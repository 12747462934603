/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import file from './tree3.glb' ; 
export default function Tree3({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF(file)
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[3.04, -2.11, -1.86]} rotation={[Math.PI / 2, 0, 0]} scale={1.06}>
        <mesh geometry={nodes.lowpoly_tree_04_Plane012.geometry} material={materials['Material.002']} />
        <mesh geometry={nodes.lowpoly_tree_04_Plane012_1.geometry} material={materials['Material.003']} />
      </group>
    </group>
  )
}

useGLTF.preload(file)
