import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';

const ang = [
    { fov: 42, step: 0.1, pos: [-355, 30, 80], lookAt: [-355, 35, -169.5], name: "Snake Path" },
    { fov: 42, step: 0.06, pos: [-355, 80, -280], lookAt: [-355, 35, -100], name: "Clock Tower Top" },
    { fov: 42, step: 0.1, pos: [-355, 30, -140], lookAt: [-355, 35, -100.5], name: "Mandir View" },
    { fov: 42, step: 0.1, pos: [-150, 50, 250], lookAt: [-355, 35, -100.5], name: "GymG" },
    { fov: 50, step: 0.1, pos: [-340, 35, 85], lookAt: [-200, 0, 85], name: "Shankar" },
];

const num = ang.length;

function Drone({ zoom, setZoom, setLocationText }) {
    const vec = new THREE.Vector3();

    if (zoom >= num) {
        zoom = zoom % num;
        setZoom(zoom % num);
    } else if (zoom < 0) {
        zoom = num - 1;
        setZoom(num - 1);
    }

    setLocationText(ang[zoom].name);

    useFrame((state) => {
        state.camera.fov = THREE.MathUtils.lerp(state.camera.fov, ang[zoom].fov, ang[zoom].step);
        state.camera.position.lerp(vec.set(ang[zoom].pos[0], ang[zoom].pos[1], ang[zoom].pos[2]), ang[zoom].step);
        state.camera.lookAt(ang[zoom].lookAt[0], ang[zoom].lookAt[1], ang[zoom].lookAt[2]);
        state.camera.updateProjectionMatrix();
    })

    return (<></>);
}

export default Drone;