/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useBox } from "@react-three/cannon";

import file from "./redi.glb";

export default function Model({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF(file);

  let pos = props.position;
  let pos2 = [pos[0] - 0, pos[1] + 0, pos[2] + 0];
  // let scale = [4.5, 7.4, 4.5];
  let scale = [3.56, 7.4, 3.56];

  const [RediMass] = useBox(() => ({
    type: "Static",
    mass: 1000,
    args: scale,
    position: pos2,
  }));

  return (
    <>
      {/* <group>
        <mesh ref={RediMass}>
          <boxGeometry args={scale} position={pos2} />
        <meshStandardMaterial color='red' />
        </mesh>
      </group> */}
      <group ref={group} {...props} dispose={null}>
        <group scale={[1.59, 1, 1.78]}>
          <mesh geometry={nodes.Cube_1.geometry} material={materials.Material} />
          <mesh
            geometry={nodes.Cube_2.geometry}
            material={materials["Material.001"]}
          />
        </group>
      </group>
    </>
  );
}

useGLTF.preload(file);
