import React from "react";
import Tree1 from "../trees/Tree1";
import Tree2 from "../trees/Tree2";
import Tree3 from "../trees/Tree3";
import Tree4 from "../trees/Tree4";
import Tree5 from "../trees/Tree5";
import Tree6 from "../trees/Tree6";

const TreeGroup = ({ ...props }) => {
  const group = React.useRef();

  return (
    <group ref={group} {...props}>
      <Tree1 position={[0, 10, 0]} scale={4} />
      <Tree2 position={[10, 10, 0]} scale={4} />
      <Tree3 position={[-40, 10, 0]} scale={4} />
      <Tree4 position={[7, -10, -25]} scale={5} />
      <Tree5 position={[7, -5, -20]} scale={4} />
      <Tree6 position={[-43, -5, -25]} scale={4} />
    </group>
  );
};

export default TreeGroup;
