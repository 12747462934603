/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useBox } from "@react-three/cannon";

import file from "./mess.glb";

export default function Model({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF(file);

  let pos = props.position;
  // let pos2 = [pos[0] - 286.5, pos[1], pos[2] - 36.5];
  let scale = [15.6, 50, 14];

  const [MessMass] = useBox(() => ({
    type: "Static",
    mass: 1000,
    args: scale,
    position: pos,
  }));

  return (
    <>
      {/* <group>
        <mesh ref={MessMass}>
          <boxGeometry args={scale} position={pos} />
        <meshStandardMaterial color='red' />
        </mesh>
      </group> */}
      <group ref={group} {...props} dispose={null}>
        <group position={[286.5-290.94, 10.87, 36.5-42.56]} scale={[0.27, 0.27, 0.31]}>
          <mesh
            geometry={nodes.Cube294.geometry}
            material={materials["Material.666"]}
          />
          <mesh
            geometry={nodes.Cube294_1.geometry}
            material={materials["Material.668"]}
          />
        </group>
        <mesh
          geometry={nodes.Cube226.geometry}
          material={materials["Material.946"]}
          position={[286.5-292.6, 10.78, 36.5-41.55]}
          scale={[0.49, 0.14, 0.56]}
        />
        <mesh
          geometry={nodes.Cube227.geometry}
          material={materials["Material.846"]}
          position={[286.5-292.07, 11.07, 36.5-41.55]}
          scale={[0.49, 0.14, 0.56]}
        />
        <mesh
          geometry={nodes.Cube228.geometry}
          material={materials["Material.945"]}
          position={[286.5-293.27, 11.1, 36.5-36.38]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.87, 0.28, 0.79]}
        />
        <mesh
          geometry={nodes.Cube229.geometry}
          material={materials["Material.2129"]}
          position={[286.5-291.62, 14.01, 36.5-30.11]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.09, 0.09, 12.82]}
        />
        <mesh
          geometry={nodes.Cube230.geometry}
          material={materials["Material.2130"]}
          position={[286.5-291.62, 14.86, 36.5-30.11]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.09, 0.09, 12.82]}
        />
        <mesh
          geometry={nodes.Plane063.geometry}
          material={materials["Material.667"]}
          position={[286.5-291.31, 12.07, 36.5-36.8]}
          rotation={[Math.PI, 0, Math.PI / 2]}
          scale={[1.22, 1, 1]}
        />
        <mesh
          geometry={nodes.Cylinder123.geometry}
          material={nodes.Cylinder123.material}
          position={[286.5-291.33, 12.21, 36.5-36.54]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[0.04, 1.02, 0.04]}
        />
      </group>
    </>
  );
}

useGLTF.preload(file);
