/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

import file from './tree4.glb' ; 
export default function Tree4({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF(file)
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-3.03, 0.12, -1.93]} rotation={[Math.PI / 2, 0, 0]} scale={0.18}>
        <mesh geometry={nodes.Plane_Plane000.geometry} material={materials['Material.003']} />
        <mesh geometry={nodes.Plane_Plane000_1.geometry} material={materials['Material.002']} />
      </group>
    </group>
  )
}

useGLTF.preload(file)
