import React from "react";
import Tree1 from "../trees/Tree1";
import Tree2 from "../trees/Tree2";
import Tree3 from "../trees/Tree3";
import Tree4 from "../trees/Tree4";
import Tree5 from "../trees/Tree5";
import Tree6 from "../trees/Tree6";

const TreeStrip = ({ ...props }) => {
  const group = React.useRef();

  return (
    <group ref={group} {...props}>
      <Tree6 position={[-43, -5, 0]} scale={4} />
      <Tree3 position={[-40, 10, 10]} scale={4} />
      <Tree1 position={[-18, 7, 25]} scale={4} />
      <Tree2 position={[-30, 5, 40]} scale={4} />
      <Tree5 position={[-30, -10, 55]} scale={4} />
      <Tree4 position={[-10, -10, 70]} scale={5} />
    </group>
  );
};

export default TreeStrip;
