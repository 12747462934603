import React, { useRef } from "react";
import { useCylinder } from "@react-three/cannon";

export default function MassRoundaboutMed({ ...props }) {
  const group = useRef();

  let pos = props.position;
  let scale = [2.8, 2.8, 25, 32];


  const [MassRoundaboutMed] = useCylinder(() => ({
    type: "Static",
    mass: 1000,
    args: scale,
    position: pos,
  }));

  return (
    <group>
      <mesh ref={MassRoundaboutMed}>
        {/* <cylinderGeometry args={scale} position={pos} />
        <meshStandardMaterial color="red" /> */}
      </mesh>
    </group>
  );
}
