/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import file from "./tree2.glb";

export default function Tree2({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF(file);
  return (
    <group ref={group} {...props} dispose={null}>
      <group
        position={[-0.13, -2.08, -1.91]}
        rotation={[Math.PI / 2, 0, -1.34]}
        scale={0.89}
      >
        <mesh
          geometry={nodes.lowpoly_tree_02_Mesh005.geometry}
          material={materials["Material.002"]}
        />
        <mesh
          geometry={nodes.lowpoly_tree_02_Mesh005_1.geometry}
          material={materials["Material.003"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload(file);
