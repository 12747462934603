/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useBox } from "@react-three/cannon";

import file from "./hostel.glb";

export default function Hostel({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF(file);

  let pos = props.position;
  // let pos2 = [pos[0] - 288.75, pos[1] + 20, pos[2] + 72.3];
  let pos2 = [pos[0], pos[1] + 20, pos[2]];

  let scale = [35.5, 25, 51];

  const [HostelMass] = useBox(() => ({
    type: "Static",
    mass: 1000,
    args: scale,
    position: pos2,
  }));

  return (
    <>
      {/* <group>
        <mesh ref={HostelMass}>
          <boxGeometry args={scale} position={pos2} />
          <meshStandardMaterial color='red' />
        </mesh>
      </group> */}
      <group ref={group} {...props} dispose={null}>
        <group
          position={[-297.81 + 288.5, 13.09, 52.93 - 72.3]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.23, 0.98, 0.28]}
        >
          <mesh
            geometry={nodes.Cube101.geometry}
            material={materials["Material.292"]}
          />
          <mesh
            geometry={nodes.Cube101_1.geometry}
            material={materials["Material.293"]}
          />
          <mesh
            geometry={nodes.Cube101_2.geometry}
            material={materials["Material.235"]}
          />
          <mesh
            geometry={nodes.Cube101_3.geometry}
            material={materials["Material.236"]}
          />
          <mesh
            geometry={nodes.Cube101_4.geometry}
            material={materials["Material.238"]}
          />
          <mesh
            geometry={nodes.Cube101_5.geometry}
            material={materials["Material.239"]}
          />
          <mesh
            geometry={nodes.Cube101_6.geometry}
            material={materials["Material.241"]}
          />
          <mesh
            geometry={nodes.Cube101_7.geometry}
            material={materials["Material.242"]}
          />
          <mesh
            geometry={nodes.Cube101_8.geometry}
            material={materials["Material.244"]}
          />
          <mesh
            geometry={nodes.Cube101_9.geometry}
            material={materials["Material.245"]}
          />
          <mesh
            geometry={nodes.Cube101_10.geometry}
            material={materials["Material.247"]}
          />
          <mesh
            geometry={nodes.Cube101_11.geometry}
            material={materials["Material.248"]}
          />
          <mesh
            geometry={nodes.Cube101_12.geometry}
            material={materials["Material.250"]}
          />
          <mesh
            geometry={nodes.Cube101_13.geometry}
            material={materials["Material.251"]}
          />
          <mesh
            geometry={nodes.Cube101_14.geometry}
            material={materials["Material.253"]}
          />
          <mesh
            geometry={nodes.Cube101_15.geometry}
            material={materials["Material.254"]}
          />
          <mesh
            geometry={nodes.Cube101_16.geometry}
            material={materials["Material.256"]}
          />
          <mesh
            geometry={nodes.Cube101_17.geometry}
            material={materials["Material.257"]}
          />
          <mesh
            geometry={nodes.Cube101_18.geometry}
            material={materials["Material.259"]}
          />
          <mesh
            geometry={nodes.Cube101_19.geometry}
            material={materials["Material.260"]}
          />
          <mesh
            geometry={nodes.Cube101_20.geometry}
            material={materials["Material.262"]}
          />
          <mesh
            geometry={nodes.Cube101_21.geometry}
            material={materials["Material.263"]}
          />
          <mesh
            geometry={nodes.Cube101_22.geometry}
            material={materials["Material.265"]}
          />
          <mesh
            geometry={nodes.Cube101_23.geometry}
            material={materials["Material.266"]}
          />
          <mesh
            geometry={nodes.Cube101_24.geometry}
            material={materials["Material.268"]}
          />
          <mesh
            geometry={nodes.Cube101_25.geometry}
            material={materials["Material.269"]}
          />
          <mesh
            geometry={nodes.Cube101_26.geometry}
            material={materials["Material.271"]}
          />
          <mesh
            geometry={nodes.Cube101_27.geometry}
            material={materials["Material.272"]}
          />
          <mesh
            geometry={nodes.Cube101_28.geometry}
            material={materials["Material.274"]}
          />
          <mesh
            geometry={nodes.Cube101_29.geometry}
            material={materials["Material.275"]}
          />
          <mesh
            geometry={nodes.Cube101_30.geometry}
            material={materials["Material.277"]}
          />
          <mesh
            geometry={nodes.Cube101_31.geometry}
            material={materials["Material.278"]}
          />
          <mesh
            geometry={nodes.Cube101_32.geometry}
            material={materials["Material.280"]}
          />
          <mesh
            geometry={nodes.Cube101_33.geometry}
            material={materials["Material.281"]}
          />
          <mesh
            geometry={nodes.Cube101_34.geometry}
            material={materials["Material.283"]}
          />
          <mesh
            geometry={nodes.Cube101_35.geometry}
            material={materials["Material.284"]}
          />
          <mesh
            geometry={nodes.Cube101_36.geometry}
            material={materials["Material.286"]}
          />
          <mesh
            geometry={nodes.Cube101_37.geometry}
            material={materials["Material.287"]}
          />
          <mesh
            geometry={nodes.Cube101_38.geometry}
            material={materials["Material.289"]}
          />
          <mesh
            geometry={nodes.Cube101_39.geometry}
            material={materials["Material.290"]}
          />
          <mesh
            geometry={nodes.Cube101_40.geometry}
            material={materials["Material.3817"]}
          />
          <mesh
            geometry={nodes.Cube101_41.geometry}
            material={materials["Material.3818"]}
          />
          <mesh
            geometry={nodes.Cube101_42.geometry}
            material={materials["Material.5374"]}
          />
          <mesh
            geometry={nodes.Cube101_43.geometry}
            material={materials["Material.5376"]}
          />
          <mesh
            geometry={nodes.Cube101_44.geometry}
            material={materials["Material.5387"]}
          />
          <mesh
            geometry={nodes.Cube101_45.geometry}
            material={materials["Material.5389"]}
          />
          <mesh
            geometry={nodes.Cube101_46.geometry}
            material={materials["Material.5534"]}
          />
          <mesh
            geometry={nodes.Cube101_47.geometry}
            material={materials["Material.5535"]}
          />
          <mesh
            geometry={nodes.Cube101_48.geometry}
            material={materials["Material.5536"]}
          />
          <mesh
            geometry={nodes.Cube101_49.geometry}
            material={materials["Material.5537"]}
          />
          <mesh
            geometry={nodes.Cube101_50.geometry}
            material={materials["Material.5538"]}
          />
          <mesh
            geometry={nodes.Cube101_51.geometry}
            material={materials["Material.5539"]}
          />
          <mesh
            geometry={nodes.Cube101_52.geometry}
            material={materials["Material.6177"]}
          />
          <mesh
            geometry={nodes.Cube101_53.geometry}
            material={materials["Material.001"]}
          />
          <mesh
            geometry={nodes.Cube101_54.geometry}
            material={materials["Material.002"]}
          />
          <mesh
            geometry={nodes.Cube101_55.geometry}
            material={materials["Material.004"]}
          />
          <mesh
            geometry={nodes.Cube101_56.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            geometry={nodes.Cube101_57.geometry}
            material={materials["Material.011"]}
          />
          <mesh
            geometry={nodes.Cube101_58.geometry}
            material={materials["Material.012"]}
          />
          <mesh
            geometry={nodes.Cube101_59.geometry}
            material={materials["Material.013"]}
          />
          <mesh
            geometry={nodes.Cube101_60.geometry}
            material={materials["Material.014"]}
          />
          <mesh
            geometry={nodes.Cube101_61.geometry}
            material={materials["Material.016"]}
          />
          <mesh
            geometry={nodes.Cube101_62.geometry}
            material={materials.Material}
          />
          <mesh
            geometry={nodes.Cube101_63.geometry}
            material={materials["Material.027"]}
          />
          <mesh
            geometry={nodes.Cube101_64.geometry}
            material={materials["Material.028"]}
          />
          <mesh
            geometry={nodes.Cube101_65.geometry}
            material={materials["Material.030"]}
          />
          <mesh
            geometry={nodes.Cube101_66.geometry}
            material={materials["Material.031"]}
          />
          <mesh
            geometry={nodes.Cube101_67.geometry}
            material={materials["Material.041"]}
          />
          <mesh
            geometry={nodes.Cube101_68.geometry}
            material={materials["Material.042"]}
          />
          <mesh
            geometry={nodes.Cube101_69.geometry}
            material={materials["Material.043"]}
          />
          <mesh
            geometry={nodes.Cube101_70.geometry}
            material={materials["Material.044"]}
          />
          <mesh
            geometry={nodes.Cube101_71.geometry}
            material={materials["Material.045"]}
          />
          <mesh
            geometry={nodes.Cube101_72.geometry}
            material={materials["Material.046"]}
          />
          <mesh
            geometry={nodes.Cube101_73.geometry}
            material={materials["Material.047"]}
          />
          <mesh
            geometry={nodes.Cube101_74.geometry}
            material={materials["Material.048"]}
          />
          <mesh
            geometry={nodes.Cube101_75.geometry}
            material={materials["Material.049"]}
          />
          <mesh
            geometry={nodes.Cube101_76.geometry}
            material={materials["Material.050"]}
          />
          <mesh
            geometry={nodes.Cube101_77.geometry}
            material={materials["Material.051"]}
          />
          <mesh
            geometry={nodes.Cube101_78.geometry}
            material={materials["Material.052"]}
          />
          <mesh
            geometry={nodes.Cube101_79.geometry}
            material={materials["Material.053"]}
          />
          <mesh
            geometry={nodes.Cube101_80.geometry}
            material={materials["Material.054"]}
          />
          <mesh
            geometry={nodes.Cube101_81.geometry}
            material={materials["Material.055"]}
          />
          <mesh
            geometry={nodes.Cube101_82.geometry}
            material={materials["Material.056"]}
          />
          <mesh
            geometry={nodes.Cube101_83.geometry}
            material={materials["Material.057"]}
          />
          <mesh
            geometry={nodes.Cube101_84.geometry}
            material={materials["Material.058"]}
          />
          <mesh
            geometry={nodes.Cube101_85.geometry}
            material={materials["Material.059"]}
          />
          <mesh
            geometry={nodes.Cube101_86.geometry}
            material={materials["Material.060"]}
          />
          <mesh
            geometry={nodes.Cube101_87.geometry}
            material={materials["Material.061"]}
          />
          <mesh
            geometry={nodes.Cube101_88.geometry}
            material={materials["Material.062"]}
          />
          <mesh
            geometry={nodes.Cube101_89.geometry}
            material={materials["Material.063"]}
          />
          <mesh
            geometry={nodes.Cube101_90.geometry}
            material={materials["Material.064"]}
          />
          <mesh
            geometry={nodes.Cube101_91.geometry}
            material={materials["Material.065"]}
          />
          <mesh
            geometry={nodes.Cube101_92.geometry}
            material={materials["Material.066"]}
          />
          <mesh
            geometry={nodes.Cube101_93.geometry}
            material={materials["Material.067"]}
          />
          <mesh
            geometry={nodes.Cube101_94.geometry}
            material={materials["Material.068"]}
          />
          <mesh
            geometry={nodes.Cube101_95.geometry}
            material={materials["Material.069"]}
          />
          <mesh
            geometry={nodes.Cube101_96.geometry}
            material={materials["Material.070"]}
          />
          <mesh
            geometry={nodes.Cube101_97.geometry}
            material={materials["Material.071"]}
          />
          <mesh
            geometry={nodes.Cube101_98.geometry}
            material={materials["Material.072"]}
          />
          <mesh
            geometry={nodes.Cube101_99.geometry}
            material={materials["Material.073"]}
          />
          <mesh
            geometry={nodes.Cube101_100.geometry}
            material={materials["Material.074"]}
          />
          <mesh
            geometry={nodes.Cube101_101.geometry}
            material={materials["Material.075"]}
          />
          <mesh
            geometry={nodes.Cube101_102.geometry}
            material={materials["Material.076"]}
          />
          <mesh
            geometry={nodes.Cube101_103.geometry}
            material={materials["Material.077"]}
          />
          <mesh
            geometry={nodes.Cube101_104.geometry}
            material={materials["Material.078"]}
          />
          <mesh
            geometry={nodes.Cube101_105.geometry}
            material={materials["Material.079"]}
          />
          <mesh
            geometry={nodes.Cube101_106.geometry}
            material={materials["Material.080"]}
          />
          <mesh
            geometry={nodes.Cube101_107.geometry}
            material={materials["Material.081"]}
          />
          <mesh
            geometry={nodes.Cube101_108.geometry}
            material={materials["Material.082"]}
          />
          <mesh
            geometry={nodes.Cube101_109.geometry}
            material={materials["Material.083"]}
          />
          <mesh
            geometry={nodes.Cube101_110.geometry}
            material={materials["Material.084"]}
          />
          <mesh
            geometry={nodes.Cube101_111.geometry}
            material={materials["Material.085"]}
          />
          <mesh
            geometry={nodes.Cube101_112.geometry}
            material={materials["Material.086"]}
          />
          <mesh
            geometry={nodes.Cube101_113.geometry}
            material={materials["Material.088"]}
          />
          <mesh
            geometry={nodes.Cube101_114.geometry}
            material={materials["Material.089"]}
          />
          <mesh
            geometry={nodes.Cube101_115.geometry}
            material={materials["Material.091"]}
          />
          <mesh
            geometry={nodes.Cube101_116.geometry}
            material={materials["Material.092"]}
          />
          <mesh
            geometry={nodes.Cube101_117.geometry}
            material={materials["Material.094"]}
          />
          <mesh
            geometry={nodes.Cube101_118.geometry}
            material={materials["Material.095"]}
          />
          <mesh
            geometry={nodes.Cube101_119.geometry}
            material={materials["Material.097"]}
          />
          <mesh
            geometry={nodes.Cube101_120.geometry}
            material={materials["Material.098"]}
          />
          <mesh
            geometry={nodes.Cube101_121.geometry}
            material={materials["Material.100"]}
          />
          <mesh
            geometry={nodes.Cube101_122.geometry}
            material={materials["Material.101"]}
          />
          <mesh
            geometry={nodes.Cube101_123.geometry}
            material={materials["Material.103"]}
          />
          <mesh
            geometry={nodes.Cube101_124.geometry}
            material={materials["Material.104"]}
          />
          <mesh
            geometry={nodes.Cube101_125.geometry}
            material={materials["Material.106"]}
          />
          <mesh
            geometry={nodes.Cube101_126.geometry}
            material={materials["Material.107"]}
          />
          <mesh
            geometry={nodes.Cube101_127.geometry}
            material={materials["Material.109"]}
          />
          <mesh
            geometry={nodes.Cube101_128.geometry}
            material={materials["Material.110"]}
          />
          <mesh
            geometry={nodes.Cube101_129.geometry}
            material={materials["Material.112"]}
          />
          <mesh
            geometry={nodes.Cube101_130.geometry}
            material={materials["Material.113"]}
          />
          <mesh
            geometry={nodes.Cube101_131.geometry}
            material={materials["Material.115"]}
          />
          <mesh
            geometry={nodes.Cube101_132.geometry}
            material={materials["Material.116"]}
          />
          <mesh
            geometry={nodes.Cube101_133.geometry}
            material={materials["Material.118"]}
          />
          <mesh
            geometry={nodes.Cube101_134.geometry}
            material={materials["Material.119"]}
          />
          <mesh
            geometry={nodes.Cube101_135.geometry}
            material={materials["Material.121"]}
          />
          <mesh
            geometry={nodes.Cube101_136.geometry}
            material={materials["Material.122"]}
          />
          <mesh
            geometry={nodes.Cube101_137.geometry}
            material={materials["Material.124"]}
          />
          <mesh
            geometry={nodes.Cube101_138.geometry}
            material={materials["Material.125"]}
          />
          <mesh
            geometry={nodes.Cube101_139.geometry}
            material={materials["Material.127"]}
          />
          <mesh
            geometry={nodes.Cube101_140.geometry}
            material={materials["Material.128"]}
          />
          <mesh
            geometry={nodes.Cube101_141.geometry}
            material={materials["Material.130"]}
          />
          <mesh
            geometry={nodes.Cube101_142.geometry}
            material={materials["Material.131"]}
          />
          <mesh
            geometry={nodes.Cube101_143.geometry}
            material={materials["Material.133"]}
          />
          <mesh
            geometry={nodes.Cube101_144.geometry}
            material={materials["Material.134"]}
          />
          <mesh
            geometry={nodes.Cube101_145.geometry}
            material={materials["Material.136"]}
          />
          <mesh
            geometry={nodes.Cube101_146.geometry}
            material={materials["Material.137"]}
          />
          <mesh
            geometry={nodes.Cube101_147.geometry}
            material={materials["Material.139"]}
          />
          <mesh
            geometry={nodes.Cube101_148.geometry}
            material={materials["Material.140"]}
          />
          <mesh
            geometry={nodes.Cube101_149.geometry}
            material={materials["Material.142"]}
          />
          <mesh
            geometry={nodes.Cube101_150.geometry}
            material={materials["Material.143"]}
          />
          <mesh
            geometry={nodes.Cube101_151.geometry}
            material={materials["Material.145"]}
          />
          <mesh
            geometry={nodes.Cube101_152.geometry}
            material={materials["Material.146"]}
          />
          <mesh
            geometry={nodes.Cube101_153.geometry}
            material={materials["Material.148"]}
          />
          <mesh
            geometry={nodes.Cube101_154.geometry}
            material={materials["Material.149"]}
          />
          <mesh
            geometry={nodes.Cube101_155.geometry}
            material={materials["Material.151"]}
          />
          <mesh
            geometry={nodes.Cube101_156.geometry}
            material={materials["Material.152"]}
          />
          <mesh
            geometry={nodes.Cube101_157.geometry}
            material={materials["Material.154"]}
          />
          <mesh
            geometry={nodes.Cube101_158.geometry}
            material={materials["Material.155"]}
          />
          <mesh
            geometry={nodes.Cube101_159.geometry}
            material={materials["Material.157"]}
          />
          <mesh
            geometry={nodes.Cube101_160.geometry}
            material={materials["Material.158"]}
          />
          <mesh
            geometry={nodes.Cube101_161.geometry}
            material={materials["Material.160"]}
          />
          <mesh
            geometry={nodes.Cube101_162.geometry}
            material={materials["Material.161"]}
          />
          <mesh
            geometry={nodes.Cube101_163.geometry}
            material={materials["Material.163"]}
          />
          <mesh
            geometry={nodes.Cube101_164.geometry}
            material={materials["Material.164"]}
          />
          <mesh
            geometry={nodes.Cube101_165.geometry}
            material={materials["Material.166"]}
          />
          <mesh
            geometry={nodes.Cube101_166.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            geometry={nodes.Cube101_167.geometry}
            material={materials["Material.169"]}
          />
          <mesh
            geometry={nodes.Cube101_168.geometry}
            material={materials["Material.170"]}
          />
          <mesh
            geometry={nodes.Cube101_169.geometry}
            material={materials["Material.172"]}
          />
          <mesh
            geometry={nodes.Cube101_170.geometry}
            material={materials["Material.173"]}
          />
          <mesh
            geometry={nodes.Cube101_171.geometry}
            material={materials["Material.175"]}
          />
          <mesh
            geometry={nodes.Cube101_172.geometry}
            material={materials["Material.176"]}
          />
          <mesh
            geometry={nodes.Cube101_173.geometry}
            material={materials["Material.178"]}
          />
          <mesh
            geometry={nodes.Cube101_174.geometry}
            material={materials["Material.179"]}
          />
          <mesh
            geometry={nodes.Cube101_175.geometry}
            material={materials["Material.181"]}
          />
          <mesh
            geometry={nodes.Cube101_176.geometry}
            material={materials["Material.182"]}
          />
          <mesh
            geometry={nodes.Cube101_177.geometry}
            material={materials["Material.184"]}
          />
          <mesh
            geometry={nodes.Cube101_178.geometry}
            material={materials["Material.185"]}
          />
          <mesh
            geometry={nodes.Cube101_179.geometry}
            material={materials["Material.187"]}
          />
          <mesh
            geometry={nodes.Cube101_180.geometry}
            material={materials["Material.188"]}
          />
          <mesh
            geometry={nodes.Cube101_181.geometry}
            material={materials["Material.190"]}
          />
          <mesh
            geometry={nodes.Cube101_182.geometry}
            material={materials["Material.191"]}
          />
          <mesh
            geometry={nodes.Cube101_183.geometry}
            material={materials["Material.193"]}
          />
          <mesh
            geometry={nodes.Cube101_184.geometry}
            material={materials["Material.194"]}
          />
          <mesh
            geometry={nodes.Cube101_185.geometry}
            material={materials["Material.196"]}
          />
          <mesh
            geometry={nodes.Cube101_186.geometry}
            material={materials["Material.197"]}
          />
          <mesh
            geometry={nodes.Cube101_187.geometry}
            material={materials["Material.199"]}
          />
          <mesh
            geometry={nodes.Cube101_188.geometry}
            material={materials["Material.200"]}
          />
          <mesh
            geometry={nodes.Cube101_189.geometry}
            material={materials["Material.202"]}
          />
          <mesh
            geometry={nodes.Cube101_190.geometry}
            material={materials["Material.203"]}
          />
          <mesh
            geometry={nodes.Cube101_191.geometry}
            material={materials["Material.205"]}
          />
          <mesh
            geometry={nodes.Cube101_192.geometry}
            material={materials["Material.206"]}
          />
          <mesh
            geometry={nodes.Cube101_193.geometry}
            material={materials["Material.208"]}
          />
          <mesh
            geometry={nodes.Cube101_194.geometry}
            material={materials["Material.209"]}
          />
          <mesh
            geometry={nodes.Cube101_195.geometry}
            material={materials["Material.211"]}
          />
          <mesh
            geometry={nodes.Cube101_196.geometry}
            material={materials["Material.212"]}
          />
          <mesh
            geometry={nodes.Cube101_197.geometry}
            material={materials["Material.214"]}
          />
          <mesh
            geometry={nodes.Cube101_198.geometry}
            material={materials["Material.215"]}
          />
          <mesh
            geometry={nodes.Cube101_199.geometry}
            material={materials["Material.217"]}
          />
          <mesh
            geometry={nodes.Cube101_200.geometry}
            material={materials["Material.218"]}
          />
          <mesh
            geometry={nodes.Cube101_201.geometry}
            material={materials["Material.220"]}
          />
          <mesh
            geometry={nodes.Cube101_202.geometry}
            material={materials["Material.221"]}
          />
          <mesh
            geometry={nodes.Cube101_203.geometry}
            material={materials["Material.223"]}
          />
          <mesh
            geometry={nodes.Cube101_204.geometry}
            material={materials["Material.224"]}
          />
          <mesh
            geometry={nodes.Cube101_205.geometry}
            material={materials["Material.226"]}
          />
          <mesh
            geometry={nodes.Cube101_206.geometry}
            material={materials["Material.227"]}
          />
          <mesh
            geometry={nodes.Cube101_207.geometry}
            material={materials["Material.229"]}
          />
          <mesh
            geometry={nodes.Cube101_208.geometry}
            material={materials["Material.230"]}
          />
          <mesh
            geometry={nodes.Cube101_209.geometry}
            material={materials["Material.232"]}
          />
          <mesh
            geometry={nodes.Cube101_210.geometry}
            material={materials["Material.233"]}
          />
        </group>
      </group>
    </>
  );
}

useGLTF.preload(file);
