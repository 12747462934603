import { useCylinder } from "@react-three/cannon";

export default function MassRoundaboutLarge({ ...props }) {
  let pos = props.position;
  let scale = [3.42, 3.42, 25, 32];

  const [MassRoundaboutLarge] = useCylinder(() => ({
    type: "Static",
    mass: 1000,
    args: scale,
    position: pos,
  }));

  return (
    <group>
      <mesh ref={MassRoundaboutLarge}>
        {/* <cylinderGeometry args={scale} position={pos} />
        <meshStandardMaterial color="red" /> */}
      </mesh>
    </group>
  );
}
