import MassBox from "./Mass_Box";

export default function MassGymG({ ...props }) {
  return (
    <>
      {/* Walls */}
      <MassBox position={[-258.46, 22.3, 140.39]} scale={[0.4, 3.34, 118.48]} visible={props.visible} />
      <MassBox position={[-178.99, 22.3, 128.72]} scale={[0.4, 3.34, 141.83]} visible={props.visible} />
      <MassBox position={[-218.73, 22.3, 199.4]} scale={[79.1, 3.34, 0.46]} visible={props.visible} />
      <MassBox position={[-218.73, 22.3, 58.03]} scale={[79.1, 3.34, 0.46]} visible={props.visible} />
      <MassBox position={[-255.17, 21.5, 81.3]} scale={[6.54, 1.661, 0.37]} visible={props.visible} />

      {/* Building and Courts */}
      <MassBox position={[-238.2, 30, 71.2]} scale={[24.4, 25, 10.47]} visible={props.visible} />
      <MassBox position={[-203.25, 30, 86.5]} scale={[37.02, 25, 22.51]} visible={props.visible} />

      {/* Big Goals */}
      <MassBox position={[-232.84, 22.65, 165.8]} scale={[4.58, 2, 2.05]} visible={props.visible} />
      <MassBox position={[-232.84, 22.65, 122.8]} scale={[4.58, 2, 2.05]} visible={props.visible} />

      {/* Other courts that should be walls + internals (eventually) */}
      <MassBox position={[-188.1, 30, 130.795]} scale={[13.97, 25, 16.61]} visible={props.visible} />
      <MassBox position={[-192.15, 30, 157.98]} scale={[21.75, 25, 27.165]} visible={props.visible} />
    </>
  );
}
