import * as THREE from 'three';
import React, { Suspense, useRef, useEffect, useState } from 'react';
import { Canvas, useThree, useFrame, useLoader } from '@react-three/fiber';
import { PointerLockControls, Stars } from '@react-three/drei';
import { Physics, usePlane } from '@react-three/cannon';

import { Player } from './Components/Player/Player.js';
import Vehicle from './Components/Car/Vehicle.js';

import { MapControls, OrbitControls } from '@react-three/drei';

import Bitsverse from './Models/bitsverse/Bitsverse';
import Hostel from './Models/buildings/Hostel';
import Mess from './Models/buildings/Mess';
import Redi from './Models/buildings/Redi';
import MassFD from './Components/Mass/Mass_FD';
import MassLawns from './Components/Mass/Mass_Lawns';
import MassTemple from './Components/Mass/Mass_Temple';
import MassTLawns from './Components/Mass/Mass_TLawns';
import MassNAB from './Components/Mass/Mass_NAB';
import MassRotunda from './Components/Mass/Mass_Rotunda';
import MassBitsWorkshop from './Components/Mass/Mass_Bits_Workshop';
import MassFD1RightArm from './Components/Mass/Mass_FD1_Right_Arm';
import MassFD1LeftArm from './Components/Mass/Mass_FD1_Left_Arm';
import MassLTC from './Components/Mass/Mass_LTC';
import MassLTCEntrance from './Components/Mass/Mass_LTC_Entrance';
import MassLibrary1 from './Components/Mass/Mass_Library1';
import MassLibrary2 from './Components/Mass/Mass_Library2';
import MassCentralWorkshop from './Components/Mass/Mass_Central_Workshop';
import MassFM from './Components/Mass/Mass_FM';
import MassClocktowerBack from './Components/Mass/Mass_Clocktower_Back';
import MassClocktowerWings from './Components/Mass/Mass_Clocktower_Wings';
import MassClocktowerGrass1 from './Components/Mass/Mass_Clocktower_Grass1';
import MassClocktowerGrass2 from './Components/Mass/Mass_Clocktower_Grass2';
import MassClocktowerFront from './Components/Mass/Mass_Clocktower_Front';
import MassSRGrounds from './Components/Mass/Mass_SR_Grounds';
import MassSR from './Components/Mass/Mass_SR';
import MassBVP from './Components/Mass/Mass_BVP';
import MassMeera1 from './Components/Mass/Mass_Meera1';
import MassMeera2 from './Components/Mass/Mass_Meera2';
import MassMeeraSide1 from './Components/Mass/Mass_MeeraSide1';
import MassMeeraSide2 from './Components/Mass/Mass_MeeraSide2';
import MassSAC from './Components/Mass/Mass_SAC';
import MassMalviya from './Components/Mass/Mass_Malviya';
import MassLooters from './Components/Mass/Mass_Looters';
import MassMuseum from './Components/Mass/Mass_Museum';
import MassEntranceRightHostel from './Components/Mass/Mass_Entrance_Right_Hostel';
import MassEntranceLeftBuilding from './Components/Mass/Mass_Entrance_Left_Building';
import MassEntranceLeftOtherBuilding from './Components/Mass/Mass_Entrance_Left_Other_Building';
import MassVfast from './Components/Mass/Mass_Vfast';
import MassPool from './Components/Mass/Mass_Pool';
import MassEntranceRightThing from './Components/Mass/Mass_Entrance_Right_Thing';
import MassMedC from './Components/Mass/Mass_MedC';
import MassGymG from './Components/Mass/Mass_GymG';
import MassCricketG from './Components/Mass/Mass_CricketG';
import MassShivGanga from './Components/Mass/Mass_ShivGanga';
import MassSkyLawns from './Components/Mass/Mass_SkyLanws';
import MassEntranceLeft from './Components/Mass/Mass_EntranceLeft';
import MassEntranceGateLeft from './Components/Mass/Mass_EntranceGateLeft';
import MassEntranceLeftTrees from './Components/Mass/Mass_EntranceLeftTrees';
import MassEntranceRight from './Components/Mass/Mass_EntranceRight';
import MassEntranceGateRight from './Components/Mass/Mass_EntranceGateRight';
import MassEntranceRightTrees from './Components/Mass/Mass_EntranceRightTrees';
import MassBehindMeera1 from './Components/Mass/Mass_BehindMeera1';
import MassBehindMeera2 from './Components/Mass/Mass_BehindMeera2';
import MassBehindSAC1 from './Components/Mass/Mass_BehindSAC1';
import MassBehindSAC2 from './Components/Mass/Mass_BehindSAC2';
import MassBehindTemple1 from './Components/Mass/Mass_BehindTemple1';
import MassBehindTemple2 from './Components/Mass/Mass_BehindTemple2';
import MassBehindSkyLawns from './Components/Mass/Mass_BehindSkyLawns';
import MassRoundaboutLarge from './Components/Mass/Mass_Roundabout_Large';
import MassRoundaboutMed from './Components/Mass/Mass_Roundabout_Med';
import MassRoundaboutSmall from './Components/Mass/Mass_Roundabout_Small';
import MassBehindBitsWorkshop from './Components/Mass/Mass_Behind_Bits_Workshop';
import MassEntranceOpp from './Components/Mass/Mass_EntranceOpp';

import MassBox from './Components/Mass/Mass_Box.js';

// sounds
import instrument from './sound/instrument.mp3';
import aarti from './sound/aarti.mp3';
import pasoori from './sound/pasoori.mp3';

import './App.css';

// icons
import {
  GoArrowRight,
  GoArrowLeft,
  GoArrowUp,
  GoArrowDown,
} from 'react-icons/go';

// modal import

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import TreeGroup from './Models/TreeGroup/TreeGroup.js';
import TreeStrip from './Models/TreeGroup/TreeStrip.js';

import Drone from './Components/Drone/Drone.js';

// The original was made by Maksim Ivanow: https://www.youtube.com/watch?v=Lc2JvBXMesY&t=124s
// This demo needs pointer-lock, that works only if you open it in a new window
// Controls: WASD + left click

const styles = {
  buttonControls: {
    width: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

function Sound1({ url }) {
  const sound = useRef();
  const { camera } = useThree();
  const [listener] = useState(() => new THREE.AudioListener());
  const buffer = useLoader(THREE.AudioLoader, url);
  useEffect(() => {
    sound.current.setBuffer(buffer);
    sound.current.setRefDistance(30);
    sound.current.setLoop(true);
    sound.current.play();
    camera.add(listener);
    return () => camera.remove(listener);
  }, []);
  return <positionalAudio ref={sound} args={[listener]} />;
}
function Sound2({ url }) {
  const sound = useRef();
  const { camera } = useThree();
  const [listener] = useState(() => new THREE.AudioListener());
  const buffer = useLoader(THREE.AudioLoader, url);
  useEffect(() => {
    sound.current.setBuffer(buffer);
    sound.current.setRefDistance(30);
    sound.current.setLoop(true);
    sound.current.play();
    camera.add(listener);
    return () => camera.remove(listener);
  }, []);
  return <positionalAudio ref={sound} args={[listener]} />;
}
function Sound3({ url }) {
  const sound = useRef();
  const { camera } = useThree();
  const [listener] = useState(() => new THREE.AudioListener());
  const buffer = useLoader(THREE.AudioLoader, url);
  useEffect(() => {
    sound.current.setBuffer(buffer);
    sound.current.setRefDistance(5);
    sound.current.setLoop(true);
    sound.current.play();
    camera.add(listener);
    return () => camera.remove(listener);
  }, []);
  return <positionalAudio ref={sound} args={[listener]} />;
}

export default function App({ ready }) {
  const [camnum, setCamNum] = React.useState(2); //Camera Type
  const [intensity, setIntensity] = React.useState(0.4); //Intensity
  const [day, setDay] = React.useState(1); //day

  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCamera = () => {
    setCamNum((camnum + 1) % 3);
  };
  const handleDay = () => {
    setDay(!day);
  };

  const [zoom, setZoom] = React.useState(0);
  const [locationText, setLocationText] = React.useState('');

  return (
    <>
      <Canvas
        shadows
        gl={{ alpha: false }}
        camera={{ fov: 45 }}
        onCreated={({ camera }) => {
          camera.rotation.set(0.81, 1.48, -0.8);
        }}
      >
        {/* {day ? <Sky sunPosition={[100, 1500, 100]} /> : <></>} */}
        <Stars
          radius={300}
          depth={50}
          count={5000}
          factor={4}
          saturation={0}
          speed={1}
        />

        <mesh position={[0, 30, 50]}>
          {/* <sphereGeometry attach="geometry" /> */}
          {/* <meshBasicMaterial attach="material" color="hotpink" /> */}
          {ready && <Sound1 url={instrument} />}
        </mesh>
        {/* <mesh position={[-460, 30, -150]}>
          <sphereGeometry attach="geometry" />
          <meshBasicMaterial attach="material" color="hotpink" />
          {ready && <Sound2 url={pasoori} />}
        </mesh> */}
        {/* <mesh position={[-360, 30, 190]}>
          <sphereGeometry attach="geometry" />
          <meshBasicMaterial attach="material" color="hotpink" />
          {ready && <Sound3 url={aarti} />}
        </mesh> */}

        <ambientLight intensity={intensity} />
        <pointLight castShadow intensity={0.8} position={[1000, 1000, 1000]} />
        <Physics gravity={[0, -20, 0]}>
          <Plane rotation={[-Math.PI / 2, 0, 0]} args={[1000, 1000]} />
          <TreeGroup position={[10, 30, 30]} />
          <TreeGroup position={[70, 30, 30]} />
          <TreeGroup position={[10, 30, 111]} />
          <TreeGroup position={[70, 30, 111]} />
          <TreeStrip position={[-120, 29.5, 130]} />
          <TreeStrip position={[-120, 29, 220]} />
          <TreeStrip position={[-120, 28.5, 310]} />
          <TreeStrip position={[-120, 28.5, 400]} />

          <MassEntranceOpp position={[115, 30, 47]} />

          <Bitsverse position={[0, 10, 0]} />

          <Hostel position={[0 - 288.75, 10, 13 + 72.3]} />
          <Hostel position={[0 - 288.75, 10, 98.4 + 72.3]} />
          <Hostel position={[0 - 288.75, 10, -60 + 72.3]} />
          <Hostel position={[48.4 - 288.75, 10, -60 + 72.3]} />
          <Hostel position={[91.5 - 288.75, 10, -60 + 72.3]} />
          <Hostel position={[0 - 288.75, 10, -142.75 + 72.3]} />
          <Hostel position={[48.4 - 288.75, 10, -142.75 + 72.3]} />
          <Hostel position={[91.5 - 288.75, 10, -142.75 + 72.3]} />
          <Hostel position={[-431, 10, 12.5]} rotation={[0, Math.PI, 0]} />
          <Hostel position={[-431, 10, -67]} rotation={[0, Math.PI, 0]} />

          <Mess position={[-5 - 286.5, 10, 7.5 - 36.5]} />
          <Mess position={[-5 - 286.5, 10, 165 - 36.5]} />
          <Mess position={[45 - 286.5, 10, 7.5 - 36.5]} />
          <Mess position={[90 - 286.5, 10, 7.5 - 36.5]} />
          <Mess position={[-428.5, 10, -27]} rotation={[0, Math.PI, 0]} />

          <Redi position={[-275, 22, -42.5]} rotation={[0, 1.5 * Math.PI, 0]} />
          <Redi position={[-290, 22, -16]} rotation={[0, 1.5 * Math.PI, 0]} />
          <Redi position={[-295, 22, 138]} rotation={[0, 1.5 * Math.PI, 0]} />
          <Redi position={[-274, 22, 114]} />
          <Redi position={[-227.5, 22, -35]} rotation={[0, 1.5 * Math.PI, 0]} />
          <Redi position={[-232.5, 22, -17.5]} />
          <Redi
            position={[-182.5, 22, -42.5]}
            rotation={[0, 1.5 * Math.PI, 0]}
          />
          <Redi position={[-182.5, 22, -16]} rotation={[0, 1.5 * Math.PI, 0]} />
          <Redi position={[-447, 22, -16]} />
          <Redi position={[-447, 22, -39]} />

          <MassNAB position={[-356, 30, -48]} />
          <MassRotunda position={[-355, 30, -126.5]} />
          <MassFD position={[-295, 30, -171]} />
          <MassFD position={[-415, 30, -171]} />
          <MassFD1RightArm position={[-219, 30, -176]} />
          {/* <MassFD1RightArm position={[-219.5, 30, -180]} /> */}
          <MassFD1LeftArm position={[-226, 30, -202.25]} />
          {/* <MassFD1LeftArm position={[-225, 30, -203]} /> */}
          <MassLawns position={[-290.87, 30, -121.6]} />
          <MassLawns position={[-430.25, 30, -121.6]} />
          <MassTemple position={[-358, 30, 227]} />
          <MassTLawns position={[-358, 30, 112.5]} />
          <MassBitsWorkshop position={[-222, 30, -133]} />
          <MassBVP position={[-463.5, 30, 105.5]} />
          <MassMeera1 position={[-449, 30, 182.15]} />
          <MassMeera2 position={[-525.5, 30, 232.5]} />
          <MassMeeraSide1 position={[-460, 30, 256.5]} />
          <MassMeeraSide2 position={[-413, 30, 259.75]} />
          <MassSAC position={[-494, 30, 10]} />
          <MassLooters position={[-500, 30, -32.5]} />
          <MassMalviya position={[-482.75, 30, -60.75]} />
          <MassMuseum position={[-486.75, 30, -102.75]} />
          <MassSkyLawns position={[-477.75, 30, -144]} />
          <MassLTC position={[-174, 30, -212]} />
          <MassLTCEntrance position={[-202, 30, -216]} />
          <MassLibrary1 position={[-213, 30, -254]} />
          <MassCentralWorkshop position={[-290, 30, -273]} />
          <MassFM position={[-287, 30, -215]} />

          <MassClocktowerBack position={[-356.5, 30, -205.5]} />
          <MassClocktowerWings position={[-355, 30, -181.5]} />
          <MassClocktowerGrass1 position={[-328, 30, -164]} />
          <MassClocktowerGrass1 position={[-382, 30, -166]} />
          <MassClocktowerGrass2 position={[-339, 30, -158]} />
          <MassClocktowerGrass2 position={[-371, 30, -158]} />
          <MassClocktowerFront position={[-356.5, 30, -169.5]} />

          <MassSR position={[-389, 30, -348.5]} />
          <MassSRGrounds position={[-304.5, 30, -346]} />

          {/* <MassEntranceRightHostel position={[-116.5, 30, 24.5]} /> */}
          {/* <MassEntranceRightHostel position={[-150, 30, 24.5]} /> */}
          <MassEntranceRightHostel position={[-131.5, 30, 9.5]} />
          <MassEntranceLeftBuilding position={[-50, 30, 75]} />
          <MassVfast position={[-79.5, 30, 74]} />
          <MassEntranceLeftOtherBuilding position={[-113.5, 30, 75]} />
          <MassVfast position={[-147, 30, 74]} />
          <MassPool position={[-55, 30, 10]} />
          <MassEntranceRightThing position={[-87.5, 30, 10]} />
          <MassEntranceLeftTrees position={[40, 30, 77]} />
          <MassEntranceRightTrees position={[40, 30, 5]} />
          <MassEntranceGateLeft position={[-50, 30, 56]} />
          <MassEntranceGateRight position={[-50, 30, 40]} />

          {/* ANC */}
          <MassBox position={[-254.37, 30, 66.5]} scale={[8.6, 25, 17.4]} />

          {/* Cnot Left Arm */}
          <MassBox position={[-205.25, 30, 304.4]} scale={[56.2, 25, 14.65]} />
          {/* Cnot Right Arm */}
          <MassBox position={[-205.45, 30, 332]} scale={[56.6, 25, 14.4]} />
          {/* Cnot Pool */}
          <MassBox position={[-248.3, 30, 317.8]} scale={[20.8, 25, 6.9]} />

          <MassMedC position={[-243.5, 30, 226]} />
          <MassCricketG position={[-202, 30, 253.75]} />
          <MassShivGanga position={[-301.75, 30, 312]} />

          <MassGymG />

          {/* Akshay */}
          <MassBox position={[-242, 30, 259.75]} scale={[32, 25, 30.75]} />

          {/* Masses occupying grass between Akshay and Cnot */}
          <MassBox position={[-242.2, 30, 282]} scale={[32.9, 25, 12.9]} />
          <MassBox position={[-240.1, 30, 294.95]} scale={[28.8, 25, 13]} />
          <MassEntranceLeft position={[-24.5, 30, 278]} />
          <MassEntranceRight position={[-24.5, 30, -202]} />

          {/* Masses behind Cnot */}
          <MassBox position={[-218.2, 30, 370.45]} scale={[82.2, 25, 67.9]} />
          <MassBox position={[-211.1, 30, 441.5]} scale={[97.2, 25, 55]} />

          <MassBehindMeera1 position={[-528, 30, 130]} />
          <MassBehindMeera2 position={[-491, 30, 181]} />
          <MassBehindTemple1 position={[-415, 30, 370]} />
          <MassBehindTemple2 position={[-296.25, 30, 242]} />
          <MassBehindSAC1 position={[-538, 30, -58]} />
          <MassBehindSAC2 position={[-508.5, 30, -114]} />
          <MassBehindSkyLawns position={[-497.5, 30, -283.5]} />

          <MassRoundaboutLarge position={[-171.8, 30, 47.7]} />
          <MassRoundaboutLarge position={[-263.6, 30, 47.7]} />
          <MassRoundaboutLarge position={[-315.6, 30, 47.7]} />
          <MassRoundaboutLarge position={[-403.75, 30, 48.8]} />
          <MassRoundaboutMed position={[-315, 30, -101.7]} />
          <MassRoundaboutMed position={[-401.35, 30, -101.7]} />
          <MassRoundaboutSmall position={[-264.5, 30, 206.7]} />
          <MassRoundaboutSmall position={[-264.55, 30, 409.6]} />

          <MassBehindBitsWorkshop position={[-187, 30, -141.5]} />

          {/* Amul */}
          <MassBox
            position={[-260.7, 30, 89.82]}
            scale={[4.3, 25, 8.52]}
            color='blue'
          />

          {camnum === 0 ? (
            <Player />
          ) : camnum === 1 ? (
            <Vehicle
              position={[-12.7, 27, 52.56]}
              rotation={[0.81, 1.48, -0.8]}
              angularVelocity={[0, 4.5, 0]}
              wheelRadius={0.3}
            />
          ) : (
            <Drone
              zoom={zoom}
              setZoom={setZoom}
              setLocationText={setLocationText}
            />
          )}
        </Physics>
        {camnum === 0 ? (
          <PointerLockControls />
        ) : camnum === 1 ? (
          <></>
        ) : (
          // <MapControls
          //   minPolarAngle={Math.PI / 3}
          //   maxPolarAngle={Math.PI / 3}
          //   panSpeed={0.5}
          //   dampingFactor={0.05}
          //   enableDamping={true}
          //   minDistance={50}
          //   maxDistance={400}
          //   screenSpacePanning={false}
          //   enableRotate={true}
          // />
          <></>
        )}

        {/* {<OrbitControls />} */}
      </Canvas>

      {/* <div style={{ position: "absolute", top: 30, left: 40 }}>
        <button onClick={handleDay}>Day/Night</button>
        <button onClick={handleCamera}>Camera</button>
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          background: "white",
          display: display,
        }}
      >
        <h1>Cheat code bata?</h1>
        <input id='code' type='text' style={{ left: 5 }} />
        <button onClick={() => myFunction()}>Try it</button>
      </div> */}

      {camnum === 2 ? (
        <Container
          style={{
            position: 'absolute',
            bottom: 40,
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '300px',
          }}
        >
          <Button
            onClick={() => {
              setZoom(zoom - 1);
            }}
          >
            {'<'}
          </Button>
          <Button disabled={true}>{locationText}</Button>
          <Button
            onClick={() => {
              setZoom(zoom + 1);
            }}
          >
            {'>'}
          </Button>
        </Container>
      ) : (
        <></>
      )}

      <Container style={{ position: 'absolute', top: 0, left: 30 }}>
        <Button
          variant='primary'
          onClick={handleShow}
          style={{ position: 'absolute', top: 20, left: 0 }}
        >
          i
        </Button>

        {show ? (
          <div className='mainBody'>
            <div className='openContainer'>
              <div className='openContainerHeading1'>
                <center>
                  <h1 style={{ marginLeft: 60 }}>instructions</h1>
                </center>
                {/* <i className='bi bi-x closeContainer'></i> */}
                <center className='closeContainer' onClick={handleClose}>
                  <p style={{ marginTop: 2 }}>x</p>
                </center>
              </div>
              <div className='navigationInfo'>
                <GoArrowUp
                  style={{
                    fontSize: 30,
                    transform: 'scaleY(3)',
                    margin: '0 45px 20px 0',
                    color: '#757575',
                  }}
                />
                <div className='up element'>
                  <div className='inner'>A</div>
                </div>
                <div className='lower_row'>
                  <GoArrowLeft
                    style={{
                      fontSize: 30,
                      transform: 'scaleX(3)',
                      margin: '0 20px 0 0 ',
                      color: '#757575',
                    }}
                  />
                  <div className='lower_row_element element'>
                    <div className='inner'>S</div>
                  </div>
                  <div className=' element'>
                    <div className='inner'>W</div>
                  </div>
                  <div className='lower_row_element element'>
                    <div className='inner'>D</div>
                  </div>
                  <GoArrowRight
                    style={{
                      fontSize: 30,
                      transform: 'scaleX(3)',
                      margin: '0 0 0 20px ',
                      color: '#757575',
                    }}
                  />
                </div>
                <GoArrowDown
                  style={{
                    fontSize: 30,
                    transform: 'scaleY(3)',
                    margin: '20px 0 0 0',
                    color: '#757575',
                  }}
                />
              </div>
              <div className='openContainerHeading2'>
                <center>
                  <h1>navigation views</h1>
                </center>
              </div>
              <div>
                <div className='navigationViews' onClick={() => setCamNum(0)}>
                  <h2>first person view</h2>
                </div>
              </div>
              <div>
                <div className='navigationViews' onClick={() => setCamNum(1)}>
                  <h2>car view</h2>
                </div>
              </div>
              <div>
                <div
                  className='navigationViews'
                  onClick={() => {
                    setCamNum(2);
                    handleClose();
                  }}
                >
                  <h2>drone view</h2>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Container>
    </>
  );
}

function Plane(props) {
  const [ref] = usePlane(() => ({ ...props }));
  return (
    <group ref={ref}>
      <mesh>
        <planeGeometry args={[1000, 1000]} />
        <meshStandardMaterial color='green' />
      </mesh>
    </group>
  );
}
