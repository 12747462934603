import { useBox } from "@react-three/cannon";

export default function MassBox({ ...props }) {
  const [MassBox] = useBox(() => ({
    type: "Static",
    mass: 1000,
    args: props.scale,
    position: props.position,
  }));

  if (props.visible) {
    return (
      <group>
        <mesh ref={MassBox}>
          <boxGeometry args={props.scale} position={props.position} />
          <meshStandardMaterial color={props.color ? props.color : "red"} />
        </mesh>
      </group>
    );
  } else { return <></>; }
}
