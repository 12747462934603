import React, { useRef } from "react";
import { useBox } from "@react-three/cannon";

export default function MassNAB({ ...props }) {
  const group = useRef();

  let pos = props.position;
  let scale = [60, 25, 172];

  const [MassNAB] = useBox(() => ({
    type: "Static",
    mass: 1000,
    args: scale,
    position: pos,
  }));

  return (
    <group>
      <mesh ref={MassNAB}>
        {/* <boxGeometry args={scale} position={pos} />
        <meshStandardMaterial color='red' /> */}
      </mesh>
    </group>
  );
}
