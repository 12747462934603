/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

import file from './tree5.glb'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF(file)
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.31, 2.33, -2.33]} rotation={[Math.PI / 2, 0, 0.12]} scale={1.07}>
        <mesh geometry={nodes.lowpoly_tree_01_Plane013.geometry} material={materials['Material.002']} />
        <mesh geometry={nodes.lowpoly_tree_01_Plane013_1.geometry} material={materials['Material.003']} />
      </group>
    </group>
  )
}

useGLTF.preload(file)
