import React, { useRef } from "react";
import { useBox } from "@react-three/cannon";

export default function MassSR({ ...props }) {
  const group = useRef();

  let pos = props.position;
  let scale = [110, 25, 55];

  const [MassSR] = useBox(() => ({
    type: "Static",
    mass: 1000,
    args: scale,
    position: pos,
  }));

  return (
    <group>
      <mesh ref={MassSR}>
        {/* <boxGeometry args={scale} position={pos} />
        <meshStandardMaterial color='red' /> */}
      </mesh>
    </group>
  );
}
