import { forwardRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useBox } from '@react-three/cannon'

import { PerspectiveCamera } from '@react-three/drei'

import file from './Beetle.glb'

useGLTF.preload(file)

// Auto-generated by: https://github.com/pmndrs/gltfjsx
// Model via KrStolorz on Sketchfab, CC-BY-4.0
// https://sketchfab.com/3d-models/low-poly-volkswagen-beetle-f680ad7e98e445eaafed1a70f2c53911
const Beetle = forwardRef(({ args = [1.7, 1, 4], mass = 500, ...props }, ref) => {
  const { nodes, materials } = useGLTF(file)
  const [, api] = useBox(() => ({ mass, args, allowSleep: false, onCollide: (e) => console.log('bonk', e.body.userData), ...props }), ref)

  return (
    <mesh ref={ref} api={api}>
      <group rotation={[0,Math.PI,0]} >
        <PerspectiveCamera makeDefault position={[0, 3, 10]} args={[45, 20, 1, 1000]} />
      </group>
    <group position={[0, -0.6, 0]}>
      <mesh geometry={nodes.chassis318_1.geometry} material={materials['Black paint']} />
      <mesh geometry={nodes.chassis318_2.geometry} material={materials.Rubber} />
      <mesh geometry={nodes.chassis318_3.geometry} material={materials.Paint} />
      <mesh geometry={nodes.chassis318_4.geometry} material={materials.Underbody} />
      <mesh geometry={nodes.chassis318_5.geometry} material={materials.Chrom} />
      <mesh geometry={nodes.chassis318_6.geometry} material={materials['Interior (dark)']} />
      <mesh geometry={nodes.chassis318_7.geometry} material={materials['Interior (light)']} />
      <mesh geometry={nodes.chassis318_8.geometry} material={materials.Reflector} />
      <mesh geometry={nodes.chassis318_9.geometry} material={materials.Glass} />
      <mesh geometry={nodes.chassis318_10.geometry} material={materials.Steel} />
      <mesh geometry={nodes.chassis318_11.geometry} material={materials['Black plastic']} />
      <mesh geometry={nodes.chassis318_12.geometry} material={materials.Headlight} />
      <mesh geometry={nodes.chassis318_13.geometry} material={materials['Reverse lights']} />
      <mesh geometry={nodes.chassis318_14.geometry} material={materials['Orange plastic']} />
      <mesh geometry={nodes.chassis318_15.geometry} material={materials['Tail lights']} />
      <mesh geometry={nodes.chassis318_16.geometry} material={materials['License Plate']} />
      <mesh geometry={nodes.chassis318_17.geometry} material={materials['Material.009']} />
      <mesh geometry={nodes.chassis318_18.geometry} material={materials['Material.010']} />
      <mesh geometry={nodes.chassis318_19.geometry} material={materials['Material.011']} />
      <mesh geometry={nodes.chassis318_20.geometry} material={materials['Material.012']} />
      <mesh geometry={nodes.chassis318_21.geometry} material={materials['Material.013']} />
      <mesh geometry={nodes.chassis318_22.geometry} material={materials['Material.014']} />
      <mesh geometry={nodes.chassis318_23.geometry} material={materials['Material.015']} />
      <mesh geometry={nodes.chassis318_24.geometry} material={materials['Material.016']} />
      <mesh geometry={nodes.chassis318_25.geometry} material={materials['Material.017']} />
      <mesh geometry={nodes.chassis318_26.geometry} material={materials['Material.018']} />
      <mesh geometry={nodes.chassis318_27.geometry} material={materials['Material.019']} />
      <mesh geometry={nodes.chassis318_28.geometry} material={materials['Material.020']} />
      <mesh geometry={nodes.chassis318_29.geometry} material={materials['Material.021']} />
      <mesh geometry={nodes.chassis318_30.geometry} material={materials['Material.022']} />
      <mesh geometry={nodes.chassis318_31.geometry} material={materials['Material.023']} />
      <mesh geometry={nodes.chassis318_32.geometry} material={materials['Material.029']} />
      <mesh geometry={nodes.chassis318_33.geometry} material={materials['Material.032']} />
      <mesh geometry={nodes.chassis318_34.geometry} material={materials['Material.035']} />
      <mesh geometry={nodes.chassis318_35.geometry} material={materials['Material.001']} />
      <mesh geometry={nodes.chassis318_36.geometry} material={materials['Material.003']} />
      <mesh geometry={nodes.chassis318_37.geometry} material={materials['Material.002']} />
      <mesh geometry={nodes.chassis318_38.geometry} material={materials['Material.004']} />
      <mesh geometry={nodes.chassis318_39.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.chassis318_40.geometry} material={materials['Material.040']} />
      <mesh
        geometry={nodes.Text.geometry}
        material={materials['Material.038']}
        position={[-0.11, 1.43, 0.33]}
        rotation={[1.29, 0, 0]}
        scale={0.12}
      />
      <mesh
        geometry={nodes.Text001.geometry}
        material={materials['Material.039']}
        position={[0.11, 1.43, 0.28]}
        rotation={[1.85, 0, Math.PI]}
        scale={0.12}
      />
    </group>
  </mesh>  
  )
})

export default Beetle

