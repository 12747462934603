/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF , useAnimations } from "@react-three/drei";
import { useBox } from "@react-three/cannon";

import file from './bitsverse.glb'

export default function Bitsverse({ ...props }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(file);
  const { actions } = useAnimations(animations, group)

  const [groundPlane] = useBox(() => ({
    type: "Static",
    mass: 1000,
    args: [1000, 42, 1000],
    position: [-5, 0, 0],
  }));



  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="VRayPhysicalCamera001Target" position={[-384.98, 17.81, 317.72]} rotation={[0, Math.PI / 2, 0]} scale={0.03} />
        <group name="VRaySun001" position={[-323.18, 161.32, 284.38]} rotation={[2.07, 0.64, -2.31]} scale={0.03} />
        <group name="VRaySun001Target" position={[-447.16, 11.62, 363.9]} rotation={[0, Math.PI / 2, 0]} scale={0.03} />
        <group name="Layer0_001180" position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <group name="Cube117" position={[-285.92, 11.73, -4.96]} scale={[13.83, 1, 23.01]} />
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1034" position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1048" position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <group name="BezierCircle" position={[-467.3, 15.32, -133.61]} rotation={[0, -Math.PI / 2, 0]} scale={[3.81, 5.09, 3.19]} />
        <group name="BezierCircle001" position={[-226.43, 14.61, -133.19]} rotation={[0, Math.PI / 2, 0]} scale={[4.62, 3.52, 3.86]} />
        <group name="braid_circle006" position={[18.31, 0.62, 0]} />
        <group name="braid_circle007" position={[18.31, 0.62, 0]} />
        <group name="braid_circle024" position={[-497.32, 0, 2.93]} rotation={[-Math.PI, 0, -Math.PI]} />
        <group name="braid_circle025" position={[-497.32, 0, 2.93]} rotation={[-Math.PI, 0, -Math.PI]} />
        <group name="braid_circle026" position={[-488.25, 0, -76.32]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.72, 1, 0.72]} />
        <group name="braid_circle027" position={[-488.25, 0, -76.32]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.72, 1, 0.72]} />
        <group name="braid_circle028" position={[46.28, 0, -0.05]} />
        <group name="braid_circle029" position={[46.28, 0, -0.05]} />
        <group name="braid_circle030" position={[-0.68, 0, 160.38]} />
        <group name="braid_circle031" position={[-0.68, 0, 160.38]} />
        <group name="Cube" position={[-223.64, 7.57, -123.97]} scale={0.62} />
        <group name="Cube011" position={[-318.01, 16.49, -178.16]} rotation={[0, -Math.PI / 2, 0]} scale={[1.06, 1.43, 1]} />
        <group name="Cube089" position={[-282.87, 15.3, -240.68]} rotation={[0, Math.PI / 2, 0]} scale={7.75}>
          <mesh name="Text001" geometry={nodes.Text001.geometry} material={materials['Material.6187']} position={[0.6, 0.38, 1.01]} rotation={[Math.PI / 2, 0, 0]} scale={0.09} />
        </group>
        <group name="Empty" position={[-241.65, 10.98, -247.35]} rotation={[0, -Math.PI / 2, 0]} scale={[0.81, 1.05, 0.76]} />
        <group name="Final014" position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <group name="Final015" position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <group name="Final024" position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <group name="Final025" position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <group name="Final028" position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <group name="Final029" position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <group name="Final708" position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <group name="Final709" position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <group name="Final710" position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <group name="Final711" position={[-177.2, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <group name="Final716" position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <group name="Final717" position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <group name="Layer0_001" position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <group name="Final019" position={[-265.75, 13.84, 78.46]} rotation={[0, Math.PI / 2, 0]} scale={[2.02, 1.71, 2.02]} />
        <group name="BB_+_Tennis_Court174" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <group name="BB_+_Tennis_Court187" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <group name="BB_+_Tennis_Court204" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="DoorKnob_004" geometry={nodes.DoorKnob_004.geometry} material={nodes.DoorKnob_004.material} position={[-356.5, 12.7, 312.76]} rotation={[Math.PI / 2, 0, 0]} scale={[0.03, 0.03, 0.04]} />
        <mesh name="DoorKnob_002" geometry={nodes.DoorKnob_002.geometry} material={nodes.DoorKnob_002.material} position={[-349.35, 12.7, 318.28]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[0.03, 0.03, 0.04]} />
        <mesh name="DoorKnob_001" geometry={nodes.DoorKnob_001.geometry} material={nodes.DoorKnob_001.material} position={[-349.32, 12.7, 318.28]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={[-0.03, -0.03, -0.04]} />
        <mesh name="Roof_001" geometry={nodes.Roof_001.geometry} material={materials.dbl_Roof_006} position={[-354.83, 17.66, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001003" geometry={nodes.Layer0_001003.geometry} material={materials['dbl_Layer0_001.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001023" geometry={nodes.Layer0_001023.geometry} material={materials['dbl_Layer0_001.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001024" geometry={nodes.Layer0_001024.geometry} material={materials['dbl_Layer0_001.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001032" geometry={nodes.Layer0_001032.geometry} material={materials['dbl_Layer0_001.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001034" geometry={nodes.Layer0_001034.geometry} material={materials['dbl_Layer0_001.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001065" geometry={nodes.Layer0_001065.geometry} material={materials['dbl_Layer0_001.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001066" geometry={nodes.Layer0_001066.geometry} material={materials['dbl_Layer0_002.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001067" geometry={nodes.Layer0_001067.geometry} material={materials['dbl_Layer0_001.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001068" geometry={nodes.Layer0_001068.geometry} material={materials['dbl_Layer0_001.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001069" geometry={nodes.Layer0_001069.geometry} material={materials['dbl_Layer0_002.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001070" geometry={nodes.Layer0_001070.geometry} material={materials['dbl_Layer0_002.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001071" geometry={nodes.Layer0_001071.geometry} material={materials['dbl_Layer0_002.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001072" geometry={nodes.Layer0_001072.geometry} material={materials['dbl_Layer0_002.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001073" geometry={nodes.Layer0_001073.geometry} material={materials['dbl_Layer0_002.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001074" geometry={nodes.Layer0_001074.geometry} material={materials['dbl_Layer0_002.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001075" geometry={nodes.Layer0_001075.geometry} material={materials['dbl_Layer0_002.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001076" geometry={nodes.Layer0_001076.geometry} material={materials['dbl_Layer0_002.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001077" geometry={nodes.Layer0_001077.geometry} material={materials['dbl_Layer0_002.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001078" geometry={nodes.Layer0_001078.geometry} material={materials['dbl_Layer0_002.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001079" geometry={nodes.Layer0_001079.geometry} material={materials['dbl_Layer0_002.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001080" geometry={nodes.Layer0_001080.geometry} material={materials['dbl_Layer0_003.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001081" geometry={nodes.Layer0_001081.geometry} material={materials['dbl_Layer0_001.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001082" geometry={nodes.Layer0_001082.geometry} material={materials['dbl_Layer0_001.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001083" geometry={nodes.Layer0_001083.geometry} material={materials['dbl_Layer0_004.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001084" geometry={nodes.Layer0_001084.geometry} material={materials['dbl_Layer0_001.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001085" geometry={nodes.Layer0_001085.geometry} material={materials['dbl_Layer0_006.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001086" geometry={nodes.Layer0_001086.geometry} material={materials['dbl_Layer0_001.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001087" geometry={nodes.Layer0_001087.geometry} material={materials['dbl_Layer0_001.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001088" geometry={nodes.Layer0_001088.geometry} material={materials['dbl_Layer0_001.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001089" geometry={nodes.Layer0_001089.geometry} material={materials.dbl_Layer0_047} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001102" geometry={nodes.Layer0_001102.geometry} material={materials['dbl_Layer0_003.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001147" geometry={nodes.Layer0_001147.geometry} material={materials['dbl_Layer0_009.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001148" geometry={nodes.Layer0_001148.geometry} material={materials['dbl_Layer0_009.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001149" geometry={nodes.Layer0_001149.geometry} material={materials['dbl_Layer0_009.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001150" geometry={nodes.Layer0_001150.geometry} material={materials['dbl_Layer0_009.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001151" geometry={nodes.Layer0_001151.geometry} material={materials['dbl_Layer0_009.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001152" geometry={nodes.Layer0_001152.geometry} material={materials['dbl_Layer0_009.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001153" geometry={nodes.Layer0_001153.geometry} material={materials['dbl_Layer0_009.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001181" geometry={nodes.Layer0_001181.geometry} material={materials.dbl_Layer0_012} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001183" geometry={nodes.Layer0_001183.geometry} material={materials.dbl_Layer0_012} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001184" geometry={nodes.Layer0_001184.geometry} material={materials.dbl_Layer0_012} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001185" geometry={nodes.Layer0_001185.geometry} material={materials.dbl_Layer0_012} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001186" geometry={nodes.Layer0_001186.geometry} material={materials.dbl_Layer0_012} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001187" geometry={nodes.Layer0_001187.geometry} material={materials['dbl_Layer0_001.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001188" geometry={nodes.Layer0_001188.geometry} material={materials['dbl_Layer0_001.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001189" geometry={nodes.Layer0_001189.geometry} material={materials['dbl_Layer0_001.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001190" geometry={nodes.Layer0_001190.geometry} material={materials['dbl_Layer0_001.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001192" geometry={nodes.Layer0_001192.geometry} material={materials['dbl_Layer0_014.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001201" geometry={nodes.Layer0_001201.geometry} material={materials['dbl_Layer0_002.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001202" geometry={nodes.Layer0_001202.geometry} material={materials['dbl_Layer0_002.001']} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001203" geometry={nodes.Layer0_001203.geometry} material={materials.dbl_Layer0_016} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001204" geometry={nodes.Layer0_001204.geometry} material={materials.dbl_Layer0_017} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001205" geometry={nodes.Layer0_001205.geometry} material={materials.dbl_Layer0_017} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001206" geometry={nodes.Layer0_001206.geometry} material={materials.dbl_Layer0_017} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001221" geometry={nodes.Layer0_001221.geometry} material={materials.dbl_Layer0_019} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001231" geometry={nodes.Layer0_001231.geometry} material={materials.dbl_Layer0_020} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001235" geometry={nodes.Layer0_001235.geometry} material={materials.dbl_Layer0_021} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001352" geometry={nodes.Layer0_001352.geometry} material={materials.dbl_Layer0_047} position={[-354.7, 13.14, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={0.03} />
        <mesh name="Layer0_001353" geometry={nodes.Layer0_001353.geometry} material={materials.dbl_Layer0_047} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001354" geometry={nodes.Layer0_001354.geometry} material={materials.dbl_Layer0_047} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001355" geometry={nodes.Layer0_001355.geometry} material={materials.dbl_Layer0_047} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001356" geometry={nodes.Layer0_001356.geometry} material={materials.dbl_Layer0_047} position={[-354.7, 13.22, 317.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001357" geometry={nodes.Layer0_001357.geometry} material={materials.dbl_Layer0_047} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001358" geometry={nodes.Layer0_001358.geometry} material={materials.dbl_Layer0_047} position={[-354.7, 13.14, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={0.03} />
        <mesh name="Layer0_001359" geometry={nodes.Layer0_001359.geometry} material={materials.dbl_Layer0_047} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001360" geometry={nodes.Layer0_001360.geometry} material={materials.dbl_Layer0_047} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001361" geometry={nodes.Layer0_001361.geometry} material={materials.dbl_Layer0_047} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001362" geometry={nodes.Layer0_001362.geometry} material={materials.dbl_Layer0_047} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001363" geometry={nodes.Layer0_001363.geometry} material={materials.dbl_Layer0_047} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001364" geometry={nodes.Layer0_001364.geometry} material={materials.dbl_Layer0_047} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001365" geometry={nodes.Layer0_001365.geometry} material={materials.dbl_Layer0_047} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001366" geometry={nodes.Layer0_001366.geometry} material={materials.dbl_Layer0_047} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001367" geometry={nodes.Layer0_001367.geometry} material={materials.dbl_Layer0_047} position={[-354.7, 13.14, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={0.03} />
        <mesh name="Layer0_001368" geometry={nodes.Layer0_001368.geometry} material={materials.dbl_Layer0_047} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001369" geometry={nodes.Layer0_001369.geometry} material={materials.dbl_Layer0_047} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001370" geometry={nodes.Layer0_001370.geometry} material={materials.dbl_Layer0_047} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001371" geometry={nodes.Layer0_001371.geometry} material={materials.dbl_Layer0_047} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001372" geometry={nodes.Layer0_001372.geometry} material={materials.dbl_Layer0_047} position={[-354.7, 13.22, 315.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Layer0_001090" geometry={nodes.Layer0_001090.geometry} material={materials['dbl_Layer0_003.002']} position={[-354.7, 13.22, 306.88]} rotation={[0, Math.PI / 2, 0]} scale={[0.03, 0.04, 0.03]} />
        <mesh name="Cube108" geometry={nodes.Cube108.geometry} material={materials['Material.1461']} position={[-354.98, 6.43, -1.37]} scale={[22.47, 4.5, 4.5]} />
        <group name="Cube517" position={[-339.37, 2.9, -32.7]} rotation={[-Math.PI, 0, -Math.PI]} scale={[3.59, 7.94, 7.94]}>
          <mesh name="Cube417" geometry={nodes.Cube417.geometry} material={materials['Material.1470']} />
          <mesh name="Cube417_1" geometry={nodes.Cube417_1.geometry} material={materials['Material.975']} />
          <mesh name="Cube417_2" geometry={nodes.Cube417_2.geometry} material={materials['Material.976']} />
          <mesh name="Cube417_3" geometry={nodes.Cube417_3.geometry} material={materials['Material.977']} />
          <mesh name="Cube417_4" geometry={nodes.Cube417_4.geometry} material={materials['Material.194']} />
        </group>
        <mesh name="Cube359" geometry={nodes.Cube359.geometry} material={materials['Material.6247']} position={[-324.87, 9.97, -2.8]} scale={[1, 1, 23.93]} />
        <mesh name="Cube368" geometry={nodes.Cube368.geometry} material={materials['Material.6248']} position={[-343.8, 9.97, 39.46]} rotation={[0, Math.PI / 2, 0]} scale={[1, 1, 23.93]} />
        <mesh name="Cube369" geometry={nodes.Cube369.geometry} material={materials['Material.6249']} position={[-387.85, 9.97, -1.21]} scale={[1, 1, 23.93]} />
        <mesh name="Circle015" geometry={nodes.Circle015.geometry} material={materials['Material.774']} position={[-355.29, 7.23, -126.83]} scale={[22.11, 25.42, 22.11]} />
        <mesh name="Cylinder045" geometry={nodes.Cylinder045.geometry} material={materials['Material.3646']} position={[-355.2, 3.53, -126.68]} scale={[22.51, 3.87, 23.11]} />
        <mesh name="Circle" geometry={nodes.Circle.geometry} material={materials['Material.3647']} position={[-354.56, 7.42, -129.22]} scale={7.9} />
        <group name="Cube116" position={[-285.92, 11.73, -13.01]} scale={[13.83, 1, 23.01]}>
          <mesh name="Cube9529" geometry={nodes.Cube9529.geometry} material={materials['Material.181']} />
          <mesh name="Cube9529_1" geometry={nodes.Cube9529_1.geometry} material={materials['Material.486']} />
          <mesh name="Cube9529_2" geometry={nodes.Cube9529_2.geometry} material={materials['Material.247']} />
        </group>
        <mesh name="Cube9987" geometry={nodes.Cube9987.geometry} material={materials['Material.3693']} position={[-344.79, 5.64, -107.43]} scale={[1, 2.72, 1]} />
        <mesh name="Circle019" geometry={nodes.Circle019.geometry} material={materials['Material.803']} position={[-364.6, 9.99, -119.65]} rotation={[-Math.PI, 1.11, -Math.PI]} scale={[14.82, 1.68, 9.62]} />
        <group name="Circle021" position={[-348.26, 8.69, -118]} rotation={[Math.PI, -1.03, Math.PI]} scale={[14.82, 1.68, 9.62]}>
          <mesh name="Circle026_1" geometry={nodes.Circle026_1.geometry} material={materials['Material.3689']} />
          <mesh name="Circle026_2" geometry={nodes.Circle026_2.geometry} material={materials['Material.3690']} />
        </group>
        <mesh name="Cube9992" geometry={nodes.Cube9992.geometry} material={materials['Material.802']} position={[-335.18, 5.64, -135.26]} rotation={[0, Math.PI / 2, 0]} scale={[1, 2.72, 1]} />
        <mesh name="Cube202" geometry={nodes.Cube202.geometry} material={materials['Material.3694']} position={[-360.99, 5.64, -107.43]} scale={[1, 2.72, 1]} />
        <mesh name="Cube12333" geometry={nodes.Cube12333.geometry} material={materials['Material.3679']} position={[-367.39, 3.94, -24.56]} scale={[0.5, 5.02, 0.5]} />
        <mesh name="Plane075" geometry={nodes.Plane075.geometry} material={materials['Material.3687']} position={[-366.05, 10.33, -106.19]} scale={10.78} />
        <mesh name="Plane076" geometry={nodes.Plane076.geometry} material={materials['Material.3688']} position={[-341.76, 10.26, -106.19]} rotation={[0, 0, Math.PI]} scale={10.78} />
        <group name="Circle024" position={[-351.17, 7.06, -125.86]} scale={4.17}>
          <mesh name="Circle017" geometry={nodes.Circle017.geometry} material={materials['Material.045']} />
          <mesh name="Circle017_1" geometry={nodes.Circle017_1.geometry} material={materials['Material.046']} />
          <mesh name="Circle017_2" geometry={nodes.Circle017_2.geometry} material={materials['Material.047']} />
        </group>
        <group name="Circle025" position={[-357.75, 6.99, -125.74]} rotation={[-Math.PI, -1.25, -Math.PI]} scale={4.17}>
          <mesh name="Circle023" geometry={nodes.Circle023.geometry} material={materials['Material.048']} />
          <mesh name="Circle023_1" geometry={nodes.Circle023_1.geometry} material={materials['Material.049']} />
          <mesh name="Circle023_2" geometry={nodes.Circle023_2.geometry} material={materials['Material.050']} />
        </group>
        <group name="Circle026" position={[-357.75, 6.99, -132.82]} rotation={[-Math.PI, 0.36, -Math.PI]} scale={4.17}>
          <mesh name="Circle024_1" geometry={nodes.Circle024_1.geometry} material={materials['Material.051']} />
          <mesh name="Circle024_2" geometry={nodes.Circle024_2.geometry} material={materials['Material.052']} />
          <mesh name="Circle024_3" geometry={nodes.Circle024_3.geometry} material={materials['Material.053']} />
        </group>
        <group name="Circle027" position={[-351.17, 7.06, -132.44]} rotation={[0, 1.55, 0]} scale={4.17}>
          <mesh name="Circle029" geometry={nodes.Circle029.geometry} material={materials['Material.054']} />
          <mesh name="Circle029_1" geometry={nodes.Circle029_1.geometry} material={materials['Material.192']} />
          <mesh name="Circle029_2" geometry={nodes.Circle029_2.geometry} material={materials['Material.252']} />
        </group>
        <mesh name="Cube12336" geometry={nodes.Cube12336.geometry} material={materials['Material.804']} position={[-388.62, 5.64, -126.71]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1, 2.72, 1]} />
        <mesh name="Plane077" geometry={nodes.Plane077.geometry} material={materials['Material.3692']} position={[-384.42, 10.55, -116.71]} scale={[3.54, 1, 1]} />
        <mesh name="Plane082" geometry={nodes.Plane082.geometry} material={materials['Material.3691']} position={[-327.58, 10.7, -119.37]} scale={3.09} />
        <group name="Cylinder203" position={[-361.2, 0, -4.4]} scale={0.13}>
          <mesh name="Cylinder326" geometry={nodes.Cylinder326.geometry} material={materials['Material.955']} />
          <mesh name="Cylinder326_1" geometry={nodes.Cylinder326_1.geometry} material={materials['Material.956']} />
        </group>
        <group name="Cylinder204" position={[-361.15, -1.12, -8.73]} scale={0.13}>
          <mesh name="Cylinder327" geometry={nodes.Cylinder327.geometry} material={materials['Material.957']} />
          <mesh name="Cylinder327_1" geometry={nodes.Cylinder327_1.geometry} material={materials['Material.958']} />
        </group>
        <group name="Cylinder215" position={[-347.98, 0.04, -4.4]} scale={0.13}>
          <mesh name="Cylinder334" geometry={nodes.Cylinder334.geometry} material={materials['Material.971']} />
          <mesh name="Cylinder334_1" geometry={nodes.Cylinder334_1.geometry} material={materials['Material.972']} />
        </group>
        <group name="Cylinder216" position={[-347.92, -1.15, -8.73]} scale={0.13}>
          <mesh name="Cylinder339" geometry={nodes.Cylinder339.geometry} material={materials['Material.973']} />
          <mesh name="Cylinder339_1" geometry={nodes.Cylinder339_1.geometry} material={materials['Material.974']} />
        </group>
        <mesh name="Cube12484" geometry={nodes.Cube12484.geometry} material={materials['Material.3680']} position={[-371, 3.94, -24.56]} scale={[0.5, 3.29, 0.5]} />
        <group name="Cube412" position={[-366.57, 2.69, -32.71]} scale={[3.59, 7.94, 7.94]}>
          <mesh name="Cube737" geometry={nodes.Cube737.geometry} material={materials['Material.1014']} />
          <mesh name="Cube737_1" geometry={nodes.Cube737_1.geometry} material={materials['Material.1015']} />
          <mesh name="Cube737_2" geometry={nodes.Cube737_2.geometry} material={materials['Material.1016']} />
          <mesh name="Cube737_3" geometry={nodes.Cube737_3.geometry} material={materials['Material.1017']} />
        </group>
        <group name="Cube12491" position={[-363.69, 6.18, -37.35]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1, 1.63, 1]}>
          <mesh name="Cube738" geometry={nodes.Cube738.geometry} material={materials['Material.1018']} />
          <mesh name="Cube738_1" geometry={nodes.Cube738_1.geometry} material={materials['Material.1019']} />
          <mesh name="Cube738_2" geometry={nodes.Cube738_2.geometry} material={materials['Material.1020']} />
          <mesh name="Cube738_3" geometry={nodes.Cube738_3.geometry} material={materials['Material.1021']} />
          <mesh name="Cube738_4" geometry={nodes.Cube738_4.geometry} material={materials['Material.1022']} />
          <mesh name="Cube738_5" geometry={nodes.Cube738_5.geometry} material={materials['Material.1023']} />
        </group>
        <group name="Cube109" position={[-366.57, 2.69, -51.38]} scale={[3.59, 7.94, 7.94]}>
          <mesh name="Cube739" geometry={nodes.Cube739.geometry} material={materials['Material.3649']} />
          <mesh name="Cube739_1" geometry={nodes.Cube739_1.geometry} material={materials['Material.3650']} />
          <mesh name="Cube739_2" geometry={nodes.Cube739_2.geometry} material={materials['Material.3651']} />
          <mesh name="Cube739_3" geometry={nodes.Cube739_3.geometry} material={materials['Material.3652']} />
        </group>
        <group name="Cube420" position={[-363.69, 6.18, -56.03]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1, 1.63, 1]}>
          <mesh name="Cube740" geometry={nodes.Cube740.geometry} material={materials['Material.3653']} />
          <mesh name="Cube740_1" geometry={nodes.Cube740_1.geometry} material={materials['Material.3654']} />
          <mesh name="Cube740_2" geometry={nodes.Cube740_2.geometry} material={materials['Material.3655']} />
          <mesh name="Cube740_3" geometry={nodes.Cube740_3.geometry} material={materials['Material.3656']} />
          <mesh name="Cube740_4" geometry={nodes.Cube740_4.geometry} material={materials['Material.3657']} />
          <mesh name="Cube740_5" geometry={nodes.Cube740_5.geometry} material={materials['Material.3658']} />
        </group>
        <group name="Cube421" position={[-366.57, 2.69, -69.44]} scale={[3.59, 7.94, 7.94]}>
          <mesh name="Cube741" geometry={nodes.Cube741.geometry} material={materials['Material.3659']} />
          <mesh name="Cube741_1" geometry={nodes.Cube741_1.geometry} material={materials['Material.3660']} />
          <mesh name="Cube741_2" geometry={nodes.Cube741_2.geometry} material={materials['Material.3661']} />
          <mesh name="Cube741_3" geometry={nodes.Cube741_3.geometry} material={materials['Material.3662']} />
        </group>
        <group name="Cube12492" position={[-363.69, 6.18, -74.09]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1, 1.63, 1]}>
          <mesh name="Cube880" geometry={nodes.Cube880.geometry} material={materials['Material.3663']} />
          <mesh name="Cube880_1" geometry={nodes.Cube880_1.geometry} material={materials['Material.3664']} />
          <mesh name="Cube880_2" geometry={nodes.Cube880_2.geometry} material={materials['Material.3665']} />
          <mesh name="Cube880_3" geometry={nodes.Cube880_3.geometry} material={materials['Material.3666']} />
          <mesh name="Cube880_4" geometry={nodes.Cube880_4.geometry} material={materials['Material.3667']} />
          <mesh name="Cube880_5" geometry={nodes.Cube880_5.geometry} material={materials['Material.3668']} />
        </group>
        <group name="Cube12493" position={[-366.57, 2.69, -90.76]} scale={[3.59, 7.94, 7.94]}>
          <mesh name="Cube881" geometry={nodes.Cube881.geometry} material={materials['Material.3669']} />
          <mesh name="Cube881_1" geometry={nodes.Cube881_1.geometry} material={materials['Material.3670']} />
          <mesh name="Cube881_2" geometry={nodes.Cube881_2.geometry} material={materials['Material.3671']} />
          <mesh name="Cube881_3" geometry={nodes.Cube881_3.geometry} material={materials['Material.3672']} />
        </group>
        <group name="Cube12494" position={[-363.69, 6.18, -95.41]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1, 1.63, 1]}>
          <mesh name="Cube882" geometry={nodes.Cube882.geometry} material={materials['Material.3673']} />
          <mesh name="Cube882_1" geometry={nodes.Cube882_1.geometry} material={materials['Material.3674']} />
          <mesh name="Cube882_2" geometry={nodes.Cube882_2.geometry} material={materials['Material.3675']} />
          <mesh name="Cube882_3" geometry={nodes.Cube882_3.geometry} material={materials['Material.3676']} />
          <mesh name="Cube882_4" geometry={nodes.Cube882_4.geometry} material={materials['Material.3677']} />
          <mesh name="Cube882_5" geometry={nodes.Cube882_5.geometry} material={materials['Material.3678']} />
        </group>
        <mesh name="1200px-BITS_Pilani-Logosvg_-1024x1024" geometry={nodes['1200px-BITS_Pilani-Logosvg_-1024x1024'].geometry} material={materials['1200px-BITS_Pilani-Logo.svg_-1024x1024']} position={[-49.6, 16.91, 50.21]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1.02, 1.02, 1.19]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1001" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1001'].geometry} material={materials['Material.846']} position={[-487.32, 10.48, -72.45]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1002" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1002'].geometry} material={materials['Material.945']} position={[-459.89, 11.67, -147.83]} rotation={[-Math.PI, 0.76, -Math.PI]} scale={[0.02, 0.15, 0.04]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1003" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1003'].geometry} material={materials['Material.946']} position={[-461.43, 11.65, -146.91]} rotation={[-Math.PI, 0.76, -Math.PI]} scale={[0.02, 0.15, 0.05]} />
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1031" position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1623" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1623'].geometry} material={materials['Material.949']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1623_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1623_1'].geometry} material={materials['Material.950']} />
        </group>
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1032" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1032'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1033" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1033'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1035" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1035'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1036" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1036'].geometry} material={materials['Material.953']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1039" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1039'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1040" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1040'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1041" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1041'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1042" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1042'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1043" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1043'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1044" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1044'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1045" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1045'].geometry} material={materials['Material.954']} position={[-481.61, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1046" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1046'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1047" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1047'].geometry} material={materials['Default OBJ.001']} position={[-481.61, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1049" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1049'].geometry} material={materials['Material.961']} position={[-487.32, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1051" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1051'].geometry} material={materials['Material.962']} position={[-487.32, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1052" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1052'].geometry} material={materials['Material.965']} position={[-487.32, 10.75, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.11, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1053" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1053'].geometry} material={materials['Material.966']} position={[-487.32, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1054" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1054'].geometry} material={materials['Default OBJ.001']} position={[-487.32, 10.75, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.11, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1055" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1055'].geometry} material={materials['Default OBJ.001']} position={[-487.32, 10.75, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.11, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1056" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1056'].geometry} material={materials['Default OBJ.001']} position={[-487.32, 10.75, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.11, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1058" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1058'].geometry} material={materials['Default OBJ.001']} position={[-487.32, 10.75, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.11, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1059" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1059'].geometry} material={materials['Default OBJ.001']} position={[-487.32, 10.75, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.11, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1061" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1061'].geometry} material={materials['Default OBJ.001']} position={[-487.32, 10.75, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.11, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1062" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1062'].geometry} material={materials['Default OBJ.001']} position={[-487.32, 10.75, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.11, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1063" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1063'].geometry} material={materials['Default OBJ.001']} position={[-487.32, 10.75, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.11, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1064" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1064'].geometry} material={materials['Material.969']} position={[-483.07, 10.4, -97.32]} rotation={[Math.PI, 0, Math.PI]} scale={[0.09, 0.14, 0.09]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1066" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1066'].geometry} material={materials['Material.970']} position={[-483.07, 10.4, -97.32]} rotation={[Math.PI, 0, Math.PI]} scale={[0.09, 0.14, 0.09]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1067" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1067'].geometry} material={materials['Material.3695']} position={[-478.51, 10.66, -161.11]} rotation={[Math.PI, 0, Math.PI]} scale={[0.12, 0.19, 0.13]} />
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1068" position={[-485.27, 9.8, -77.14]} rotation={[Math.PI, 0, Math.PI]} scale={[0.12, 0.17, 0.12]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1654" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1654'].geometry} material={materials['Material.3696']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1654_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1654_1'].geometry} material={materials['Material.3697']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1654_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1654_2'].geometry} material={materials['Material.3698']} />
        </group>
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1069" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1069'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1070" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1070'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1071" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1071'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1072" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1072'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1073" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1073'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1074" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1074'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1075" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1075'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1076" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1076'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1077" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1077'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1078" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1078'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1079" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1079'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1080" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1080'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1081" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1081'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1082" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1082'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1083" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1083'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1084" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1084'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1085" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1085'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1086" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1086'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1087" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1087'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1088" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1088'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1089" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1089'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1090" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1090'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1091" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1091'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1092" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1092'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1093" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1093'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1094" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1094'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1095" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1095'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1096" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1096'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1097" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1097'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1098" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1098'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1099" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1099'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1100" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1100'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1101" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1101'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1102" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1102'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1103" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1103'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1104" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1104'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1105" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1105'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1106" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1106'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1107" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1107'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1108" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1108'].geometry} material={materials['Material.3699']} position={[-209.07, 12.95, -169.82]} scale={[0.09, 0.08, 0.09]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1109" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1109'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1110" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1110'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1111" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1111'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1117" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1117'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -95.05]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1402" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1402'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1403" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1403'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1404" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1404'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1405" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1405'].geometry} material={materials['Default OBJ.001']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1407" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1407'].geometry} material={materials['Material.3702']} position={[-481.62, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1409" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1409'].geometry} material={materials['Material.3703']} position={[-487.32, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1412" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1412'].geometry} material={materials['Default OBJ.001']} position={[-487.32, 10.75, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.11, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1415" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1415'].geometry} material={materials['Material.3704']} position={[-487.32, 10.48, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.12, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1416" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1416'].geometry} material={materials['Default OBJ.001']} position={[-487.32, 10.75, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.11, 0.07]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1417" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1417'].geometry} material={materials['Default OBJ.001']} position={[-487.32, 10.75, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.11, 0.07]} />
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1418" position={[-487.32, 10.75, -103.4]} rotation={[Math.PI, 0, Math.PI]} scale={[0.07, 0.11, 0.07]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1709" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1709'].geometry} material={materials['Material.3705']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1709_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1709_1'].geometry} material={materials['Material.3706']} />
        </group>
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1477" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1477'].geometry} material={materials['Wood_Not_Painted.001']} position={[-502.86, 10.57, -110.59]} rotation={[Math.PI, 0, Math.PI]} scale={[0.02, 0.04, 0.02]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1529" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1529'].geometry} material={materials['Material.3699']} position={[-209.02, 10.67, -169.82]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1530" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1530'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -169.82]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1531" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1531'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -169.82]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1532" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1532'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -169.82]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1533" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1533'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -169.82]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1534" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1534'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -169.82]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1535" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1535'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -169.82]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1536" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1536'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -169.82]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1537" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1537'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -169.82]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1538" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1538'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -169.82]} scale={0.09} />
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1539" position={[-209.07, 10.67, -169.82]} scale={0.09}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1721" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1721'].geometry} material={materials['Material.3699']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1721_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1721_1'].geometry} material={materials['Material.3700']} />
        </group>
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1540" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1540'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -169.82]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1541" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1541'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -169.82]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1545" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1545'].geometry} material={materials['Material.3699']} position={[-209.07, 12.35, -172.99]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1548" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1548'].geometry} material={materials['Material.3699']} position={[-209.07, 11.73, -169.82]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1549" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1549'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -169.82]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1552" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1552'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -169.82]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1553" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1553'].geometry} material={materials['Material.3699']} position={[-209.07, 12.35, -169.82]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1554" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1554'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -169.82]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1555" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1555'].geometry} material={materials['Material.3699']} position={[-209.07, 11.73, -172.99]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1556" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1556'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -169.82]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1557" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1557'].geometry} material={materials['Material.3699']} position={[-209.07, 12.35, -172.99]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1559" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1559'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -160.35]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1561" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1561'].geometry} material={materials['Material.3699']} position={[-209.07, 12.35, -169.82]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1562" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1562'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -172.99]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1563" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1563'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -169.82]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1564" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1564'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -172.99]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1567" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1567'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -172.99]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1569" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1569'].geometry} material={materials['Material.3699']} position={[-209.07, 12.35, -163.52]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1570" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1570'].geometry} material={materials['Material.3699']} position={[-209.07, 11.73, -172.99]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1571" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1571'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -169.82]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1573" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1573'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -169.82]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1575" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1575'].geometry} material={materials['Material.3699']} position={[-209.07, 12.35, -172.99]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1577" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1577'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -172.99]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1579" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1579'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -172.99]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1580" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1580'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -172.99]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1581" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1581'].geometry} material={materials['Material.3699']} position={[-209.07, 12.35, -172.99]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1582" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1582'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -172.99]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1583" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1583'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -160.35]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1584" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1584'].geometry} material={materials['Material.3699']} position={[-209.07, 12.35, -172.99]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1585" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1585'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -169.82]} scale={0.09} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1586" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1586'].geometry} material={materials['Material.3699']} position={[-209.07, 10.67, -169.82]} scale={0.09} />
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1587" position={[-209.07, 12.95, -169.82]} scale={[0.09, 0.08, 0.09]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1753" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1753'].geometry} material={materials['Material.3700']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1753_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1753_1'].geometry} material={materials['Material.3701']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1588" position={[-209.07, 12.95, -169.82]} scale={[0.09, 0.08, 0.09]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1754" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1754'].geometry} material={materials['Material.3700']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1754_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1754_1'].geometry} material={materials['Material.3701']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1589" position={[-209.07, 12.95, -169.82]} scale={[0.09, 0.08, 0.09]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1755" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1755'].geometry} material={materials['Material.3700']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1755_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1755_1'].geometry} material={materials['Material.3701']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1590" position={[-209.07, 12.95, -169.82]} scale={[0.09, 0.08, 0.09]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1756" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1756'].geometry} material={materials['Material.3700']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1756_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1756_1'].geometry} material={materials['Material.3701']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1591" position={[-209.07, 12.95, -169.82]} scale={[0.09, 0.08, 0.09]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1757" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1757'].geometry} material={materials['Material.3700']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1757_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1757_1'].geometry} material={materials['Material.3701']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1592" position={[-209.07, 12.95, -169.82]} scale={[0.09, 0.08, 0.09]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1758" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1758'].geometry} material={materials['Material.3700']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1758_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1758_1'].geometry} material={materials['Material.3701']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1593" position={[-209.07, 12.95, -169.82]} scale={[0.09, 0.08, 0.09]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1759" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1759'].geometry} material={materials['Material.3700']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1759_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1759_1'].geometry} material={materials['Material.3701']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1594" position={[-209.07, 12.95, -169.82]} scale={[0.09, 0.08, 0.09]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1760" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1760'].geometry} material={materials['Material.3700']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1760_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1760_1'].geometry} material={materials['Material.3701']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1595" position={[-209.07, 12.95, -169.82]} scale={[0.09, 0.08, 0.09]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1761" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1761'].geometry} material={materials['Material.3700']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1761_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1761_1'].geometry} material={materials['Material.3701']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1596" position={[-209.07, 12.95, -169.82]} scale={[0.09, 0.08, 0.09]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1762" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1762'].geometry} material={materials['Material.3700']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1762_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1762_1'].geometry} material={materials['Material.3701']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1597" position={[-209.07, 12.95, -169.91]} scale={[0.09, 0.08, 0.09]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1763" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1763'].geometry} material={materials['Material.3700']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1763_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1763_1'].geometry} material={materials['Material.3701']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1598" position={[-209.07, 12.95, -169.91]} scale={[0.09, 0.08, 0.09]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1764" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1764'].geometry} material={materials['Material.3700']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1764_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1764_1'].geometry} material={materials['Material.3701']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1599" position={[-209.07, 12.95, -169.82]} scale={[0.09, 0.08, 0.09]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1765" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1765'].geometry} material={materials['Material.3700']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1765_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1765_1'].geometry} material={materials['Material.3701']} />
        </group>
        <group name="A_Block" position={[-402.66, 12.6, -324.73]} rotation={[0, Math.PI / 2, 0]} scale={[4.02, 1.49, 38.39]}>
          <mesh name="Cube730" geometry={nodes.Cube730.geometry} material={materials['Roof.001']} />
          <mesh name="Cube730_1" geometry={nodes.Cube730_1.geometry} material={materials['Wall.001']} />
          <mesh name="Cube730_2" geometry={nodes.Cube730_2.geometry} material={materials['Trim.001']} />
          <mesh name="Cube730_3" geometry={nodes.Cube730_3.geometry} material={materials['Windows.003']} />
        </group>
        <group name="B_Block" position={[-380.26, 12.61, -340.71]} rotation={[0, Math.PI / 2, 0]} scale={[4.02, 1.49, 38.39]}>
          <mesh name="Cube883" geometry={nodes.Cube883.geometry} material={materials['Roof.001']} />
          <mesh name="Cube883_1" geometry={nodes.Cube883_1.geometry} material={materials['Wall.001']} />
          <mesh name="Cube883_2" geometry={nodes.Cube883_2.geometry} material={materials['Trim.001']} />
          <mesh name="Cube883_3" geometry={nodes.Cube883_3.geometry} material={materials['Windows.003']} />
        </group>
        <mesh name="Barrister" geometry={nodes.Barrister.geometry} material={nodes.Barrister.material} position={[-356.96, 14.62, 208.95]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.05, 0.53, 0.05]} />
        <mesh name="Base" geometry={nodes.Base.geometry} material={materials['Material.3707']} position={[-357.62, 12.48, 211.71]} rotation={[-Math.PI, 0, -Math.PI]} scale={[11.77, 0.65, 10.11]} />
        <group name="C_Block" position={[-403.08, 12.6, -355.65]} rotation={[0, Math.PI / 2, 0]} scale={[4.02, 1.49, 38.39]}>
          <mesh name="Cube886" geometry={nodes.Cube886.geometry} material={materials['Roof.001']} />
          <mesh name="Cube886_1" geometry={nodes.Cube886_1.geometry} material={materials['Wall.001']} />
          <mesh name="Cube886_2" geometry={nodes.Cube886_2.geometry} material={materials['Trim.001']} />
          <mesh name="Cube886_3" geometry={nodes.Cube886_3.geometry} material={materials['Windows.003']} />
        </group>
        <mesh name="Ceiling_Boxes" geometry={nodes.Ceiling_Boxes.geometry} material={materials['Grayish Concrete.001']} position={[-236.87, 18.98, 248.64]} rotation={[0, Math.PI / 2, 0]} scale={[1.16, 0.64, 0.02]} />
        <mesh name="Central_Building" geometry={nodes.Central_Building.geometry} material={materials['Material.3708']} position={[-474.14, 10.94, 3.71]} rotation={[0, Math.PI / 2, 0]} scale={[1.66, 2.65, 1.66]} />
        <mesh name="Central_Building046" geometry={nodes.Central_Building046.geometry} material={materials['Material.3709']} position={[-473.83, 10.94, 3.71]} rotation={[0, Math.PI / 2, 0]} scale={[1.66, 2.65, 1.66]} />
        <mesh name="Central_Building103" geometry={nodes.Central_Building103.geometry} material={materials['Material.3710']} position={[-474.14, 10.94, 3.71]} rotation={[0, Math.PI / 2, 0]} scale={[1.66, 2.65, 1.66]} />
        <mesh name="Central_Building104" geometry={nodes.Central_Building104.geometry} material={materials['Material.3711']} position={[-474.14, 10.94, 3.71]} rotation={[0, Math.PI / 2, 0]} scale={[1.66, 2.65, 1.66]} />
        <mesh name="Circle001" geometry={nodes.Circle001.geometry} material={materials['Material.3712']} position={[-231.14, 12.75, 305.37]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={[0.99, 1.36, 1.61]} />
        <group name="Circle002" position={[-356.14, 38.37, -173.65]} rotation={[Math.PI / 2, 0, 0]} scale={[1.1, 1.19, 1.13]}>
          <mesh name="Circle006" geometry={nodes.Circle006.geometry} material={materials['Material.3713']} />
          <mesh name="Circle006_1" geometry={nodes.Circle006_1.geometry} material={materials['Material.3714']} />
          <mesh name="Circle006_2" geometry={nodes.Circle006_2.geometry} material={materials['Material.3715']} />
          <mesh name="Circle006_3" geometry={nodes.Circle006_3.geometry} material={materials['Material.3716']} />
          <mesh name="Circle006_4" geometry={nodes.Circle006_4.geometry} material={materials['Material.3717']} />
          <mesh name="Circle006_5" geometry={nodes.Circle006_5.geometry} material={materials['Material.3718']} />
          <mesh name="Circle006_6" geometry={nodes.Circle006_6.geometry} material={materials['Material.3719']} />
          <mesh name="Circle006_7" geometry={nodes.Circle006_7.geometry} material={materials['Material.3720']} />
          <mesh name="Circle006_8" geometry={nodes.Circle006_8.geometry} material={materials['Material.3721']} />
          <mesh name="Circle006_9" geometry={nodes.Circle006_9.geometry} material={materials['Material.3722']} />
          <mesh name="Circle006_10" geometry={nodes.Circle006_10.geometry} material={materials['Material.3723']} />
          <mesh name="Circle006_11" geometry={nodes.Circle006_11.geometry} material={materials['Material.3724']} />
          <mesh name="Circle006_12" geometry={nodes.Circle006_12.geometry} material={materials['Material.3725']} />
          <mesh name="Circle006_13" geometry={nodes.Circle006_13.geometry} material={materials['Material.3726']} />
        </group>
        <group name="Circle003" position={[-356.14, 38.37, -173.65]} rotation={[Math.PI / 2, 0, 0]} scale={[1.1, 1.19, 1.13]}>
          <mesh name="Circle008_1" geometry={nodes.Circle008_1.geometry} material={materials['Material.3727']} />
          <mesh name="Circle008_2" geometry={nodes.Circle008_2.geometry} material={materials['Material.3731']} />
          <mesh name="Circle008_3" geometry={nodes.Circle008_3.geometry} material={materials['Material.3732']} />
          <mesh name="Circle008_4" geometry={nodes.Circle008_4.geometry} material={materials['Material.3733']} />
          <mesh name="Circle008_5" geometry={nodes.Circle008_5.geometry} material={materials['Material.3734']} />
          <mesh name="Circle008_6" geometry={nodes.Circle008_6.geometry} material={materials['Material.3735']} />
          <mesh name="Circle008_7" geometry={nodes.Circle008_7.geometry} material={materials['Material.3736']} />
          <mesh name="Circle008_8" geometry={nodes.Circle008_8.geometry} material={materials['Material.3737']} />
          <mesh name="Circle008_9" geometry={nodes.Circle008_9.geometry} material={materials['Material.3738']} />
          <mesh name="Circle008_10" geometry={nodes.Circle008_10.geometry} material={materials['Material.3739']} />
          <mesh name="Circle008_11" geometry={nodes.Circle008_11.geometry} material={materials['Material.3740']} />
        </group>
        <group name="Circle004" position={[-353.94, 38.37, -175.75]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1.1, 1.19, 1.13]}>
          <mesh name="Circle034" geometry={nodes.Circle034.geometry} material={materials['Material.3741']} />
          <mesh name="Circle034_1" geometry={nodes.Circle034_1.geometry} material={materials['Material.3745']} />
          <mesh name="Circle034_2" geometry={nodes.Circle034_2.geometry} material={materials['Material.3746']} />
          <mesh name="Circle034_3" geometry={nodes.Circle034_3.geometry} material={materials['Material.3747']} />
          <mesh name="Circle034_4" geometry={nodes.Circle034_4.geometry} material={materials['Material.3748']} />
          <mesh name="Circle034_5" geometry={nodes.Circle034_5.geometry} material={materials['Material.3749']} />
          <mesh name="Circle034_6" geometry={nodes.Circle034_6.geometry} material={materials['Material.3750']} />
          <mesh name="Circle034_7" geometry={nodes.Circle034_7.geometry} material={materials['Material.3751']} />
          <mesh name="Circle034_8" geometry={nodes.Circle034_8.geometry} material={materials['Material.3752']} />
          <mesh name="Circle034_9" geometry={nodes.Circle034_9.geometry} material={materials['Material.3753']} />
          <mesh name="Circle034_10" geometry={nodes.Circle034_10.geometry} material={materials['Material.3754']} />
        </group>
        <group name="Circle007" position={[-356.18, 38.37, -177.83]} rotation={[Math.PI / 2, 0, Math.PI]} scale={[1.1, 1.19, 1.13]}>
          <mesh name="Circle039" geometry={nodes.Circle039.geometry} material={materials['Material.3755']} />
          <mesh name="Circle039_1" geometry={nodes.Circle039_1.geometry} material={materials['Material.3759']} />
          <mesh name="Circle039_2" geometry={nodes.Circle039_2.geometry} material={materials['Material.3760']} />
          <mesh name="Circle039_3" geometry={nodes.Circle039_3.geometry} material={materials['Material.3761']} />
          <mesh name="Circle039_4" geometry={nodes.Circle039_4.geometry} material={materials['Material.3762']} />
          <mesh name="Circle039_5" geometry={nodes.Circle039_5.geometry} material={materials['Material.3763']} />
          <mesh name="Circle039_6" geometry={nodes.Circle039_6.geometry} material={materials['Material.3764']} />
          <mesh name="Circle039_7" geometry={nodes.Circle039_7.geometry} material={materials['Material.3765']} />
          <mesh name="Circle039_8" geometry={nodes.Circle039_8.geometry} material={materials['Material.3766']} />
          <mesh name="Circle039_9" geometry={nodes.Circle039_9.geometry} material={materials['Material.3767']} />
          <mesh name="Circle039_10" geometry={nodes.Circle039_10.geometry} material={materials['Material.3768']} />
        </group>
        <mesh name="Circle008" geometry={nodes.Circle008.geometry} material={materials['Material.3769']} position={[-203.3, 14.79, -220.99]} rotation={[Math.PI, 0, Math.PI / 2]} scale={2.19} />
        <mesh name="Circle012" geometry={nodes.Circle012.geometry} material={materials['Material.3770']} position={[-203.3, 14.79, -216.1]} rotation={[Math.PI, 0, Math.PI / 2]} scale={2.19} />
        <group name="Circle013" position={[-356.14, 42.34, -174.39]} rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={[0.75, 0.71, 0.6]}>
          <mesh name="Circle042" geometry={nodes.Circle042.geometry} material={materials['Material.3771']} />
          <mesh name="Circle042_1" geometry={nodes.Circle042_1.geometry} material={materials['Material.3773']} />
          <mesh name="Circle042_2" geometry={nodes.Circle042_2.geometry} material={materials['Material.3774']} />
          <mesh name="Circle042_3" geometry={nodes.Circle042_3.geometry} material={materials['Material.3776']} />
        </group>
        <mesh name="Circle014" geometry={nodes.Circle014.geometry} material={materials['Material.3728']} position={[-356.14, 38.37, -173.65]} rotation={[Math.PI / 2, 0, 0]} scale={[1.1, 1.19, 1.13]} />
        <mesh name="Circle030" geometry={nodes.Circle030.geometry} material={materials['Material.3777']} position={[-203.3, 14.79, -211.35]} rotation={[Math.PI, 0, Math.PI / 2]} scale={2.19} />
        <mesh name="Circle032" geometry={nodes.Circle032.geometry} material={materials['Material.3778']} position={[-231.14, 12.75, 330.58]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={[0.99, 1.36, 1.61]} />
        <mesh name="Circle044" geometry={nodes.Circle044.geometry} material={materials['Material.3790']} position={[-365.32, 10.69, -153.43]} rotation={[0, 0.74, 0]} scale={[13.16, 3.65, 7.85]} />
        <mesh name="Circle045" geometry={nodes.Circle045.geometry} material={materials['Material.3791']} position={[-373.36, 10.86, -147.6]} rotation={[0, 0.96, 0]} scale={[24.6, 3.65, 15.97]} />
        <mesh name="Circle046" geometry={nodes.Circle046.geometry} material={materials['Material.3792']} position={[-346.16, 10.69, -153.43]} rotation={[0, -0.74, Math.PI]} scale={[13.16, 3.65, 7.85]} />
        <mesh name="Circle047" geometry={nodes.Circle047.geometry} material={materials['Material.3793']} position={[-345.27, 10.86, -153.32]} rotation={[0, -0.5, 0]} scale={[24.6, 3.65, 15.97]} />
        <mesh name="Circle048" geometry={nodes.Circle048.geometry} material={materials['Material.3729']} position={[-356.14, 38.37, -173.65]} rotation={[Math.PI / 2, 0, 0]} scale={[1.1, 1.19, 1.13]} />
        <mesh name="Circle049" geometry={nodes.Circle049.geometry} material={materials['Material.3730']} position={[-356.14, 38.37, -173.65]} rotation={[Math.PI / 2, 0, 0]} scale={[1.1, 1.19, 1.13]} />
        <group name="Circle050" position={[-358.42, 38.37, -175.88]} rotation={[Math.PI / 2, 0, 1.57]} scale={[1.1, 1.19, 1.13]}>
          <mesh name="Circle067" geometry={nodes.Circle067.geometry} material={materials['Material.3794']} />
          <mesh name="Circle067_1" geometry={nodes.Circle067_1.geometry} material={materials['Material.3798']} />
          <mesh name="Circle067_2" geometry={nodes.Circle067_2.geometry} material={materials['Material.3799']} />
          <mesh name="Circle067_3" geometry={nodes.Circle067_3.geometry} material={materials['Material.3800']} />
          <mesh name="Circle067_4" geometry={nodes.Circle067_4.geometry} material={materials['Material.3801']} />
          <mesh name="Circle067_5" geometry={nodes.Circle067_5.geometry} material={materials['Material.3802']} />
          <mesh name="Circle067_6" geometry={nodes.Circle067_6.geometry} material={materials['Material.3803']} />
          <mesh name="Circle067_7" geometry={nodes.Circle067_7.geometry} material={materials['Material.3804']} />
          <mesh name="Circle067_8" geometry={nodes.Circle067_8.geometry} material={materials['Material.3805']} />
          <mesh name="Circle067_9" geometry={nodes.Circle067_9.geometry} material={materials['Material.3806']} />
          <mesh name="Circle067_10" geometry={nodes.Circle067_10.geometry} material={materials['Material.3807']} />
        </group>
        <mesh name="Circle051" geometry={nodes.Circle051.geometry} material={materials['Material.3808']} position={[-437.07, 13.58, -152.19]} rotation={[-Math.PI / 2, 1.57, 0]} scale={[0.89, 0.89, 0.68]} />
        <mesh name="Circle052" geometry={nodes.Circle052.geometry} material={materials['Material.3809']} position={[-390.78, 13.58, -158.58]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[0.89, 0.89, 0.68]} />
        <mesh name="Circle053" geometry={nodes.Circle053.geometry} material={materials['Material.3810']} position={[-377.39, 13.58, -178.64]} rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={[0.89, 0.89, 0.68]} />
        <mesh name="Circle054" geometry={nodes.Circle054.geometry} material={materials['Material.3811']} position={[-317.84, 13.58, -178.64]} rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={[0.89, 0.89, 0.68]} />
        <mesh name="Circle055" geometry={nodes.Circle055.geometry} material={materials['Material.3812']} position={[-318.79, 13.58, -186.97]} rotation={[0, 0, -Math.PI / 2]} scale={[0.89, 0.89, 0.68]} />
        <mesh name="Circle056" geometry={nodes.Circle056.geometry} material={materials['Material.3813']} position={[-300.91, 13.61, -151.69]} rotation={[-Math.PI / 2, 1.57, 0]} scale={[0.89, 0.89, 0.68]} />
        <mesh name="Circle057" geometry={nodes.Circle057.geometry} material={materials['Material.6235']} position={[-271.18, 13.58, -158.26]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[0.89, 0.89, 0.68]} />
        <group name="Circular_Wall" position={[-250.54, 15.69, 254.7]} rotation={[0, Math.PI / 2, 0]} scale={[1.16, 0.64, 0.02]}>
          <mesh name="Cube889" geometry={nodes.Cube889.geometry} material={materials['Grayish Concrete.001']} />
          <mesh name="Cube889_1" geometry={nodes.Cube889_1.geometry} material={materials['Blue Accents.001']} />
          <mesh name="Cube889_2" geometry={nodes.Cube889_2.geometry} material={materials['Windows.004']} />
          <mesh name="Cube889_3" geometry={nodes.Cube889_3.geometry} material={materials['Shutters.001']} />
        </group>
        <mesh name="Cube003" geometry={nodes.Cube003.geometry} material={materials['Material.3825']} position={[-399.89, 16.05, -191.07]} scale={[4.39, 4.19, 3.06]} />
        <mesh name="Cube004" geometry={nodes.Cube004.geometry} material={materials['Material.3826']} position={[-435.46, 16.05, -186.83]} rotation={[0, Math.PI / 2, 0]} scale={[3.28, 4.19, 3.06]} />
        <mesh name="Cube006" geometry={nodes.Cube006.geometry} material={materials['Material.3828']} position={[-316.68, 11.38, -184.21]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.38, 0.63, 0.49]} />
        <mesh name="Cube010" geometry={nodes.Cube010.geometry} material={materials['Material.3832']} position={[-311.78, 11.71, -176.11]} rotation={[0, Math.PI / 2, 0]} scale={[0.49, 0.63, 0.58]} />
        <group name="Cube012" position={[-300.63, 15.63, -154.49]} scale={[3.03, 3.93, 3.03]}>
          <mesh name="Cube900" geometry={nodes.Cube900.geometry} material={materials['Material.3834']} />
          <mesh name="Cube900_1" geometry={nodes.Cube900_1.geometry} material={materials['Material.3835']} />
        </group>
        <mesh name="Cube014" geometry={nodes.Cube014.geometry} material={materials['Material.3837']} position={[-308.34, 11.41, -151.33]} scale={[0.49, 0.79, 0.58]} />
        <mesh name="Cube015" geometry={nodes.Cube015.geometry} material={materials['Material.3838']} position={[-290.74, 17.36, -153]} />
        <mesh name="Cube016" geometry={nodes.Cube016.geometry} material={materials['Material.3839']} position={[-292.24, 12.79, -153]} />
        <mesh name="Cube017" geometry={nodes.Cube017.geometry} material={materials['Material.3840']} position={[-275.37, 16.13, -191.07]} scale={[4.39, 4.19, 3.06]} />
        <mesh name="Cube023" geometry={nodes.Cube023.geometry} material={materials['Material.3842']} position={[-270.26, 11.79, -176.11]} rotation={[0, Math.PI / 2, 0]} scale={[0.49, 0.79, 0.58]} />
        <mesh name="Cube024" geometry={nodes.Cube024.geometry} material={materials['Material.3843']} position={[-271.65, 14.03, -165.05]} rotation={[0, Math.PI / 2, 0]} scale={[1, 2.08, 1]} />
        <mesh name="Cube032" geometry={nodes.Cube032.geometry} material={materials['Material.3846']} position={[-74.25, 11.75, 61.28]} scale={[23.33, 2.42, 0.24]} />
        <mesh name="Cube033" geometry={nodes.Cube033.geometry} material={materials['Material.3847']} position={[-100.14, 12.68, 22.8]} rotation={[0, Math.PI / 2, 0]} scale={[7.78, 2.42, 0.48]} />
        <group name="Cube034" position={[-79.46, 11.71, 66.61]} rotation={[-Math.PI, 0, -Math.PI]} scale={[8.43, 1.25, 1]}>
          <mesh name="Cube17872" geometry={nodes.Cube17872.geometry} material={materials['Material.3848']} />
          <mesh name="Cube17872_1" geometry={nodes.Cube17872_1.geometry} material={materials['Material.3849']} />
        </group>
        <group name="Cube035" position={[-74.69, 11.47, 71.15]} rotation={[-Math.PI, 0, -Math.PI]} scale={[4.73, 1, 1]}>
          <mesh name="Cube17873" geometry={nodes.Cube17873.geometry} material={materials['Material.3850']} />
          <mesh name="Cube17873_1" geometry={nodes.Cube17873_1.geometry} material={materials['Material.3851']} />
        </group>
        <group name="Cube038" position={[-72.06, 11.47, 74.43]} rotation={[-Math.PI, 0, -Math.PI]} scale={[4.73, 1, 1]}>
          <mesh name="Cube17874" geometry={nodes.Cube17874.geometry} material={materials['Material.3852']} />
          <mesh name="Cube17874_1" geometry={nodes.Cube17874_1.geometry} material={materials['Material.3853']} />
        </group>
        <group name="Cube039" position={[-69.4, 11.47, 77.17]} rotation={[-Math.PI, 0, -Math.PI]} scale={[4.73, 1, 1]}>
          <mesh name="Cube17875" geometry={nodes.Cube17875.geometry} material={materials['Material.3854']} />
          <mesh name="Cube17875_1" geometry={nodes.Cube17875_1.geometry} material={materials['Material.3855']} />
        </group>
        <mesh name="Cube040" geometry={nodes.Cube040.geometry} material={materials['Material.3856']} position={[-70.55, 11.47, 79.88]} scale={[4.73, 1, 1]} />
        <mesh name="Cube041" geometry={nodes.Cube041.geometry} material={materials['Material.3857']} position={[-73.08, 11.47, 81.89]} scale={[4.73, 1, 1]} />
        <mesh name="Cube042" geometry={nodes.Cube042.geometry} material={materials['Material.3858']} position={[-212.75, 15.76, 303.65]} scale={[20.44, -0.16, 1.79]} />
        <mesh name="Cube043" geometry={nodes.Cube043.geometry} material={materials['Material.3859']} position={[-232.21, 10.76, 325.35]} scale={[1.52, 1.32, 0.18]} />
        <mesh name="Cube045" geometry={nodes.Cube045.geometry} material={materials['Material.3860']} position={[-192.12, 10.76, 309.72]} rotation={[0, Math.PI / 2, 0]} scale={[1.79, 1.32, 0.15]} />
        <mesh name="Cube048" geometry={nodes.Cube048.geometry} material={materials['Material.3861']} position={[-294.7, 11.79, -194.74]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.49, 0.79, 0.58]} />
        <mesh name="Cube049" geometry={nodes.Cube049.geometry} material={materials['Material.3862']} position={[-40.94, 11.76, 35.01]} scale={[4.87, 1.3, -0.22]} />
        <group name="Cube053" position={[-49.75, 14.91, 38.14]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.25, 0.23, 0.2]}>
          <mesh name="Cube17886" geometry={nodes.Cube17886.geometry} material={materials['Material.3923']} />
          <mesh name="Cube17886_1" geometry={nodes.Cube17886_1.geometry} material={materials['Material.3925']} />
          <mesh name="Cube17886_2" geometry={nodes.Cube17886_2.geometry} material={materials['Material.3926']} />
          <mesh name="Cube17886_3" geometry={nodes.Cube17886_3.geometry} material={materials['Material.3927']} />
          <mesh name="Cube17886_4" geometry={nodes.Cube17886_4.geometry} material={materials['Material.3928']} />
          <mesh name="Cube17886_5" geometry={nodes.Cube17886_5.geometry} material={materials['Material.3929']} />
          <mesh name="Cube17886_6" geometry={nodes.Cube17886_6.geometry} material={materials['Material.3930']} />
          <mesh name="Cube17886_7" geometry={nodes.Cube17886_7.geometry} material={materials['Material.3931']} />
        </group>
        <mesh name="Cube054" geometry={nodes.Cube054.geometry} material={materials['Material.3932']} position={[-40.94, 13.62, 61.1]} rotation={[-Math.PI, 0, 0]} scale={[4.87, 1.3, -0.22]} />
        <mesh name="Cube055" geometry={nodes.Cube055.geometry} material={materials['Material.3933']} position={[-15.42, 10.68, 47.13]} scale={[12.55, 0.56, 0.56]} />
        <mesh name="Cube056" geometry={nodes.Cube056.geometry} material={materials['Material.3934']} position={[-15.42, 10.62, 36.65]} scale={[13.41, 0.4, 0.4]} />
        <mesh name="Cube057" geometry={nodes.Cube057.geometry} material={materials['Material.3935']} position={[-15.42, 10.62, 57.33]} scale={[13.41, 0.4, 0.4]} />
        <mesh name="Cube058" geometry={nodes.Cube058.geometry} material={materials['Material.3936']} position={[-15.42, 10.62, 58.9]} scale={[13.41, 0.4, 0.4]} />
        <mesh name="Cube059" geometry={nodes.Cube059.geometry} material={materials['Material.3937']} position={[-15.42, 10.62, 28.37]} scale={[13.41, 0.4, 0.4]} />
        <mesh name="Cube060" geometry={nodes.Cube060.geometry} material={materials['Material.3938']} position={[-24.52, 11.71, 65.71]} rotation={[Math.PI, 0, Math.PI]} scale={[4.37, 1, -0.2]} />
        <mesh name="Cube061" geometry={nodes.Cube061.geometry} material={materials['Material.3939']} position={[-25.42, 12.94, 27.8]} rotation={[0, 0, Math.PI]} scale={[4.37, 1, -0.2]} />
        <mesh name="Cube063" geometry={nodes.Cube063.geometry} material={materials['Material.3960']} position={[-49.89, 11.96, 38.22]} scale={[0.04, 2.04, 0.07]} />
        <mesh name="Cube064" geometry={nodes.Cube064.geometry} material={materials['Material.3980']} position={[-49.89, 11.96, 60.08]} scale={[0.04, 2.04, 0.07]} />
        <mesh name="Cube065" geometry={nodes.Cube065.geometry} material={materials['Material.4000']} position={[-45.65, 9.24, 59.36]} scale={[4.37, 1.3, 1.82]} />
        <mesh name="Cube066" geometry={nodes.Cube066.geometry} material={materials['Material.4001']} position={[-41.33, 10.71, 59.29]} rotation={[0, Math.PI / 2, 0]} scale={[1.88, -0.42, 0.07]} />
        <mesh name="Cube067" geometry={nodes.Cube067.geometry} material={materials['Material.4002']} position={[-45.82, 9.19, 35.08]} scale={[4.37, 1.3, 1.82]} />
        <mesh name="Cube068" geometry={nodes.Cube068.geometry} material={materials['Material.4003']} position={[-41.33, 10.71, 35]} rotation={[-Math.PI, Math.PI / 2, 0]} scale={[1.88, -0.42, 0.07]} />
        <mesh name="Cube069" geometry={nodes.Cube069.geometry} material={materials['Material.209']} position={[-49.67, 16.89, 47.18]} scale={[-0.04, 0.67, 3.87]} />
        <mesh name="Cube070" geometry={nodes.Cube070.geometry} material={materials['Material.4004']} position={[-49.72, 16.64, 44.39]} scale={[0.12, 0.14, 0.12]} />
        <mesh name="Cube071" geometry={nodes.Cube071.geometry} material={materials['Material.4005']} position={[-49.72, 16.64, 44.16]} scale={[0.12, 0.14, 0.12]} />
        <mesh name="Cube072" geometry={nodes.Cube072.geometry} material={materials['Material.4006']} position={[-49.72, 16.64, 43.92]} scale={[0.12, 0.14, 0.12]} />
        <group name="Cube073" position={[-49.11, 16.54, 42.88]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.35, 0.32, 0.28]}>
          <mesh name="Cube17906" geometry={nodes.Cube17906.geometry} material={materials['Material.4007']} />
          <mesh name="Cube17906_1" geometry={nodes.Cube17906_1.geometry} material={materials['Material.4009']} />
          <mesh name="Cube17906_2" geometry={nodes.Cube17906_2.geometry} material={materials['Material.4010']} />
          <mesh name="Cube17906_3" geometry={nodes.Cube17906_3.geometry} material={materials['Material.4011']} />
          <mesh name="Cube17906_4" geometry={nodes.Cube17906_4.geometry} material={materials['Material.4012']} />
          <mesh name="Cube17906_5" geometry={nodes.Cube17906_5.geometry} material={materials['Material.4013']} />
          <mesh name="Cube17906_6" geometry={nodes.Cube17906_6.geometry} material={materials['Material.4014']} />
          <mesh name="Cube17906_7" geometry={nodes.Cube17906_7.geometry} material={materials['Material.4015']} />
        </group>
        <group name="Cube074" position={[-49.04, 16.54, 51.61]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.35, 0.32, 0.28]}>
          <mesh name="Cube17907" geometry={nodes.Cube17907.geometry} material={materials['Material.4016']} />
          <mesh name="Cube17907_1" geometry={nodes.Cube17907_1.geometry} material={materials['Material.4018']} />
          <mesh name="Cube17907_2" geometry={nodes.Cube17907_2.geometry} material={materials['Material.4019']} />
          <mesh name="Cube17907_3" geometry={nodes.Cube17907_3.geometry} material={materials['Material.4020']} />
          <mesh name="Cube17907_4" geometry={nodes.Cube17907_4.geometry} material={materials['Material.4021']} />
          <mesh name="Cube17907_5" geometry={nodes.Cube17907_5.geometry} material={materials['Material.4023']} />
          <mesh name="Cube17907_6" geometry={nodes.Cube17907_6.geometry} material={materials['Material.4024']} />
        </group>
        <group name="Cube075" position={[-49.51, 14.91, 56.44]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.25, 0.23, 0.2]}>
          <mesh name="Cube17908" geometry={nodes.Cube17908.geometry} material={materials['Material.4025']} />
          <mesh name="Cube17908_1" geometry={nodes.Cube17908_1.geometry} material={materials['Material.4027']} />
          <mesh name="Cube17908_2" geometry={nodes.Cube17908_2.geometry} material={materials['Material.4028']} />
          <mesh name="Cube17908_3" geometry={nodes.Cube17908_3.geometry} material={materials['Material.4029']} />
          <mesh name="Cube17908_4" geometry={nodes.Cube17908_4.geometry} material={materials['Material.4030']} />
          <mesh name="Cube17908_5" geometry={nodes.Cube17908_5.geometry} material={materials['Material.4031']} />
          <mesh name="Cube17908_6" geometry={nodes.Cube17908_6.geometry} material={materials['Material.4032']} />
          <mesh name="Cube17908_7" geometry={nodes.Cube17908_7.geometry} material={materials['Material.4033']} />
        </group>
        <group name="Cube076" position={[-50.75, 12.18, 59.97]} scale={[2.23, 1.92, 1.24]}>
          <mesh name="Cube17909" geometry={nodes.Cube17909.geometry} material={materials['Material.4034']} />
          <mesh name="Cube17909_1" geometry={nodes.Cube17909_1.geometry} material={materials['Material.4035']} />
          <mesh name="Cube17909_2" geometry={nodes.Cube17909_2.geometry} material={materials['Material.4036']} />
        </group>
        <group name="Cube077" position={[-218.48, 15.23, -186.59]} rotation={[0, -1.57, 0]} scale={[15.27, 3.99, 3.99]}>
          <mesh name="Cube17910" geometry={nodes.Cube17910.geometry} material={materials['Material.4037']} />
          <mesh name="Cube17910_1" geometry={nodes.Cube17910_1.geometry} material={materials['Material.4038']} />
        </group>
        <mesh name="Cube078" geometry={nodes.Cube078.geometry} material={materials['Material.4039']} position={[-222.8, 19.26, -179.76]} rotation={[0, -1.57, 0]} scale={[21.24, 0.13, 0.13]} />
        <mesh name="Cube079" geometry={nodes.Cube079.geometry} material={materials['Material.4040']} position={[-222.8, 12.55, -194.03]} rotation={[0, -1.57, 0]} scale={[8.91, 0.13, 0.13]} />
        <group name="Cube080" position={[-218.48, 15.23, -186.59]} rotation={[0, -1.57, 0]} scale={[15.27, 3.99, 3.99]}>
          <mesh name="Cube17913" geometry={nodes.Cube17913.geometry} material={materials['Material.4037']} />
          <mesh name="Cube17913_1" geometry={nodes.Cube17913_1.geometry} material={materials['Material.4038']} />
        </group>
        <mesh name="Cube081" geometry={nodes.Cube081.geometry} material={materials['Material.4037']} position={[-218.48, 15.23, -175.62]} rotation={[0, -1.57, 0]} scale={[15.27, 3.99, 3.99]} />
        <mesh name="Cube082" geometry={nodes.Cube082.geometry} material={materials['Material.4037']} position={[-218.48, 15.23, -186.59]} rotation={[0, -1.57, 0]} scale={[15.27, 3.99, 3.99]} />
        <mesh name="Cube083" geometry={nodes.Cube083.geometry} material={materials['Material.4037']} position={[-218.48, 15.23, -186.59]} rotation={[0, -1.57, 0]} scale={[15.27, 3.99, 3.99]} />
        <mesh name="Cube084" geometry={nodes.Cube084.geometry} material={materials['Material.4037']} position={[-218.48, 15.23, -186.59]} rotation={[0, -1.57, 0]} scale={[15.27, 3.99, 3.99]} />
        <mesh name="Cube085" geometry={nodes.Cube085.geometry} material={materials['Material.4041']} position={[-222.8, 12.55, -171.44]} rotation={[0, -1.57, 0]} scale={[8.05, 0.13, 0.13]} />
        <mesh name="Cube086" geometry={nodes.Cube086.geometry} material={materials['Material.4042']} position={[-221.4, 10.48, -159.76]} rotation={[-Math.PI, 0, -Math.PI]} scale={[2.6, 0.23, 2.09]} />
        <mesh name="Cube087" geometry={nodes.Cube087.geometry} material={materials['Material.4043']} position={[-221.4, 10.48, -181.63]} rotation={[-Math.PI, 0, -Math.PI]} scale={[2.6, 0.23, 2.09]} />
        <group name="Cube088" position={[-192.81, 12.82, -203.12]} scale={[3.3, 3.3, 6.2]}>
          <mesh name="Cube17921" geometry={nodes.Cube17921.geometry} material={materials['Material.4044']} />
          <mesh name="Cube17921_1" geometry={nodes.Cube17921_1.geometry} material={materials['Material.4045']} />
        </group>
        <group name="Cube090" position={[-283.37, 18.22, -250.46]} rotation={[0, Math.PI / 2, 0]} scale={7.75}>
          <mesh name="Cube17923" geometry={nodes.Cube17923.geometry} material={materials['whhite.001']} />
          <mesh name="Cube17923_1" geometry={nodes.Cube17923_1.geometry} material={materials['Material.4046']} />
        </group>
        <mesh name="Cube091" geometry={nodes.Cube091.geometry} material={materials['Material.4047']} position={[-283.27, 18.22, -250.46]} rotation={[0, Math.PI / 2, 0]} scale={7.75} />
        <mesh name="Cube092" geometry={nodes.Cube092.geometry} material={materials['Material.4048']} position={[-283.37, 18.22, -250.46]} rotation={[0, Math.PI / 2, 0]} scale={7.75} />
        <mesh name="Cube093" geometry={nodes.Cube093.geometry} material={materials['Material.4049']} position={[-281.12, 13.47, -262.14]} rotation={[0, Math.PI / 2, 0]} scale={0.15} />
        <mesh name="Cube094" geometry={nodes.Cube094.geometry} material={materials['whhite.001']} position={[-283.37, 18.22, -250.46]} rotation={[0, Math.PI / 2, 0]} scale={7.75} />
        <group name="Cube095" position={[-283.37, 18.22, -250.46]} rotation={[0, Math.PI / 2, 0]} scale={7.75}>
          <mesh name="Cube17928" geometry={nodes.Cube17928.geometry} material={materials['whhite.001']} />
          <mesh name="Cube17928_1" geometry={nodes.Cube17928_1.geometry} material={materials['yellow.001']} />
        </group>
        <mesh name="Cube096" geometry={nodes.Cube096.geometry} material={materials['Material.551']} position={[-357.69, 19.48, 216.62]} scale={[2.9, 2.14, 2.44]} />
        <mesh name="Cube097" geometry={nodes.Cube097.geometry} material={materials['Material.4050']} position={[-322.67, 11.58, 196.05]} rotation={[-Math.PI, 0, 0]} scale={[0.57, 1.12, 1]} />
        <mesh name="Cube098" geometry={nodes.Cube098.geometry} material={materials['Material.6236']} position={[-357.57, 19.48, 222.68]} scale={[4.06, 3, 3.8]} />
        <mesh name="Cube099" geometry={nodes.Cube099.geometry} material={materials['Material.6237']} position={[-357.57, 19.48, 230.48]} scale={[5.29, 3.9, 4.4]} />
        <mesh name="Cube104" geometry={nodes.Cube104.geometry} material={materials['Material.4051']} position={[-222.97, 11.59, 66.45]} scale={[7.96, 1.78, 0.24]} />
        <group name="Cube105" position={[-116.68, 12.49, 24.58]} scale={[9.78, 2.13, 9.78]}>
          <mesh name="Cube17938" geometry={nodes.Cube17938.geometry} material={materials['Material.4052']} />
          <mesh name="Cube17938_1" geometry={nodes.Cube17938_1.geometry} material={materials['Material.4053']} />
        </group>
        <mesh name="Cube106" geometry={nodes.Cube106.geometry} material={materials['Material.4054']} position={[-118.38, 12.26, 36.56]} scale={[0.03, 2.02, 0.03]} />
        <group name="Cube107" position={[-149.49, 12.49, 24.75]} rotation={[0, -Math.PI / 2, 0]} scale={[9.78, 2.13, 9.78]}>
          <mesh name="Cube17940" geometry={nodes.Cube17940.geometry} material={materials['Material.4055']} />
          <mesh name="Cube17940_1" geometry={nodes.Cube17940_1.geometry} material={materials['Material.4056']} />
        </group>
        <mesh name="Cube001" geometry={nodes.Cube001.geometry} material={materials['Material.4057']} position={[-161.47, 12.26, 23.05]} rotation={[0, -Math.PI / 2, 0]} scale={[0.03, 2.02, 0.03]} />
        <mesh name="Cube002" geometry={nodes.Cube002.geometry} material={materials['Material.4058']} position={[-62.61, 12.71, 33.3]} rotation={[0, Math.PI / 2, 0]} scale={[2.51, 2.51, 0.16]} />
        <mesh name="Cube110" geometry={nodes.Cube110.geometry} material={materials['Material.4059']} position={[-65.13, 11.22, 36.61]} rotation={[-Math.PI, 0, -Math.PI]} scale={[2.51, 0.63, 0.16]} />
        <mesh name="Cube111" geometry={nodes.Cube111.geometry} material={materials['Material.4060']} position={[-57.26, 13.92, 30.45]} rotation={[-Math.PI, 0, -Math.PI / 2]} scale={[2.51, 0.63, 0.16]} />
        <mesh name="Cube112" geometry={nodes.Cube112.geometry} material={materials['Material.4061']} position={[-64.96, 16.55, 30.45]} rotation={[-Math.PI, 0, -Math.PI]} scale={[2.51, 0.63, 0.16]} />
        <mesh name="Cube113" geometry={nodes.Cube113.geometry} material={materials['Material.4062']} position={[-61.28, 17.81, 30.45]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.17, 0.46, 0.16]} />
        <mesh name="Cube114" geometry={nodes.Cube114.geometry} material={materials['Material.4063']} position={[-67.31, 12.98, 30.08]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.16, 2.51, 2.51]} />
        <mesh name="Cube115" geometry={nodes.Cube115.geometry} material={materials['Material.4064']} position={[-54.48, 13.9, 27.8]} rotation={[0, Math.PI / 2, 0]} scale={[2.51, 2.51, 0.16]} />
        <mesh name="Cube019" geometry={nodes.Cube019.geometry} material={nodes.Cube019.material} position={[-60.91, 13.85, -0.05]} rotation={[0, Math.PI / 2, 0]} scale={[0.16, 2.51, 2.51]} />
        <mesh name="Cube118" geometry={nodes.Cube118.geometry} material={materials['Material.4065']} position={[-89.29, 12.68, 14.9]} scale={[11.06, 2.42, 0.37]} />
        <mesh name="Cube119" geometry={nodes.Cube119.geometry} material={materials['Material.4066']} position={[-78.9, 12.68, 22.8]} rotation={[0, Math.PI / 2, 0]} scale={[7.78, 2.42, 0.48]} />
        <group name="Cube120" position={[-229.08, 10.76, 311.36]} scale={[1.52, 1.32, 0.18]}>
          <mesh name="Cube17952" geometry={nodes.Cube17952.geometry} material={materials['Material.4067']} />
          <mesh name="Cube17952_1" geometry={nodes.Cube17952_1.geometry} material={materials['Material.4068']} />
        </group>
        <mesh name="Cube121" geometry={nodes.Cube121.geometry} material={materials['Material.4069']} position={[-179.23, 10.69, 332.09]} rotation={[0, Math.PI / 2, 0]} scale={[1.79, 1.32, 0.15]} />
        <mesh name="Cube122" geometry={nodes.Cube122.geometry} material={materials['Material.4070']} position={[-220.49, 10.76, 325.02]} scale={[1.52, 1.32, 0.18]} />
        <mesh name="Cube123" geometry={nodes.Cube123.geometry} material={materials['Material.4071']} position={[-233.11, 10.76, 330.7]} rotation={[0, Math.PI / 2, 0]} scale={[1.79, 1.32, 0.15]} />
        <mesh name="Cube124" geometry={nodes.Cube124.geometry} material={materials['Material.4072']} position={[-212.75, 15.47, 332.37]} scale={[20.44, -0.19, 1.79]} />
        <mesh name="Cube125" geometry={nodes.Cube125.geometry} material={materials['Material.3858']} position={[-212.75, 15.47, 303.65]} scale={[20.44, -0.16, 1.79]} />
        <mesh name="Cube126" geometry={nodes.Cube126.geometry} material={materials['Material.3858']} position={[-212.75, 15.47, 303.65]} scale={[20.44, -0.21, 1.79]} />
        <mesh name="Cube127" geometry={nodes.Cube127.geometry} material={materials['Material.4073']} position={[-241.88, 12.95, 221.37]} rotation={[-Math.PI, 0, -Math.PI]} scale={[9.68, 2.46, 2.26]} />
        <mesh name="Cube128" geometry={nodes.Cube128.geometry} material={materials['Material.3858']} position={[-212.75, 15.47, 303.65]} scale={[20.44, -0.16, 1.79]} />
        <mesh name="Cube130" geometry={nodes.Cube130.geometry} material={materials['Material.4075']} position={[-356.2, 33.42, -175.86]} scale={[1, 0.53, 0.94]} />
        <group name="Cube132" position={[-348.15, 18.25, -171.96]} scale={[1.97, 1.58, 3.07]}>
          <mesh name="Cube17964" geometry={nodes.Cube17964.geometry} material={materials['Material.4077']} />
          <mesh name="Cube17964_1" geometry={nodes.Cube17964_1.geometry} material={materials['Material.6303']} />
        </group>
        <mesh name="Cube133" geometry={nodes.Cube133.geometry} material={materials['Material.4077']} position={[-366.73, 18.25, -171.96]} scale={[1.97, 1.58, 3.07]} />
        <mesh name="Cube135" geometry={nodes.Cube135.geometry} material={materials['Material.4078']} position={[-356.17, 38.22, -175.74]} scale={[1.87, 1.65, 1.77]} />
        <mesh name="Cube136" geometry={nodes.Cube136.geometry} material={materials['Material.4079']} position={[-354.19, 38.37, -175.77]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.15, 1.29, 1.43]} />
        <mesh name="Cube137" geometry={nodes.Cube137.geometry} material={materials['Material.4080']} position={[-356.21, 38.37, -177.63]} rotation={[0, -1.57, 0]} scale={[0.15, 1.29, 1.47]} />
        <mesh name="Cube138" geometry={nodes.Cube138.geometry} material={materials['Material.4081']} position={[-358.12, 38.37, -175.73]} scale={[0.15, 1.29, 1.43]} />
        <mesh name="Cube139" geometry={nodes.Cube139.geometry} material={materials['Material.4082']} position={[-356.14, 38.37, -173.87]} rotation={[0, Math.PI / 2, 0]} scale={[0.15, 1.29, 1.47]} />
        <mesh name="Cube143" geometry={nodes.Cube143.geometry} material={materials['Material.4086']} position={[-359.99, 28.49, -176.07]} rotation={[-Math.PI / 2, -1.5, -Math.PI / 2]} scale={[0.15, 6.53, 0.21]} />
        <mesh name="Cube144" geometry={nodes.Cube144.geometry} material={materials['Material.4087']} position={[-351.75, 14.96, -167.19]} scale={[0.81, 2.4, 0.81]} />
        <mesh name="Cube145" geometry={nodes.Cube145.geometry} material={materials['Material.4088']} position={[-360.63, 14.96, -167.19]} scale={[0.81, 2.4, 0.81]} />
        <mesh name="Cube146" geometry={nodes.Cube146.geometry} material={materials['Material.4089']} position={[-359.99, 28.49, -175.39]} rotation={[-Math.PI / 2, -1.5, -Math.PI / 2]} scale={[0.13, 6.53, 0.21]} />
        <mesh name="Cube147" geometry={nodes.Cube147.geometry} material={materials['Material.4089']} position={[-355.61, 28.49, -172.37]} rotation={[-0.07, 0, 0]} scale={[0.13, 6.53, 0.2]} />
        <mesh name="Cube148" geometry={nodes.Cube148.geometry} material={materials['Material.4090']} position={[-358.95, 14.83, -161.81]} scale={[0.59, 1.72, 0.59]} />
        <mesh name="Cube149" geometry={nodes.Cube149.geometry} material={materials['Material.4092']} position={[-354.96, 28.5, -172.41]} rotation={[-0.07, 0, 0]} scale={[0.13, 6.31, 0.16]} />
        <mesh name="Cube150" geometry={nodes.Cube150.geometry} material={materials['Material.4086']} position={[-356.33, 28.49, -172.37]} rotation={[-0.07, 0, 0]} scale={[0.15, 6.53, 0.2]} />
        <mesh name="Cube151" geometry={nodes.Cube151.geometry} material={materials['Material.4092']} position={[-357.02, 28.5, -172.41]} rotation={[-0.07, 0, 0]} scale={[0.13, 6.31, 0.16]} />
        <mesh name="Cube154" geometry={nodes.Cube154.geometry} material={materials['Material.4092']} position={[-359.94, 28.5, -174.78]} rotation={[-Math.PI / 2, -1.5, -Math.PI / 2]} scale={[0.12, 6.31, 0.16]} />
        <mesh name="Cube156" geometry={nodes.Cube156.geometry} material={materials['Material.4092']} position={[-359.94, 28.5, -176.73]} rotation={[-Math.PI / 2, -1.5, -Math.PI / 2]} scale={[0.12, 6.31, 0.16]} />
        <mesh name="Cube157" geometry={nodes.Cube157.geometry} material={materials['Material.4091']} position={[-358.95, 14.83, -161.81]} scale={[0.59, 1.72, 0.59]} />
        <mesh name="Cube158" geometry={nodes.Cube158.geometry} material={materials['Material.4090']} position={[-358.95, 14.83, -161.81]} scale={[0.59, 1.72, 0.59]} />
        <mesh name="Cube159" geometry={nodes.Cube159.geometry} material={materials['Material.4096']} position={[-355.97, 23.22, -171.91]} scale={[2.23, 0.14, 0.44]} />
        <mesh name="Cube161" geometry={nodes.Cube161.geometry} material={materials['Material.4096']} position={[-360.41, 23.22, -175.74]} rotation={[0, -Math.PI / 2, 0]} scale={[2.11, 0.14, 0.47]} />
        <mesh name="Cube164" geometry={nodes.Cube164.geometry} material={materials['Material.4100']} position={[-356.13, 28.97, -175.97]} scale={[3.23, 5.68, 3.06]} />
        <mesh name="Cube165" geometry={nodes.Cube165.geometry} material={materials['Material.4096']} position={[-351.16, 21.9, -174.72]} rotation={[0, Math.PI / 2, 0]} scale={[4.43, 0.12, 1.13]} />
        <mesh name="Cube166" geometry={nodes.Cube166.geometry} material={materials['Material.4101']} position={[-351.11, 21.56, -175.06]} scale={[1.17, 0.25, 4.77]} />
        <mesh name="Cube167" geometry={nodes.Cube167.geometry} material={materials['Material.4096']} position={[-361.21, 21.9, -174.72]} rotation={[0, Math.PI / 2, 0]} scale={[4.43, 0.12, 1.13]} />
        <mesh name="Cube168" geometry={nodes.Cube168.geometry} material={materials['Material.4101']} position={[-361.21, 21.56, -175.06]} scale={[1.17, 0.25, 4.77]} />
        <mesh name="Cube169" geometry={nodes.Cube169.geometry} material={materials['Material.4096']} position={[-346.96, 20.46, -174.18]} rotation={[0, Math.PI / 2, 0]} scale={[5.71, 0.12, 3.49]} />
        <mesh name="Cube170" geometry={nodes.Cube170.geometry} material={materials['Material.4101']} position={[-346.82, 20.07, -174.15]} scale={[3.4, 0.34, 5.58]} />
        <mesh name="Cube171" geometry={nodes.Cube171.geometry} material={materials['Material.4096']} position={[-365.82, 20.46, -174.18]} rotation={[0, Math.PI / 2, 0]} scale={[5.71, 0.12, 3.49]} />
        <mesh name="Cube172" geometry={nodes.Cube172.geometry} material={materials['Material.4101']} position={[-365.57, 20.07, -174.15]} scale={[3.4, 0.34, 5.58]} />
        <mesh name="Cube173" geometry={nodes.Cube173.geometry} material={materials['Material.4096']} position={[-339.84, 16.51, -176.52]} rotation={[0, Math.PI / 2, 0]} scale={[4.24, 0.09, 2.6]} />
        <mesh name="Cube183" geometry={nodes.Cube183.geometry} material={materials['Material.4111']} position={[-356.09, 17.22, -168.03]} scale={[6.1, 0.16, 1.6]} />
        <mesh name="Cube187" geometry={nodes.Cube187.geometry} material={materials['Material.4115']} position={[-352.54, 28.49, -175.71]} rotation={[-Math.PI / 2, 1.5, Math.PI / 2]} scale={[0.15, 6.53, 0.2]} />
        <mesh name="Cube188" geometry={nodes.Cube188.geometry} material={materials['Material.4116']} position={[-352.54, 28.49, -176.43]} rotation={[-Math.PI / 2, 1.5, Math.PI / 2]} scale={[0.13, 6.53, 0.2]} />
        <mesh name="Cube189" geometry={nodes.Cube189.geometry} material={materials['Material.4117']} position={[-352.59, 28.5, -177.08]} rotation={[-Math.PI / 2, 1.5, Math.PI / 2]} scale={[0.13, 6.31, 0.16]} />
        <mesh name="Cube190" geometry={nodes.Cube190.geometry} material={materials['Material.4117']} position={[-352.59, 28.5, -175.02]} rotation={[-Math.PI / 2, 1.5, Math.PI / 2]} scale={[0.13, 6.31, 0.16]} />
        <mesh name="Cube191" geometry={nodes.Cube191.geometry} material={materials['Material.4118']} position={[-352.13, 23.22, -176.03]} rotation={[0, Math.PI / 2, 0]} scale={[2.23, 0.14, 0.44]} />
        <mesh name="Cube193" geometry={nodes.Cube193.geometry} material={materials['Material.4120']} position={[-357, 16.65, -164.75]} rotation={[0, Math.PI / 2, 0]} scale={[3.62, 0.12, 3.03]} />
        <mesh name="Cube203" geometry={nodes.Cube203.geometry} material={materials['Material.4129']} position={[-291.47, 10.36, -122.77]} scale={[14.42, 0.48, 16.06]} />
        <mesh name="Cube211" geometry={nodes.Cube211.geometry} material={materials['Material.4135']} position={[-241.91, 14.21, 218.91]} rotation={[-Math.PI, 0, -Math.PI]} scale={[10.16, 0.34, 0.31]} />
        <mesh name="Cube313" geometry={nodes.Cube313.geometry} material={materials['Material.4208']} position={[-356.21, 11.58, 146.55]} rotation={[0, Math.PI / 2, 0]} scale={[0.57, 1.12, 2.03]} />
        <mesh name="Cube314" geometry={nodes.Cube314.geometry} material={materials['Material.4209']} position={[-334.38, 11.58, 184.03]} rotation={[0, Math.PI / 2, 0]} scale={[0.57, 1.12, 1]} />
        <mesh name="Cube318" geometry={nodes.Cube318.geometry} material={materials['Material.4210']} position={[-349.57, 14.26, -180.67]} scale={3.97} />
        <mesh name="Cube319" geometry={nodes.Cube319.geometry} material={materials['Material.4211']} position={[-251.86, 12.02, 219.84]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.68, 0.84, 0.77]} />
        <mesh name="Cube320" geometry={nodes.Cube320.geometry} material={materials['Material.4212']} position={[-232.49, 12.02, 219.84]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.68, 0.84, 0.77]} />
        <mesh name="Cube321" geometry={nodes.Cube321.geometry} material={materials['Material.4213']} position={[-228.11, 14.43, 218.91]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[12.5, 0.28, 0.31]} />
        <mesh name="Cube322" geometry={nodes.Cube322.geometry} material={materials['Material.4214']} position={[-231.18, 12.02, 219.84]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.68, 0.84, 0.77]} />
        <mesh name="Cube323" geometry={nodes.Cube323.geometry} material={materials['Material.4215']} position={[-231.18, 15.75, 219.84]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.68, 1.07, 0.77]} />
        <mesh name="Cube324" geometry={nodes.Cube324.geometry} material={materials['Material.4216']} position={[-255.54, 13.98, 234.53]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[12.72, 0.21, 0.24]} />
        <mesh name="Cube325" geometry={nodes.Cube325.geometry} material={materials['Material.4217']} position={[-241.44, 13.65, 219.1]} rotation={[-Math.PI, 0, -Math.PI]} scale={[2.08, 0.32, 0.29]} />
        <mesh name="Cube326" geometry={nodes.Cube326.geometry} material={materials['Material.4216']} position={[-255.54, 13.98, 234.53]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[12.72, 0.21, 0.24]} />
        <mesh name="Cube327" geometry={nodes.Cube327.geometry} material={materials['Material.4216']} position={[-255.54, 13.98, 234.08]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[12.72, 0.21, 0.24]} />
        <mesh name="Cube328" geometry={nodes.Cube328.geometry} material={materials['Material.4218']} position={[-236.68, 12.02, 219.84]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.68, 0.84, 0.77]} />
        <mesh name="Cube329" geometry={nodes.Cube329.geometry} material={materials['Material.4219']} position={[-234.86, 12.02, 219.84]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.68, 0.84, 0.77]} />
        <mesh name="Cube330" geometry={nodes.Cube330.geometry} material={materials['Material.4220']} position={[-243.76, 15.83, 219.85]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.68, 0.89, 0.77]} />
        <mesh name="Cube331" geometry={nodes.Cube331.geometry} material={materials['Material.4221']} position={[-238.38, 12.02, 219.84]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.68, 0.84, 0.77]} />
        <mesh name="Cube332" geometry={nodes.Cube332.geometry} material={materials['Material.4222']} position={[-245.22, 12.04, 219.84]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.68, 0.72, 0.77]} />
        <mesh name="Cube333" geometry={nodes.Cube333.geometry} material={materials['Material.4223']} position={[-249.2, 12.03, 219.84]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.68, 0.72, 0.77]} />
        <mesh name="Cube334" geometry={nodes.Cube334.geometry} material={materials['Material.4224']} position={[-251.47, 15.87, 219.84]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.68, 1, 0.77]} />
        <mesh name="Cube335" geometry={nodes.Cube335.geometry} material={materials['Material.4225']} position={[-254.71, 15.87, 219.84]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.77, 1.06, 0.87]} />
        <mesh name="Cube336" geometry={nodes.Cube336.geometry} material={materials['Material.4226']} position={[-253.12, 14.58, 232.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[2.15, 2.64, 3.41]} />
        <mesh name="Cube337" geometry={nodes.Cube337.geometry} material={materials['Material.4227']} position={[-230.26, 12.59, 262.32]} scale={0.04} />
        <mesh name="Cube338" geometry={nodes.Cube338.geometry} material={materials['Material.4228']} position={[-230.26, 12.8, 262.49]} rotation={[Math.PI / 2, 0, 0]} scale={[0.04, 0.07, 0.04]} />
        <mesh name="Cube339" geometry={nodes.Cube339.geometry} material={materials['Material.4229']} position={[-254.27, 10.93, 231.67]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.95, 0.13, 4.22]} />
        <mesh name="Cube340" geometry={nodes.Cube340.geometry} material={materials['Material.4230']} position={[-255.41, 13.98, 221.33]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[12.72, 0.21, 0.24]} />
        <mesh name="Cube341" geometry={nodes.Cube341.geometry} material={materials['Material.4231']} position={[-255.41, 13.98, 223.86]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[12.72, 0.21, 0.24]} />
        <mesh name="Cube342" geometry={nodes.Cube342.geometry} material={materials['Material.4232']} position={[-255.41, 13.98, 222.64]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[12.72, 0.21, 0.24]} />
        <mesh name="Cube343" geometry={nodes.Cube343.geometry} material={materials['Material.4233']} position={[-255.37, 17.98, 218.91]} rotation={[0, -Math.PI / 2, 0]} scale={[11.48, 0.34, 0.28]} />
        <mesh name="Cube344" geometry={nodes.Cube344.geometry} material={materials['Material.4234']} position={[-255.71, 15.4, 221.08]} rotation={[0, -Math.PI / 2, 0]} scale={[11.48, 0.41, 0.28]} />
        <mesh name="Cube345" geometry={nodes.Cube345.geometry} material={materials['Material.4235']} position={[-242.71, 13.06, 219.1]} rotation={[Math.PI / 2, -1.57, 0]} scale={[2.56, 0.29, 0.26]} />
        <mesh name="Cube346" geometry={nodes.Cube346.geometry} material={materials['Material.4216']} position={[-252.67, 13.57, 234.99]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[11.23, 0.14, 0.24]} />
        <mesh name="Cube347" geometry={nodes.Cube347.geometry} material={materials['Material.4236']} position={[-255.55, 13.98, 231.62]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[12.72, 0.21, 0.64]} />
        <mesh name="Cube349" geometry={nodes.Cube349.geometry} material={materials['Material.4237']} position={[-255.55, 13.98, 233.48]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[12.72, 0.21, 0.69]} />
        <mesh name="Cube350" geometry={nodes.Cube350.geometry} material={materials['Material.4238']} position={[-241.6, 10.93, 219.36]} rotation={[0, Math.PI / 2, 0]} scale={[2.2, 0.13, 3.74]} />
        <mesh name="Cube351" geometry={nodes.Cube351.geometry} material={materials['Material.4239']} position={[-241.33, 10.9, 216.83]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.68, 0.37, 0.77]} />
        <mesh name="Cube355" geometry={nodes.Cube355.geometry} material={materials['Material.4240']} position={[-381.34, 11.58, 184.03]} rotation={[-Math.PI, -Math.PI / 2, 0]} scale={[0.57, 1.12, 1]} />
        <mesh name="Cube356" geometry={nodes.Cube356.geometry} material={materials['Material.4241']} position={[-393.04, 11.58, 196.05]} rotation={[-Math.PI, 0, 0]} scale={[0.57, 1.12, 1]} />
        <mesh name="Cube416" geometry={nodes.Cube416.geometry} material={materials['Material.4296']} position={[-290.01, 11.68, -106.81]} rotation={[0, Math.PI / 2, 0]} scale={[0.54, 1.12, 0.95]} />
        <mesh name="Cube429" geometry={nodes.Cube429.geometry} material={materials['Material.4306']} position={[-348.92, 10.98, 112.37]} scale={[25.71, 0.81, 28.64]} />
        <mesh name="Cube518" geometry={nodes.Cube518.geometry} material={materials['Material.4389']} position={[-241.64, 10.66, 268.65]} scale={[7.11, 0.33, 0.33]} />
        <mesh name="Cube519" geometry={nodes.Cube519.geometry} material={materials['Material.4390']} position={[-236.15, 10.66, 266.19]} rotation={[0, Math.PI / 2, 0]} scale={[7.11, 0.33, 0.33]} />
        <group name="Cube520" position={[-233.42, 14.45, 256.82]} scale={[1.31, 3.44, 3.44]}>
          <mesh name="Cube25932" geometry={nodes.Cube25932.geometry} material={materials['Material.4391']} />
          <mesh name="Cube25932_1" geometry={nodes.Cube25932_1.geometry} material={materials['Material.4392']} />
        </group>
        <group name="Cube521" position={[-228.07, 14.73, 263.83]} scale={[1.4, 3.7, 3.7]}>
          <mesh name="Cube25933" geometry={nodes.Cube25933.geometry} material={materials['Material.4393']} />
          <mesh name="Cube25933_1" geometry={nodes.Cube25933_1.geometry} material={materials['Material.4394']} />
        </group>
        <mesh name="Cube522" geometry={nodes.Cube522.geometry} material={materials['Material.4395']} position={[-232.26, 11.09, 253.46]} scale={[2.47, 0.4, 7.29]} />
        <mesh name="Cube523" geometry={nodes.Cube523.geometry} material={materials['Material.4396']} position={[-230.03, 11.17, 266.5]} rotation={[0, Math.PI / 2, 0]} scale={[6.51, 0.4, 2.77]} />
        <mesh name="Cube524" geometry={nodes.Cube524.geometry} material={materials['Material.4397']} position={[-237.82, 10.66, 272.52]} scale={[7.11, 0.33, 0.17]} />
        <group name="Cube539" position={[-390.03, 15.63, -154.49]} scale={[3.03, 3.93, 3.03]}>
          <mesh name="Cube25951" geometry={nodes.Cube25951.geometry} material={materials['Material.4409']} />
          <mesh name="Cube25951_1" geometry={nodes.Cube25951_1.geometry} material={materials['Material.4410']} />
        </group>
        <mesh name="Cube541" geometry={nodes.Cube541.geometry} material={materials['Material.4412']} position={[-445.64, 11.25, -152.16]} scale={[0.49, 0.63, 0.49]} />
        <mesh name="Cube542" geometry={nodes.Cube542.geometry} material={materials['Material.4413']} position={[-390.5, 11.3, -152.16]} rotation={[0, Math.PI / 2, 0]} scale={[0.49, 0.63, 0.49]} />
        <mesh name="Cube543" geometry={nodes.Cube543.geometry} material={materials['Material.4414']} position={[-366.32, 11.38, -184.21]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.38, 0.63, 0.49]} />
        <mesh name="Cube544" geometry={nodes.Cube544.geometry} material={materials['Material.4415']} position={[-369.1, 11, -188.26]} rotation={[0, -1.57, 0]} scale={0.59} />
        <mesh name="Cube547" geometry={nodes.Cube547.geometry} material={materials['Material.4077']} position={[-366.73, 17.7, -172.08]} scale={[1.97, 1.48, 3.07]} />
        <mesh name="Cube548" geometry={nodes.Cube548.geometry} material={materials['Material.4417']} position={[-375.46, 11, -188.26]} rotation={[0, -1.57, 0]} scale={0.59} />
        <mesh name="Cube597" geometry={nodes.Cube597.geometry} material={nodes.Cube597.material} position={[-237.82, 10.66, 274.9]} scale={[7.11, 0.33, 0.17]} />
        <group name="Cube614" position={[-222.55, 14.76, -202.07]} rotation={[-Math.PI, 0, -Math.PI]} scale={[16.02, 4.48, 4.18]}>
          <mesh name="Cube26026" geometry={nodes.Cube26026.geometry} material={materials['Material.4458']} />
          <mesh name="Cube26026_1" geometry={nodes.Cube26026_1.geometry} material={materials['Material.4459']} />
          <mesh name="Cube26026_2" geometry={nodes.Cube26026_2.geometry} material={materials['Material.4460']} />
          <mesh name="Cube26026_3" geometry={nodes.Cube26026_3.geometry} material={materials['Material.4461']} />
        </group>
        <mesh name="Cube639" geometry={nodes.Cube639.geometry} material={materials['Material.4473']} position={[-200.03, 12.42, -219.32]} rotation={[Math.PI / 2, 0, Math.PI]} scale={[0.08, 0.03, 1.53]} />
        <mesh name="Cube642" geometry={nodes.Cube642.geometry} material={nodes.Cube642.material} position={[-200.03, 10.29, -219.82]} rotation={[0, 0, -Math.PI]} scale={[0.08, 0.07, 1.23]} />
        <mesh name="Cube643" geometry={nodes.Cube643.geometry} material={materials['Material.4476']} position={[-200.42, 12.58, -218.5]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.33, 1.61, 0.25]} />
        <mesh name="Cube670" geometry={nodes.Cube670.geometry} material={materials['Material.4503']} position={[-200.42, 12.58, -213.8]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.33, 1.61, 0.25]} />
        <mesh name="Cube697" geometry={nodes.Cube697.geometry} material={materials['Material.4530']} position={[-199.96, 14.4, -216.29]} rotation={[Math.PI / 2, 0, Math.PI]} scale={[0.1, 6.97, 0.25]} />
        <mesh name="Cube698" geometry={nodes.Cube698.geometry} material={materials['Material.4531']} position={[-203.17, 11.43, -223.08]} rotation={[-Math.PI, 0, -Math.PI]} scale={[3.05, 0.25, 0.25]} />
        <mesh name="Cube699" geometry={nodes.Cube699.geometry} material={materials['Material.4532']} position={[-203.17, 11.43, -209.17]} rotation={[-Math.PI, 0, -Math.PI]} scale={[3.05, 0.25, 0.25]} />
        <mesh name="Cube700" geometry={nodes.Cube700.geometry} material={materials['Material.4533']} position={[-206.3, 14.53, -223.04]} rotation={[-Math.PI, 0, -Math.PI]} scale={0.36} />
        <mesh name="Cube703" geometry={nodes.Cube703.geometry} material={materials['Material.4536']} position={[-203.85, 10.6, -216.49]} rotation={[-Math.PI, 0, -Math.PI]} scale={[3.21, 0.29, 7.56]} />
        <mesh name="Cube704" geometry={nodes.Cube704.geometry} material={materials['Material.4537']} position={[-205.11, 12.58, -218.5]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.33, 1.61, 0.25]} />
        <mesh name="Cube705" geometry={nodes.Cube705.geometry} material={materials['Material.4538']} position={[-205.11, 12.58, -213.52]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.33, 1.61, 0.25]} />
        <mesh name="Cube722" geometry={nodes.Cube722.geometry} material={materials['Material.4554']} position={[-226.53, 10.66, 255.94]} rotation={[0, Math.PI / 2, 0]} scale={[7.11, 0.33, 0.17]} />
        <group name="Cube12325" position={[-224.41, 10.59, 126.2]} scale={[34.05, 1.11, 50.75]}>
          <mesh name="Cube26543" geometry={nodes.Cube26543.geometry} material={materials['Material.4877']} />
          <mesh name="Cube26543_1" geometry={nodes.Cube26543_1.geometry} material={materials['Material.6289']} />
        </group>
        <mesh name="Cube12419" geometry={nodes.Cube12419.geometry} material={nodes.Cube12419.material} position={[-406.98, 10.75, -148.65]} scale={0.76} />
        <mesh name="Cube12428" geometry={nodes.Cube12428.geometry} material={materials['Material.4878']} position={[-378.01, 11.12, 227.3]} scale={[15.24, 0.48, 42.78]} />
        <mesh name="Cube12429" geometry={nodes.Cube12429.geometry} material={materials['Material.4879']} position={[-358.79, 11.12, 255.86]} rotation={[0, Math.PI / 2, 0]} scale={[15.24, 0.48, 34.91]} />
        <mesh name="Cube12434" geometry={nodes.Cube12434.geometry} material={materials['Material.4880']} position={[-356.03, 28.49, -179.2]} rotation={[0.08, 0, 0]} scale={[0.13, 6.53, 0.2]} />
        <mesh name="Cube12435" geometry={nodes.Cube12435.geometry} material={materials['Material.4881']} position={[-355.39, 28.5, -179.24]} rotation={[0.08, 0, 0]} scale={[0.13, 6.31, 0.16]} />
        <mesh name="Cube12436" geometry={nodes.Cube12436.geometry} material={materials['Material.4882']} position={[-356.75, 28.49, -179.2]} rotation={[0.08, 0, 0]} scale={[0.15, 6.53, 0.2]} />
        <mesh name="Cube12437" geometry={nodes.Cube12437.geometry} material={materials['Material.4881']} position={[-357.45, 28.5, -179.24]} rotation={[0.08, 0, 0]} scale={[0.13, 6.31, 0.16]} />
        <mesh name="Cube12438" geometry={nodes.Cube12438.geometry} material={materials['Material.4883']} position={[-428.04, 17.32, -153.5]} />
        <mesh name="Cube12439" geometry={nodes.Cube12439.geometry} material={materials['Material.4884']} position={[-429.54, 12.75, -153.5]} />
        <mesh name="Cube12440" geometry={nodes.Cube12440.geometry} material={materials['Material.4885']} position={[-391.38, 16.49, -167.78]} rotation={[0, Math.PI / 2, 0]} scale={[1, 1.43, 1]} />
        <mesh name="Cube12444" geometry={nodes.Cube12444.geometry} material={materials['Material.4886']} position={[-373.84, 16.51, -176.52]} rotation={[0, Math.PI / 2, 0]} scale={[4.24, 0.09, 2.94]} />
        <mesh name="Cube12449" geometry={nodes.Cube12449.geometry} material={materials['Material.4887']} position={[-233.67, 10.78, 214.53]} rotation={[0, Math.PI / 2, 0]} scale={[0.57, 1.12, 1]} />
        <mesh name="Cube12450" geometry={nodes.Cube12450.geometry} material={materials['Material.4888']} position={[-258.92, 10.78, 217.86]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.57, 1.12, 1]} />
        <mesh name="Cube12451" geometry={nodes.Cube12451.geometry} material={materials['Material.4889']} position={[-258.92, 10.95, 247.25]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.57, 1.12, 0.7]} />
        <mesh name="Cube12453" geometry={nodes.Cube12453.geometry} material={materials['Material.783']} position={[-258.02, 10.95, 278.21]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.57, 1.12, 0.7]} />
        <mesh name="Cube12454" geometry={nodes.Cube12454.geometry} material={materials['Material.4890']} position={[-229.53, 10.68, 268.28]} scale={0.34} />
        <mesh name="Cube12456" geometry={nodes.Cube12456.geometry} material={materials['Material.4891']} position={[-234.43, 14.17, 260.28]} scale={0.38} />
        <mesh name="Cube12457" geometry={nodes.Cube12457.geometry} material={materials['Material.4892']} position={[-227.8, 10.78, 217.86]} rotation={[-Math.PI, 0, 0]} scale={[0.57, 1.12, 1]} />
        <mesh name="Cube12458" geometry={nodes.Cube12458.geometry} material={materials['Material.4893']} position={[-255.73, 10.78, 237.18]} rotation={[0, -1.57, 0]} scale={[0.57, 1.12, 1]} />
        <mesh name="Cube12459" geometry={nodes.Cube12459.geometry} material={materials['Material.4894']} position={[-230.26, 12.13, 262.49]} rotation={[Math.PI / 2, 0, 0]} scale={[0.04, 0.07, 0.04]} />
        <mesh name="Cube12460" geometry={nodes.Cube12460.geometry} material={materials['Material.4895']} position={[-230.26, 12.59, 265.11]} scale={0.04} />
        <mesh name="Cube12461" geometry={nodes.Cube12461.geometry} material={materials['Material.4896']} position={[-230.26, 12.8, 265.29]} rotation={[Math.PI / 2, 0, 0]} scale={[0.04, 0.07, 0.04]} />
        <mesh name="Cube12462" geometry={nodes.Cube12462.geometry} material={materials['Material.4897']} position={[-230.26, 12.13, 265.29]} rotation={[Math.PI / 2, 0, 0]} scale={[0.04, 0.07, 0.04]} />
        <mesh name="Cube12463" geometry={nodes.Cube12463.geometry} material={materials['Material.4898']} position={[-231.6, 12.59, 260.39]} rotation={[0, Math.PI / 2, 0]} scale={[0.04, 0.05, 0.04]} />
        <mesh name="Cube12464" geometry={nodes.Cube12464.geometry} material={materials['Material.4899']} position={[-231.42, 12.8, 260.39]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.04, 0.07, 0.04]} />
        <mesh name="Cube12465" geometry={nodes.Cube12465.geometry} material={materials['Material.4900']} position={[-231.42, 12.13, 260.39]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.04, 0.07, 0.04]} />
        <mesh name="Cube12466" geometry={nodes.Cube12466.geometry} material={materials['Material.776']} position={[-358.93, 10.69, -162.18]} />
        <mesh name="Cube12471" geometry={nodes.Cube12471.geometry} material={materials['Material.4902']} position={[-377.24, 11.65, -180.22]} rotation={[-Math.PI, 0, -Math.PI]} />
        <mesh name="Cube12483" geometry={nodes.Cube12483.geometry} material={materials['Material.4903']} position={[-213.5, 11.93, -130.33]} />
        <mesh name="Cube12495" geometry={nodes.Cube12495.geometry} material={materials['Material.855']} position={[-257.43, 11.33, 81.3]} scale={[1, 1, 0.18]} />
        <mesh name="Cube12496" geometry={nodes.Cube12496.geometry} material={materials['Material.854']} position={[-254.24, 11.33, 74.77]} scale={[1, 1, 0.18]} />
        <mesh name="Cube12497" geometry={nodes.Cube12497.geometry} material={materials['Material.857']} position={[-251.87, 11.33, 74.77]} rotation={[0, Math.PI / 2, 0]} scale={[1, 1, 0.18]} />
        <mesh name="Cube12498" geometry={nodes.Cube12498.geometry} material={materials['Material.856']} position={[-251.77, 11.33, 80.7]} rotation={[0, Math.PI / 2, 0]} scale={[1, 1, 0.18]} />
        <mesh name="Cube12499" geometry={nodes.Cube12499.geometry} material={materials['Material.858']} position={[-251.83, 11.41, 76.79]} scale={[1.12, 1.12, 1.29]} />
        <mesh name="Cube12503" geometry={nodes.Cube12503.geometry} material={materials['Material.4904']} position={[-338.37, 11.12, 227.3]} scale={[15.24, 0.48, 42.78]} />
        <mesh name="Cube17589" geometry={nodes.Cube17589.geometry} material={materials['Material.5221']} position={[-285.92, 11.73, 151.62]} scale={[13.83, 1, 23.01]} />
        <mesh name="Cube18216" geometry={nodes.Cube18216.geometry} material={materials['Material.5720']} position={[-425.3, 11.73, -8.86]} rotation={[Math.PI, 0, Math.PI]} scale={[13.83, 1, 23.01]} />
        <mesh name="Cube18224" geometry={nodes.Cube18224.geometry} material={materials['Material.5720']} position={[-425.3, 11.73, 9.62]} rotation={[Math.PI, 0, Math.PI]} scale={[13.83, 1, 23.01]} />
        <mesh name="Cube18536" geometry={nodes.Cube18536.geometry} material={materials['Material.6040']} position={[-291.51, 10.36, 20.07]} scale={[15.24, 0.48, 16.98]} />
        <mesh name="Curve020" geometry={nodes.Curve020.geometry} material={materials['SVGMat.001']} position={[-358.53, 7.44, -148.42]} rotation={[Math.PI / 2, 0, 0]} scale={[44.81, 19.99, 44.81]} />
        <mesh name="Cylinder001" geometry={nodes.Cylinder001.geometry} material={materials['Material.6044']} position={[-161, 11.93, 24.75]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[3.36, 1.76, 2.69]} />
        <mesh name="Cylinder002" geometry={nodes.Cylinder002.geometry} material={materials['Material.6045']} position={[-116.68, 11.93, 36.09]} rotation={[Math.PI / 2, 0, 0]} scale={[3.36, 1.76, 2.69]} />
        <group name="D_Block" position={[-379.53, 12.61, -371.91]} rotation={[0, Math.PI / 2, 0]} scale={[4.02, 1.49, 38.39]}>
          <mesh name="Cube28014" geometry={nodes.Cube28014.geometry} material={materials['Roof.001']} />
          <mesh name="Cube28014_1" geometry={nodes.Cube28014_1.geometry} material={materials['Wall.001']} />
          <mesh name="Cube28014_2" geometry={nodes.Cube28014_2.geometry} material={materials['Trim.001']} />
          <mesh name="Cube28014_3" geometry={nodes.Cube28014_3.geometry} material={materials['Windows.003']} />
        </group>
        <mesh name="Entrance" geometry={nodes.Entrance.geometry} material={materials['Material.6046']} position={[-474.14, 10.94, 3.71]} rotation={[0, Math.PI / 2, 0]} scale={[0.33, 0.53, 1.36]} />
        <mesh name="Entrance_Fence_1" geometry={nodes.Entrance_Fence_1.geometry} material={nodes.Entrance_Fence_1.material} position={[-355.54, 11.32, 188.99]} rotation={[0, 0, -Math.PI]} scale={[0.05, 0.29, 0.41]} />
        <mesh name="Entrance_Fence_1001" geometry={nodes.Entrance_Fence_1001.geometry} material={nodes.Entrance_Fence_1001.material} position={[-355.54, 11.32, 188.99]} rotation={[0, 0, -Math.PI]} scale={[0.05, 0.29, 0.41]} />
        <mesh name="Entrance_Fence_1002" geometry={nodes.Entrance_Fence_1002.geometry} material={nodes.Entrance_Fence_1002.material} position={[-355.54, 11.32, 197.68]} rotation={[0, 0, -Math.PI]} scale={[0.05, 0.29, 0.04]} />
        <mesh name="Entrance_Fence_1003" geometry={nodes.Entrance_Fence_1003.geometry} material={nodes.Entrance_Fence_1003.material} position={[-359.77, 11.32, 188.99]} rotation={[0, 0, -Math.PI]} scale={[0.05, 0.29, 0.41]} />
        <mesh name="Entrance_Fence_1004" geometry={nodes.Entrance_Fence_1004.geometry} material={nodes.Entrance_Fence_1004.material} position={[-359.77, 11.32, 188.99]} rotation={[0, 0, -Math.PI]} scale={[0.05, 0.29, 0.41]} />
        <mesh name="Entrance_Fence_1005" geometry={nodes.Entrance_Fence_1005.geometry} material={nodes.Entrance_Fence_1005.material} position={[-359.77, 11.32, 197.68]} rotation={[0, 0, -Math.PI]} scale={[0.05, 0.29, 0.04]} />
        <mesh name="Entrance_Fence_1006" geometry={nodes.Entrance_Fence_1006.geometry} material={nodes.Entrance_Fence_1006.material} position={[-359.77, 11.32, 197.68]} rotation={[0, 0, -Math.PI]} scale={[0.05, 0.29, 0.04]} />
        <mesh name="Entrance_Fence_1007" geometry={nodes.Entrance_Fence_1007.geometry} material={nodes.Entrance_Fence_1007.material} position={[-359.77, 11.32, 197.68]} rotation={[0, 0, -Math.PI]} scale={[0.05, 0.29, 0.04]} />
        <mesh name="Entrance_Fence_1008" geometry={nodes.Entrance_Fence_1008.geometry} material={nodes.Entrance_Fence_1008.material} position={[-355.54, 11.32, 197.68]} rotation={[0, 0, -Math.PI]} scale={[0.05, 0.29, 0.04]} />
        <mesh name="Entrance_Fence_1009" geometry={nodes.Entrance_Fence_1009.geometry} material={nodes.Entrance_Fence_1009.material} position={[-359.77, 11.32, 197.68]} rotation={[0, 0, -Math.PI]} scale={[0.05, 0.29, 0.04]} />
        <mesh name="Entrance_Fence_1010" geometry={nodes.Entrance_Fence_1010.geometry} material={nodes.Entrance_Fence_1010.material} position={[-359.77, 11.32, 197.68]} rotation={[0, 0, -Math.PI]} scale={[0.05, 0.29, 0.04]} />
        <mesh name="Entrance_Fence_1014" geometry={nodes.Entrance_Fence_1014.geometry} material={nodes.Entrance_Fence_1014.material} position={[-355.54, 11.32, 197.68]} rotation={[0, 0, -Math.PI]} scale={[0.05, 0.29, 0.04]} />
        <mesh name="Entrance_Fence_1020" geometry={nodes.Entrance_Fence_1020.geometry} material={nodes.Entrance_Fence_1020.material} position={[-355.54, 11.32, 197.68]} rotation={[0, 0, -Math.PI]} scale={[0.05, 0.29, 0.04]} />
        <mesh name="Entrance_Fence_1026" geometry={nodes.Entrance_Fence_1026.geometry} material={nodes.Entrance_Fence_1026.material} position={[-355.54, 11.32, 197.68]} rotation={[0, 0, -Math.PI]} scale={[0.05, 0.29, 0.04]} />
        <mesh name="Entrance_Fence_1029" geometry={nodes.Entrance_Fence_1029.geometry} material={nodes.Entrance_Fence_1029.material} position={[-355.54, 11.32, 197.68]} rotation={[0, 0, -Math.PI]} scale={[0.05, 0.29, 0.04]} />
        <mesh name="Entrance_Stairs" geometry={nodes.Entrance_Stairs.geometry} material={materials['Grayish Concrete.001']} position={[-253.78, 11.07, 254.71]} scale={[10.51, 3.58, 10.51]} />
        <mesh name="Fence003" geometry={nodes.Fence003.geometry} material={nodes.Fence003.material} position={[-357.62, 13.48, 217.9]} rotation={[-Math.PI, Math.PI / 2, 0]} scale={[0.05, 0.29, 0.04]} />
        <mesh name="Fence027" geometry={nodes.Fence027.geometry} material={nodes.Fence027.material} position={[-357.62, 13.48, 217.9]} rotation={[-Math.PI, Math.PI / 2, 0]} scale={[0.05, 0.29, 0.04]} />
        <mesh name="Fence267" geometry={nodes.Fence267.geometry} material={nodes.Fence267.material} position={[-357.62, 13.48, 217.9]} rotation={[-Math.PI, Math.PI / 2, 0]} scale={[0.05, 0.29, 0.04]} />
        <mesh name="Fence275" geometry={nodes.Fence275.geometry} material={nodes.Fence275.material} position={[-357.62, 13.48, 217.9]} rotation={[-Math.PI, Math.PI / 2, 0]} scale={[0.05, 0.29, 0.04]} />
        <mesh name="Fence280" geometry={nodes.Fence280.geometry} material={nodes.Fence280.material} position={[-357.62, 13.48, 217.9]} rotation={[-Math.PI, Math.PI / 2, 0]} scale={[0.05, 0.29, 0.04]} />
        <mesh name="Final001" geometry={nodes.Final001.geometry} material={materials['Material.6047']} position={[-229.42, 12.24, 325.75]} scale={[1.38, 2.37, 1.62]} />
        <mesh name="Final002" geometry={nodes.Final002.geometry} material={materials['Material.6048']} position={[-231.02, 12.24, 324.8]} scale={[2.38, 2.37, 1.62]} />
        <mesh name="Final004" geometry={nodes.Final004.geometry} material={materials['Material.6049']} position={[-232.23, 13.1, 329.34]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final005" geometry={nodes.Final005.geometry} material={materials['Material.6050']} position={[-222.32, 12.24, 342.14]} scale={[1.38, 2.37, 1.62]} />
        <mesh name="Final008" geometry={nodes.Final008.geometry} material={materials['Material.6051']} position={[-248.43, 12.51, 323.66]} scale={[2.23, 1.6, 1.52]} />
        <mesh name="Final009" geometry={nodes.Final009.geometry} material={materials['Material.6052']} position={[-245.31, 12.51, 323.66]} scale={[2.23, 1.6, 1.52]} />
        <mesh name="Final010" geometry={nodes.Final010.geometry} material={materials['Material.6053']} position={[-237.52, 12.51, 323.66]} scale={[2.23, 1.6, 1.52]} />
        <mesh name="Final011" geometry={nodes.Final011.geometry} material={materials['Material.6054']} position={[-234.08, 12.51, 323.66]} scale={[2.23, 1.6, 1.52]} />
        <mesh name="Final012" geometry={nodes.Final012.geometry} material={materials['Material.6055']} position={[-234.47, 12.24, 324.8]} scale={[2.38, 2.37, 1.62]} />
        <mesh name="Final018" geometry={nodes.Final018.geometry} material={materials['Material.6056']} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final022" geometry={nodes.Final022.geometry} material={nodes.Final022.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final023" geometry={nodes.Final023.geometry} material={nodes.Final023.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final026" geometry={nodes.Final026.geometry} material={nodes.Final026.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final027" geometry={nodes.Final027.geometry} material={nodes.Final027.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final030" geometry={nodes.Final030.geometry} material={nodes.Final030.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final031" geometry={nodes.Final031.geometry} material={nodes.Final031.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final056" geometry={nodes.Final056.geometry} material={materials['Material.6057']} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final063" geometry={nodes.Final063.geometry} material={materials['Material.6058']} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final064" geometry={nodes.Final064.geometry} material={materials['Material.6059']} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final070" geometry={nodes.Final070.geometry} material={materials['Material.6060']} position={[-240.21, 12.51, 323.66]} scale={[2.23, 1.6, 1.52]} />
        <mesh name="Final071" geometry={nodes.Final071.geometry} material={materials['Material.6061']} position={[-240.48, 12.51, 323.66]} scale={[2.23, 1.6, 1.52]} />
        <mesh name="Final077" geometry={nodes.Final077.geometry} material={materials['Material.6062']} position={[-242.27, 12.51, 323.66]} scale={[2.23, 1.6, 1.52]} />
        <mesh name="Final078" geometry={nodes.Final078.geometry} material={materials['Material.6063']} position={[-240.62, 12.51, 323.66]} scale={[2.23, 1.6, 1.52]} />
        <mesh name="Final086" geometry={nodes.Final086.geometry} material={materials['Material.6064']} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final087" geometry={nodes.Final087.geometry} material={materials['Material.6065']} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final088" geometry={nodes.Final088.geometry} material={materials['Material.6066']} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final089" geometry={nodes.Final089.geometry} material={nodes.Final089.material} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final090" geometry={nodes.Final090.geometry} material={materials['Material.6067']} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final091" geometry={nodes.Final091.geometry} material={nodes.Final091.material} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final106" geometry={nodes.Final106.geometry} material={materials['Material.6068']} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final107" geometry={nodes.Final107.geometry} material={materials['Material.6069']} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final108" geometry={nodes.Final108.geometry} material={materials['Material.6070']} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final117" geometry={nodes.Final117.geometry} material={materials['Material.6071']} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final156" geometry={nodes.Final156.geometry} material={materials['Material.6072']} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final161" geometry={nodes.Final161.geometry} material={nodes.Final161.material} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final166" geometry={nodes.Final166.geometry} material={materials['Material.6073']} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final202" geometry={nodes.Final202.geometry} material={materials['Material.6074']} position={[-234.7, 13.1, 328.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final262" geometry={nodes.Final262.geometry} material={materials['Material.6075']} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final308" geometry={nodes.Final308.geometry} material={nodes.Final308.material} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final309" geometry={nodes.Final309.geometry} material={nodes.Final309.material} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final335" geometry={nodes.Final335.geometry} material={nodes.Final335.material} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final340" geometry={nodes.Final340.geometry} material={materials['Material.6076']} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final364" geometry={nodes.Final364.geometry} material={materials['Material.6077']} position={[-227.59, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final430" geometry={nodes.Final430.geometry} material={materials['Material.6078']} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final468" geometry={nodes.Final468.geometry} material={materials['Material.6079']} position={[-231.5, 13.1, 329.16]} scale={[1.65, 2.84, 1.94]} />
        <group name="Final469" position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]}>
          <mesh name="Cube28150" geometry={nodes.Cube28150.geometry} material={materials['Material.6080']} />
          <mesh name="Cube28150_1" geometry={nodes.Cube28150_1.geometry} material={materials['Material.6081']} />
        </group>
        <mesh name="Final470" geometry={nodes.Final470.geometry} material={materials['Material.6082']} position={[-231.37, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final471" geometry={nodes.Final471.geometry} material={materials['Material.6083']} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final493" geometry={nodes.Final493.geometry} material={nodes.Final493.material} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final499" geometry={nodes.Final499.geometry} material={materials['Material.6084']} position={[-231.5, 13.1, 327.74]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final553" geometry={nodes.Final553.geometry} material={materials['Material.6085']} position={[-231.5, 13.1, 328.51]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final571" geometry={nodes.Final571.geometry} material={nodes.Final571.material} position={[-231.5, 13.1, 328.51]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final685" geometry={nodes.Final685.geometry} material={materials['Material.6086']} position={[-231.5, 13.1, 328.51]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final687" geometry={nodes.Final687.geometry} material={nodes.Final687.material} position={[-231.5, 13.1, 328.51]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final697" geometry={nodes.Final697.geometry} material={materials['Material.6087']} position={[-231.5, 13.1, 329.57]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final698" geometry={nodes.Final698.geometry} material={nodes.Final698.material} position={[-231.5, 13.1, 329.57]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final700" geometry={nodes.Final700.geometry} material={materials['Material.6088']} position={[-231.5, 13.1, 329.6]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final701" geometry={nodes.Final701.geometry} material={nodes.Final701.material} position={[-231.5, 13.1, 329.57]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final702" geometry={nodes.Final702.geometry} material={materials['Material.6089']} position={[-231.5, 13.1, 329.6]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final703" geometry={nodes.Final703.geometry} material={nodes.Final703.material} position={[-231.5, 13.1, 329.57]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final704" geometry={nodes.Final704.geometry} material={materials['Material.6090']} position={[-231.5, 13.1, 329.6]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final705" geometry={nodes.Final705.geometry} material={nodes.Final705.material} position={[-231.5, 13.1, 329.57]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final706" geometry={nodes.Final706.geometry} material={nodes.Final706.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final707" geometry={nodes.Final707.geometry} material={nodes.Final707.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final712" geometry={nodes.Final712.geometry} material={nodes.Final712.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final713" geometry={nodes.Final713.geometry} material={nodes.Final713.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final714" geometry={nodes.Final714.geometry} material={nodes.Final714.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final715" geometry={nodes.Final715.geometry} material={nodes.Final715.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final735" geometry={nodes.Final735.geometry} material={materials['Material.6091']} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final752" geometry={nodes.Final752.geometry} material={nodes.Final752.material} position={[-166.59, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[2.84, 2.84, 1.94]} />
        <mesh name="Final759" geometry={nodes.Final759.geometry} material={nodes.Final759.material} position={[-167.99, 12.24, 308.95]} rotation={[-Math.PI, 0, -Math.PI]} scale={[2.38, 2.37, 1.62]} />
        <mesh name="Final762" geometry={nodes.Final762.geometry} material={nodes.Final762.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final769" geometry={nodes.Final769.geometry} material={nodes.Final769.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final773" geometry={nodes.Final773.geometry} material={materials['Material.6092']} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final777" geometry={nodes.Final777.geometry} material={nodes.Final777.material} position={[-193.29, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final779" geometry={nodes.Final779.geometry} material={materials['Material.6093']} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final794" geometry={nodes.Final794.geometry} material={materials['Material.6094']} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final820" geometry={nodes.Final820.geometry} material={nodes.Final820.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final821" geometry={nodes.Final821.geometry} material={nodes.Final821.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final822" geometry={nodes.Final822.geometry} material={nodes.Final822.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final823" geometry={nodes.Final823.geometry} material={nodes.Final823.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final824" geometry={nodes.Final824.geometry} material={nodes.Final824.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final825" geometry={nodes.Final825.geometry} material={nodes.Final825.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final826" geometry={nodes.Final826.geometry} material={nodes.Final826.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final827" geometry={nodes.Final827.geometry} material={nodes.Final827.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final828" geometry={nodes.Final828.geometry} material={nodes.Final828.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final829" geometry={nodes.Final829.geometry} material={nodes.Final829.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final830" geometry={nodes.Final830.geometry} material={nodes.Final830.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final836" geometry={nodes.Final836.geometry} material={nodes.Final836.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final837" geometry={nodes.Final837.geometry} material={nodes.Final837.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final838" geometry={nodes.Final838.geometry} material={nodes.Final838.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final839" geometry={nodes.Final839.geometry} material={nodes.Final839.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final840" geometry={nodes.Final840.geometry} material={nodes.Final840.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final841" geometry={nodes.Final841.geometry} material={nodes.Final841.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final842" geometry={nodes.Final842.geometry} material={nodes.Final842.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final843" geometry={nodes.Final843.geometry} material={nodes.Final843.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final844" geometry={nodes.Final844.geometry} material={nodes.Final844.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final845" geometry={nodes.Final845.geometry} material={nodes.Final845.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final846" geometry={nodes.Final846.geometry} material={nodes.Final846.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final847" geometry={nodes.Final847.geometry} material={nodes.Final847.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final848" geometry={nodes.Final848.geometry} material={nodes.Final848.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final849" geometry={nodes.Final849.geometry} material={nodes.Final849.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final850" geometry={nodes.Final850.geometry} material={nodes.Final850.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final851" geometry={nodes.Final851.geometry} material={nodes.Final851.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final852" geometry={nodes.Final852.geometry} material={nodes.Final852.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final853" geometry={nodes.Final853.geometry} material={nodes.Final853.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final854" geometry={nodes.Final854.geometry} material={nodes.Final854.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final859" geometry={nodes.Final859.geometry} material={nodes.Final859.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final860" geometry={nodes.Final860.geometry} material={nodes.Final860.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final861" geometry={nodes.Final861.geometry} material={nodes.Final861.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final862" geometry={nodes.Final862.geometry} material={nodes.Final862.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final863" geometry={nodes.Final863.geometry} material={nodes.Final863.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final864" geometry={nodes.Final864.geometry} material={nodes.Final864.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final865" geometry={nodes.Final865.geometry} material={nodes.Final865.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final866" geometry={nodes.Final866.geometry} material={nodes.Final866.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final867" geometry={nodes.Final867.geometry} material={nodes.Final867.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final868" geometry={nodes.Final868.geometry} material={nodes.Final868.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final869" geometry={nodes.Final869.geometry} material={nodes.Final869.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final870" geometry={nodes.Final870.geometry} material={nodes.Final870.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final871" geometry={nodes.Final871.geometry} material={nodes.Final871.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final872" geometry={nodes.Final872.geometry} material={nodes.Final872.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final873" geometry={nodes.Final873.geometry} material={nodes.Final873.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final874" geometry={nodes.Final874.geometry} material={nodes.Final874.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final875" geometry={nodes.Final875.geometry} material={nodes.Final875.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final876" geometry={nodes.Final876.geometry} material={nodes.Final876.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final878" geometry={nodes.Final878.geometry} material={nodes.Final878.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final879" geometry={nodes.Final879.geometry} material={nodes.Final879.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final880" geometry={nodes.Final880.geometry} material={nodes.Final880.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final881" geometry={nodes.Final881.geometry} material={nodes.Final881.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final882" geometry={nodes.Final882.geometry} material={nodes.Final882.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final883" geometry={nodes.Final883.geometry} material={nodes.Final883.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final884" geometry={nodes.Final884.geometry} material={nodes.Final884.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final885" geometry={nodes.Final885.geometry} material={nodes.Final885.material} position={[-203.88, 12.22, 280.28]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.38, 2.37, 1.62]} />
        <mesh name="Final886" geometry={nodes.Final886.geometry} material={nodes.Final886.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final887" geometry={nodes.Final887.geometry} material={nodes.Final887.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final888" geometry={nodes.Final888.geometry} material={nodes.Final888.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final889" geometry={nodes.Final889.geometry} material={nodes.Final889.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final890" geometry={nodes.Final890.geometry} material={nodes.Final890.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final891" geometry={nodes.Final891.geometry} material={nodes.Final891.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final892" geometry={nodes.Final892.geometry} material={nodes.Final892.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final893" geometry={nodes.Final893.geometry} material={nodes.Final893.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final894" geometry={nodes.Final894.geometry} material={nodes.Final894.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final895" geometry={nodes.Final895.geometry} material={nodes.Final895.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final896" geometry={nodes.Final896.geometry} material={nodes.Final896.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final897" geometry={nodes.Final897.geometry} material={nodes.Final897.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final898" geometry={nodes.Final898.geometry} material={nodes.Final898.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final899" geometry={nodes.Final899.geometry} material={nodes.Final899.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final900" geometry={nodes.Final900.geometry} material={nodes.Final900.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final901" geometry={nodes.Final901.geometry} material={nodes.Final901.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final902" geometry={nodes.Final902.geometry} material={nodes.Final902.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final903" geometry={nodes.Final903.geometry} material={nodes.Final903.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final904" geometry={nodes.Final904.geometry} material={nodes.Final904.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final905" geometry={nodes.Final905.geometry} material={nodes.Final905.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final906" geometry={nodes.Final906.geometry} material={nodes.Final906.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final907" geometry={nodes.Final907.geometry} material={nodes.Final907.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final908" geometry={nodes.Final908.geometry} material={nodes.Final908.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final909" geometry={nodes.Final909.geometry} material={nodes.Final909.material} position={[-203.88, 12.22, 280.28]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.38, 2.37, 1.62]} />
        <mesh name="Final910" geometry={nodes.Final910.geometry} material={nodes.Final910.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final911" geometry={nodes.Final911.geometry} material={nodes.Final911.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final912" geometry={nodes.Final912.geometry} material={nodes.Final912.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final913" geometry={nodes.Final913.geometry} material={nodes.Final913.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final914" geometry={nodes.Final914.geometry} material={nodes.Final914.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final915" geometry={nodes.Final915.geometry} material={nodes.Final915.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final916" geometry={nodes.Final916.geometry} material={nodes.Final916.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final917" geometry={nodes.Final917.geometry} material={nodes.Final917.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final918" geometry={nodes.Final918.geometry} material={nodes.Final918.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final919" geometry={nodes.Final919.geometry} material={nodes.Final919.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final920" geometry={nodes.Final920.geometry} material={nodes.Final920.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final921" geometry={nodes.Final921.geometry} material={nodes.Final921.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final922" geometry={nodes.Final922.geometry} material={nodes.Final922.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final923" geometry={nodes.Final923.geometry} material={nodes.Final923.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final924" geometry={nodes.Final924.geometry} material={nodes.Final924.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final925" geometry={nodes.Final925.geometry} material={nodes.Final925.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final926" geometry={nodes.Final926.geometry} material={nodes.Final926.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final927" geometry={nodes.Final927.geometry} material={nodes.Final927.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final928" geometry={nodes.Final928.geometry} material={nodes.Final928.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final929" geometry={nodes.Final929.geometry} material={nodes.Final929.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final930" geometry={nodes.Final930.geometry} material={nodes.Final930.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final931" geometry={nodes.Final931.geometry} material={nodes.Final931.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final932" geometry={nodes.Final932.geometry} material={nodes.Final932.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final933" geometry={nodes.Final933.geometry} material={nodes.Final933.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final934" geometry={nodes.Final934.geometry} material={nodes.Final934.material} position={[-199.2, 11.5, 281.69]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.15, 1.98, 1.36]} />
        <mesh name="Final935" geometry={nodes.Final935.geometry} material={nodes.Final935.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final936" geometry={nodes.Final936.geometry} material={nodes.Final936.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final937" geometry={nodes.Final937.geometry} material={nodes.Final937.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final938" geometry={nodes.Final938.geometry} material={nodes.Final938.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final939" geometry={nodes.Final939.geometry} material={nodes.Final939.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final940" geometry={nodes.Final940.geometry} material={nodes.Final940.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final941" geometry={nodes.Final941.geometry} material={nodes.Final941.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final942" geometry={nodes.Final942.geometry} material={nodes.Final942.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final943" geometry={nodes.Final943.geometry} material={nodes.Final943.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final944" geometry={nodes.Final944.geometry} material={nodes.Final944.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final945" geometry={nodes.Final945.geometry} material={nodes.Final945.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final946" geometry={nodes.Final946.geometry} material={nodes.Final946.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final947" geometry={nodes.Final947.geometry} material={nodes.Final947.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final948" geometry={nodes.Final948.geometry} material={nodes.Final948.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final949" geometry={nodes.Final949.geometry} material={nodes.Final949.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final950" geometry={nodes.Final950.geometry} material={nodes.Final950.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final951" geometry={nodes.Final951.geometry} material={nodes.Final951.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final952" geometry={nodes.Final952.geometry} material={nodes.Final952.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final953" geometry={nodes.Final953.geometry} material={nodes.Final953.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final954" geometry={nodes.Final954.geometry} material={nodes.Final954.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final955" geometry={nodes.Final955.geometry} material={nodes.Final955.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final956" geometry={nodes.Final956.geometry} material={nodes.Final956.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final957" geometry={nodes.Final957.geometry} material={nodes.Final957.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final958" geometry={nodes.Final958.geometry} material={nodes.Final958.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final960" geometry={nodes.Final960.geometry} material={nodes.Final960.material} position={[-199.2, 11.5, 281.69]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.15, 1.98, 1.36]} />
        <mesh name="Final961" geometry={nodes.Final961.geometry} material={nodes.Final961.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final962" geometry={nodes.Final962.geometry} material={nodes.Final962.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final963" geometry={nodes.Final963.geometry} material={nodes.Final963.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final965" geometry={nodes.Final965.geometry} material={nodes.Final965.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final966" geometry={nodes.Final966.geometry} material={nodes.Final966.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final967" geometry={nodes.Final967.geometry} material={nodes.Final967.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final968" geometry={nodes.Final968.geometry} material={nodes.Final968.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final969" geometry={nodes.Final969.geometry} material={nodes.Final969.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final970" geometry={nodes.Final970.geometry} material={nodes.Final970.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final971" geometry={nodes.Final971.geometry} material={nodes.Final971.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final972" geometry={nodes.Final972.geometry} material={nodes.Final972.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final973" geometry={nodes.Final973.geometry} material={nodes.Final973.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final974" geometry={nodes.Final974.geometry} material={nodes.Final974.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final975" geometry={nodes.Final975.geometry} material={nodes.Final975.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final976" geometry={nodes.Final976.geometry} material={nodes.Final976.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final977" geometry={nodes.Final977.geometry} material={nodes.Final977.material} position={[-199.2, 11.5, 281.69]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.15, 1.98, 1.36]} />
        <mesh name="Final978" geometry={nodes.Final978.geometry} material={nodes.Final978.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final979" geometry={nodes.Final979.geometry} material={nodes.Final979.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final980" geometry={nodes.Final980.geometry} material={nodes.Final980.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final981" geometry={nodes.Final981.geometry} material={nodes.Final981.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final982" geometry={nodes.Final982.geometry} material={nodes.Final982.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final983" geometry={nodes.Final983.geometry} material={nodes.Final983.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final984" geometry={nodes.Final984.geometry} material={nodes.Final984.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final985" geometry={nodes.Final985.geometry} material={nodes.Final985.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final986" geometry={nodes.Final986.geometry} material={nodes.Final986.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final987" geometry={nodes.Final987.geometry} material={nodes.Final987.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final988" geometry={nodes.Final988.geometry} material={nodes.Final988.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final989" geometry={nodes.Final989.geometry} material={nodes.Final989.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final990" geometry={nodes.Final990.geometry} material={nodes.Final990.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final991" geometry={nodes.Final991.geometry} material={nodes.Final991.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final992" geometry={nodes.Final992.geometry} material={nodes.Final992.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final993" geometry={nodes.Final993.geometry} material={nodes.Final993.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final995" geometry={nodes.Final995.geometry} material={nodes.Final995.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final996" geometry={nodes.Final996.geometry} material={nodes.Final996.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final997" geometry={nodes.Final997.geometry} material={nodes.Final997.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final998" geometry={nodes.Final998.geometry} material={nodes.Final998.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final999" geometry={nodes.Final999.geometry} material={nodes.Final999.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1000" geometry={nodes.Final1000.geometry} material={nodes.Final1000.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1001" geometry={nodes.Final1001.geometry} material={nodes.Final1001.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1002" geometry={nodes.Final1002.geometry} material={nodes.Final1002.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1003" geometry={nodes.Final1003.geometry} material={nodes.Final1003.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1004" geometry={nodes.Final1004.geometry} material={nodes.Final1004.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1005" geometry={nodes.Final1005.geometry} material={nodes.Final1005.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1006" geometry={nodes.Final1006.geometry} material={nodes.Final1006.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1007" geometry={nodes.Final1007.geometry} material={nodes.Final1007.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1008" geometry={nodes.Final1008.geometry} material={nodes.Final1008.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1009" geometry={nodes.Final1009.geometry} material={nodes.Final1009.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1010" geometry={nodes.Final1010.geometry} material={nodes.Final1010.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1011" geometry={nodes.Final1011.geometry} material={nodes.Final1011.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1012" geometry={nodes.Final1012.geometry} material={nodes.Final1012.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1013" geometry={nodes.Final1013.geometry} material={nodes.Final1013.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1014" geometry={nodes.Final1014.geometry} material={nodes.Final1014.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1015" geometry={nodes.Final1015.geometry} material={nodes.Final1015.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1016" geometry={nodes.Final1016.geometry} material={nodes.Final1016.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1017" geometry={nodes.Final1017.geometry} material={nodes.Final1017.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1018" geometry={nodes.Final1018.geometry} material={nodes.Final1018.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1019" geometry={nodes.Final1019.geometry} material={nodes.Final1019.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1020" geometry={nodes.Final1020.geometry} material={nodes.Final1020.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1021" geometry={nodes.Final1021.geometry} material={nodes.Final1021.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1022" geometry={nodes.Final1022.geometry} material={nodes.Final1022.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1023" geometry={nodes.Final1023.geometry} material={nodes.Final1023.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1414" geometry={nodes.Final1414.geometry} material={nodes.Final1414.material} position={[-224.96, 11.5, 306.43]} scale={[1.15, 1.98, 1.36]} />
        <mesh name="Final1682" geometry={nodes.Final1682.geometry} material={nodes.Final1682.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1683" geometry={nodes.Final1683.geometry} material={nodes.Final1683.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1684" geometry={nodes.Final1684.geometry} material={nodes.Final1684.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1685" geometry={nodes.Final1685.geometry} material={nodes.Final1685.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1686" geometry={nodes.Final1686.geometry} material={nodes.Final1686.material} position={[-199.2, 11.5, 283.61]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.15, 1.98, 1.36]} />
        <mesh name="Final1687" geometry={nodes.Final1687.geometry} material={nodes.Final1687.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1688" geometry={nodes.Final1688.geometry} material={nodes.Final1688.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1689" geometry={nodes.Final1689.geometry} material={nodes.Final1689.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1690" geometry={nodes.Final1690.geometry} material={nodes.Final1690.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1691" geometry={nodes.Final1691.geometry} material={nodes.Final1691.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1692" geometry={nodes.Final1692.geometry} material={nodes.Final1692.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1693" geometry={nodes.Final1693.geometry} material={nodes.Final1693.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1705" geometry={nodes.Final1705.geometry} material={materials['Material.6095']} position={[-196.32, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1718" geometry={nodes.Final1718.geometry} material={nodes.Final1718.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1719" geometry={nodes.Final1719.geometry} material={nodes.Final1719.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1720" geometry={nodes.Final1720.geometry} material={nodes.Final1720.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1721" geometry={nodes.Final1721.geometry} material={nodes.Final1721.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1722" geometry={nodes.Final1722.geometry} material={nodes.Final1722.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1723" geometry={nodes.Final1723.geometry} material={nodes.Final1723.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1724" geometry={nodes.Final1724.geometry} material={nodes.Final1724.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1725" geometry={nodes.Final1725.geometry} material={nodes.Final1725.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1726" geometry={nodes.Final1726.geometry} material={nodes.Final1726.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1727" geometry={nodes.Final1727.geometry} material={nodes.Final1727.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1728" geometry={nodes.Final1728.geometry} material={nodes.Final1728.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1729" geometry={nodes.Final1729.geometry} material={nodes.Final1729.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1730" geometry={nodes.Final1730.geometry} material={nodes.Final1730.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1731" geometry={nodes.Final1731.geometry} material={nodes.Final1731.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1732" geometry={nodes.Final1732.geometry} material={nodes.Final1732.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1733" geometry={nodes.Final1733.geometry} material={nodes.Final1733.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1734" geometry={nodes.Final1734.geometry} material={nodes.Final1734.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1735" geometry={nodes.Final1735.geometry} material={nodes.Final1735.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1736" geometry={nodes.Final1736.geometry} material={nodes.Final1736.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1737" geometry={nodes.Final1737.geometry} material={nodes.Final1737.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1738" geometry={nodes.Final1738.geometry} material={nodes.Final1738.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1739" geometry={nodes.Final1739.geometry} material={nodes.Final1739.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1740" geometry={nodes.Final1740.geometry} material={nodes.Final1740.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1741" geometry={nodes.Final1741.geometry} material={nodes.Final1741.material} position={[-194.66, 13.1, 306.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1815" geometry={nodes.Final1815.geometry} material={materials['Material.6096']} position={[-140.18, 13.1, 307.34]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1816" geometry={nodes.Final1816.geometry} material={materials['Material.6097']} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1817" geometry={nodes.Final1817.geometry} material={nodes.Final1817.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1818" geometry={nodes.Final1818.geometry} material={nodes.Final1818.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1819" geometry={nodes.Final1819.geometry} material={nodes.Final1819.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1820" geometry={nodes.Final1820.geometry} material={nodes.Final1820.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1821" geometry={nodes.Final1821.geometry} material={nodes.Final1821.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1822" geometry={nodes.Final1822.geometry} material={nodes.Final1822.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1823" geometry={nodes.Final1823.geometry} material={nodes.Final1823.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1824" geometry={nodes.Final1824.geometry} material={nodes.Final1824.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1825" geometry={nodes.Final1825.geometry} material={nodes.Final1825.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1826" geometry={nodes.Final1826.geometry} material={nodes.Final1826.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1827" geometry={nodes.Final1827.geometry} material={nodes.Final1827.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1828" geometry={nodes.Final1828.geometry} material={nodes.Final1828.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1829" geometry={nodes.Final1829.geometry} material={nodes.Final1829.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1830" geometry={nodes.Final1830.geometry} material={nodes.Final1830.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1831" geometry={nodes.Final1831.geometry} material={nodes.Final1831.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1832" geometry={nodes.Final1832.geometry} material={nodes.Final1832.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1833" geometry={nodes.Final1833.geometry} material={nodes.Final1833.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1834" geometry={nodes.Final1834.geometry} material={nodes.Final1834.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1835" geometry={nodes.Final1835.geometry} material={nodes.Final1835.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1836" geometry={nodes.Final1836.geometry} material={nodes.Final1836.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1837" geometry={nodes.Final1837.geometry} material={nodes.Final1837.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1838" geometry={nodes.Final1838.geometry} material={nodes.Final1838.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1839" geometry={nodes.Final1839.geometry} material={nodes.Final1839.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1840" geometry={nodes.Final1840.geometry} material={nodes.Final1840.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1841" geometry={nodes.Final1841.geometry} material={nodes.Final1841.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1842" geometry={nodes.Final1842.geometry} material={nodes.Final1842.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1843" geometry={nodes.Final1843.geometry} material={nodes.Final1843.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1844" geometry={nodes.Final1844.geometry} material={nodes.Final1844.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1845" geometry={nodes.Final1845.geometry} material={nodes.Final1845.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1846" geometry={nodes.Final1846.geometry} material={nodes.Final1846.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1847" geometry={nodes.Final1847.geometry} material={nodes.Final1847.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1848" geometry={nodes.Final1848.geometry} material={nodes.Final1848.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1849" geometry={nodes.Final1849.geometry} material={nodes.Final1849.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1850" geometry={nodes.Final1850.geometry} material={nodes.Final1850.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1851" geometry={nodes.Final1851.geometry} material={nodes.Final1851.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1852" geometry={nodes.Final1852.geometry} material={nodes.Final1852.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1853" geometry={nodes.Final1853.geometry} material={nodes.Final1853.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1854" geometry={nodes.Final1854.geometry} material={nodes.Final1854.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1855" geometry={nodes.Final1855.geometry} material={nodes.Final1855.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1856" geometry={nodes.Final1856.geometry} material={nodes.Final1856.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1857" geometry={nodes.Final1857.geometry} material={nodes.Final1857.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1858" geometry={nodes.Final1858.geometry} material={nodes.Final1858.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1859" geometry={nodes.Final1859.geometry} material={nodes.Final1859.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1860" geometry={nodes.Final1860.geometry} material={nodes.Final1860.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1861" geometry={nodes.Final1861.geometry} material={nodes.Final1861.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1862" geometry={nodes.Final1862.geometry} material={nodes.Final1862.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1863" geometry={nodes.Final1863.geometry} material={nodes.Final1863.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1864" geometry={nodes.Final1864.geometry} material={nodes.Final1864.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1865" geometry={nodes.Final1865.geometry} material={nodes.Final1865.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1866" geometry={nodes.Final1866.geometry} material={nodes.Final1866.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1867" geometry={nodes.Final1867.geometry} material={nodes.Final1867.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1868" geometry={nodes.Final1868.geometry} material={nodes.Final1868.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1869" geometry={nodes.Final1869.geometry} material={nodes.Final1869.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1870" geometry={nodes.Final1870.geometry} material={nodes.Final1870.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1871" geometry={nodes.Final1871.geometry} material={nodes.Final1871.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1872" geometry={nodes.Final1872.geometry} material={nodes.Final1872.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1873" geometry={nodes.Final1873.geometry} material={nodes.Final1873.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1874" geometry={nodes.Final1874.geometry} material={nodes.Final1874.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1875" geometry={nodes.Final1875.geometry} material={nodes.Final1875.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1876" geometry={nodes.Final1876.geometry} material={nodes.Final1876.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1877" geometry={nodes.Final1877.geometry} material={nodes.Final1877.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1878" geometry={nodes.Final1878.geometry} material={nodes.Final1878.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1879" geometry={nodes.Final1879.geometry} material={nodes.Final1879.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1880" geometry={nodes.Final1880.geometry} material={nodes.Final1880.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1881" geometry={nodes.Final1881.geometry} material={nodes.Final1881.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1882" geometry={nodes.Final1882.geometry} material={nodes.Final1882.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1883" geometry={nodes.Final1883.geometry} material={nodes.Final1883.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1884" geometry={nodes.Final1884.geometry} material={nodes.Final1884.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1885" geometry={nodes.Final1885.geometry} material={nodes.Final1885.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1886" geometry={nodes.Final1886.geometry} material={nodes.Final1886.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1887" geometry={nodes.Final1887.geometry} material={nodes.Final1887.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1888" geometry={nodes.Final1888.geometry} material={nodes.Final1888.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1889" geometry={nodes.Final1889.geometry} material={nodes.Final1889.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1890" geometry={nodes.Final1890.geometry} material={nodes.Final1890.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1891" geometry={nodes.Final1891.geometry} material={nodes.Final1891.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1892" geometry={nodes.Final1892.geometry} material={nodes.Final1892.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1893" geometry={nodes.Final1893.geometry} material={nodes.Final1893.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1894" geometry={nodes.Final1894.geometry} material={nodes.Final1894.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1895" geometry={nodes.Final1895.geometry} material={nodes.Final1895.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1896" geometry={nodes.Final1896.geometry} material={nodes.Final1896.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1897" geometry={nodes.Final1897.geometry} material={nodes.Final1897.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1898" geometry={nodes.Final1898.geometry} material={nodes.Final1898.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1899" geometry={nodes.Final1899.geometry} material={nodes.Final1899.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1900" geometry={nodes.Final1900.geometry} material={nodes.Final1900.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1901" geometry={nodes.Final1901.geometry} material={nodes.Final1901.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1902" geometry={nodes.Final1902.geometry} material={nodes.Final1902.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1903" geometry={nodes.Final1903.geometry} material={nodes.Final1903.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1904" geometry={nodes.Final1904.geometry} material={nodes.Final1904.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1905" geometry={nodes.Final1905.geometry} material={nodes.Final1905.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1906" geometry={nodes.Final1906.geometry} material={nodes.Final1906.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1907" geometry={nodes.Final1907.geometry} material={nodes.Final1907.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1908" geometry={nodes.Final1908.geometry} material={nodes.Final1908.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1909" geometry={nodes.Final1909.geometry} material={nodes.Final1909.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1910" geometry={nodes.Final1910.geometry} material={nodes.Final1910.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1911" geometry={nodes.Final1911.geometry} material={nodes.Final1911.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1912" geometry={nodes.Final1912.geometry} material={nodes.Final1912.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1913" geometry={nodes.Final1913.geometry} material={nodes.Final1913.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1914" geometry={nodes.Final1914.geometry} material={nodes.Final1914.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1915" geometry={nodes.Final1915.geometry} material={nodes.Final1915.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1916" geometry={nodes.Final1916.geometry} material={nodes.Final1916.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1917" geometry={nodes.Final1917.geometry} material={nodes.Final1917.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1918" geometry={nodes.Final1918.geometry} material={nodes.Final1918.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1919" geometry={nodes.Final1919.geometry} material={nodes.Final1919.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1920" geometry={nodes.Final1920.geometry} material={nodes.Final1920.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1921" geometry={nodes.Final1921.geometry} material={nodes.Final1921.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1922" geometry={nodes.Final1922.geometry} material={nodes.Final1922.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1923" geometry={nodes.Final1923.geometry} material={nodes.Final1923.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1924" geometry={nodes.Final1924.geometry} material={nodes.Final1924.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1925" geometry={nodes.Final1925.geometry} material={nodes.Final1925.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1926" geometry={nodes.Final1926.geometry} material={nodes.Final1926.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1927" geometry={nodes.Final1927.geometry} material={nodes.Final1927.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1928" geometry={nodes.Final1928.geometry} material={nodes.Final1928.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1929" geometry={nodes.Final1929.geometry} material={nodes.Final1929.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1930" geometry={nodes.Final1930.geometry} material={nodes.Final1930.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1931" geometry={nodes.Final1931.geometry} material={nodes.Final1931.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1932" geometry={nodes.Final1932.geometry} material={nodes.Final1932.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1933" geometry={nodes.Final1933.geometry} material={nodes.Final1933.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1934" geometry={nodes.Final1934.geometry} material={nodes.Final1934.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1935" geometry={nodes.Final1935.geometry} material={nodes.Final1935.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1936" geometry={nodes.Final1936.geometry} material={nodes.Final1936.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1937" geometry={nodes.Final1937.geometry} material={nodes.Final1937.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1938" geometry={nodes.Final1938.geometry} material={nodes.Final1938.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1939" geometry={nodes.Final1939.geometry} material={nodes.Final1939.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1940" geometry={nodes.Final1940.geometry} material={nodes.Final1940.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1941" geometry={nodes.Final1941.geometry} material={nodes.Final1941.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1942" geometry={nodes.Final1942.geometry} material={nodes.Final1942.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1943" geometry={nodes.Final1943.geometry} material={nodes.Final1943.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1944" geometry={nodes.Final1944.geometry} material={nodes.Final1944.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1945" geometry={nodes.Final1945.geometry} material={nodes.Final1945.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1946" geometry={nodes.Final1946.geometry} material={nodes.Final1946.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1947" geometry={nodes.Final1947.geometry} material={nodes.Final1947.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1948" geometry={nodes.Final1948.geometry} material={nodes.Final1948.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1949" geometry={nodes.Final1949.geometry} material={nodes.Final1949.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1950" geometry={nodes.Final1950.geometry} material={nodes.Final1950.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1951" geometry={nodes.Final1951.geometry} material={nodes.Final1951.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1952" geometry={nodes.Final1952.geometry} material={nodes.Final1952.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1953" geometry={nodes.Final1953.geometry} material={nodes.Final1953.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1954" geometry={nodes.Final1954.geometry} material={nodes.Final1954.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1955" geometry={nodes.Final1955.geometry} material={nodes.Final1955.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1956" geometry={nodes.Final1956.geometry} material={nodes.Final1956.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1957" geometry={nodes.Final1957.geometry} material={nodes.Final1957.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1958" geometry={nodes.Final1958.geometry} material={nodes.Final1958.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1959" geometry={nodes.Final1959.geometry} material={nodes.Final1959.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1960" geometry={nodes.Final1960.geometry} material={nodes.Final1960.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1961" geometry={nodes.Final1961.geometry} material={nodes.Final1961.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1962" geometry={nodes.Final1962.geometry} material={nodes.Final1962.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1963" geometry={nodes.Final1963.geometry} material={nodes.Final1963.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1964" geometry={nodes.Final1964.geometry} material={nodes.Final1964.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1965" geometry={nodes.Final1965.geometry} material={nodes.Final1965.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1966" geometry={nodes.Final1966.geometry} material={nodes.Final1966.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1967" geometry={nodes.Final1967.geometry} material={nodes.Final1967.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1968" geometry={nodes.Final1968.geometry} material={nodes.Final1968.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1969" geometry={nodes.Final1969.geometry} material={nodes.Final1969.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1970" geometry={nodes.Final1970.geometry} material={nodes.Final1970.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1971" geometry={nodes.Final1971.geometry} material={nodes.Final1971.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1972" geometry={nodes.Final1972.geometry} material={nodes.Final1972.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1973" geometry={nodes.Final1973.geometry} material={nodes.Final1973.material} position={[-141.97, 13.09, 308.75]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.55, 1.91, 1.82]} />
        <mesh name="Final1974" geometry={nodes.Final1974.geometry} material={nodes.Final1974.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1975" geometry={nodes.Final1975.geometry} material={nodes.Final1975.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1976" geometry={nodes.Final1976.geometry} material={nodes.Final1976.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1977" geometry={nodes.Final1977.geometry} material={nodes.Final1977.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1978" geometry={nodes.Final1978.geometry} material={nodes.Final1978.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1979" geometry={nodes.Final1979.geometry} material={nodes.Final1979.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1980" geometry={nodes.Final1980.geometry} material={nodes.Final1980.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1981" geometry={nodes.Final1981.geometry} material={nodes.Final1981.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1982" geometry={nodes.Final1982.geometry} material={nodes.Final1982.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1983" geometry={nodes.Final1983.geometry} material={nodes.Final1983.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1984" geometry={nodes.Final1984.geometry} material={nodes.Final1984.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1985" geometry={nodes.Final1985.geometry} material={nodes.Final1985.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1986" geometry={nodes.Final1986.geometry} material={nodes.Final1986.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1987" geometry={nodes.Final1987.geometry} material={nodes.Final1987.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1988" geometry={nodes.Final1988.geometry} material={nodes.Final1988.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1989" geometry={nodes.Final1989.geometry} material={nodes.Final1989.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1990" geometry={nodes.Final1990.geometry} material={nodes.Final1990.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1991" geometry={nodes.Final1991.geometry} material={nodes.Final1991.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1992" geometry={nodes.Final1992.geometry} material={nodes.Final1992.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1993" geometry={nodes.Final1993.geometry} material={nodes.Final1993.material} position={[-141.97, 13.09, 308.75]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.55, 1.91, 1.82]} />
        <mesh name="Final1994" geometry={nodes.Final1994.geometry} material={nodes.Final1994.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1995" geometry={nodes.Final1995.geometry} material={nodes.Final1995.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1996" geometry={nodes.Final1996.geometry} material={nodes.Final1996.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1997" geometry={nodes.Final1997.geometry} material={nodes.Final1997.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1998" geometry={nodes.Final1998.geometry} material={nodes.Final1998.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final1999" geometry={nodes.Final1999.geometry} material={nodes.Final1999.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2000" geometry={nodes.Final2000.geometry} material={nodes.Final2000.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2001" geometry={nodes.Final2001.geometry} material={nodes.Final2001.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2002" geometry={nodes.Final2002.geometry} material={nodes.Final2002.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2003" geometry={nodes.Final2003.geometry} material={nodes.Final2003.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2004" geometry={nodes.Final2004.geometry} material={nodes.Final2004.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2005" geometry={nodes.Final2005.geometry} material={nodes.Final2005.material} position={[-141.97, 13.09, 308.75]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.55, 1.91, 1.82]} />
        <mesh name="Final2006" geometry={nodes.Final2006.geometry} material={nodes.Final2006.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2007" geometry={nodes.Final2007.geometry} material={nodes.Final2007.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2008" geometry={nodes.Final2008.geometry} material={nodes.Final2008.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2009" geometry={nodes.Final2009.geometry} material={nodes.Final2009.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2010" geometry={nodes.Final2010.geometry} material={nodes.Final2010.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2011" geometry={nodes.Final2011.geometry} material={nodes.Final2011.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2012" geometry={nodes.Final2012.geometry} material={nodes.Final2012.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2013" geometry={nodes.Final2013.geometry} material={nodes.Final2013.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2014" geometry={nodes.Final2014.geometry} material={nodes.Final2014.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2015" geometry={nodes.Final2015.geometry} material={nodes.Final2015.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2016" geometry={nodes.Final2016.geometry} material={nodes.Final2016.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2017" geometry={nodes.Final2017.geometry} material={nodes.Final2017.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2018" geometry={nodes.Final2018.geometry} material={nodes.Final2018.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2019" geometry={nodes.Final2019.geometry} material={nodes.Final2019.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2020" geometry={nodes.Final2020.geometry} material={nodes.Final2020.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2021" geometry={nodes.Final2021.geometry} material={nodes.Final2021.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2022" geometry={nodes.Final2022.geometry} material={nodes.Final2022.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2023" geometry={nodes.Final2023.geometry} material={nodes.Final2023.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2024" geometry={nodes.Final2024.geometry} material={nodes.Final2024.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2025" geometry={nodes.Final2025.geometry} material={nodes.Final2025.material} position={[-139.81, 13.1, 276.07]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2026" geometry={nodes.Final2026.geometry} material={nodes.Final2026.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2027" geometry={nodes.Final2027.geometry} material={nodes.Final2027.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2028" geometry={nodes.Final2028.geometry} material={nodes.Final2028.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2029" geometry={nodes.Final2029.geometry} material={nodes.Final2029.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2030" geometry={nodes.Final2030.geometry} material={nodes.Final2030.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2031" geometry={nodes.Final2031.geometry} material={nodes.Final2031.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2032" geometry={nodes.Final2032.geometry} material={nodes.Final2032.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2033" geometry={nodes.Final2033.geometry} material={nodes.Final2033.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2034" geometry={nodes.Final2034.geometry} material={nodes.Final2034.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2035" geometry={nodes.Final2035.geometry} material={nodes.Final2035.material} position={[-139.81, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2036" geometry={nodes.Final2036.geometry} material={materials['Material.6098']} position={[-140.77, 13.1, 307.28]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2037" geometry={nodes.Final2037.geometry} material={nodes.Final2037.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2038" geometry={nodes.Final2038.geometry} material={materials['Material.6099']} position={[-140.77, 13.1, 307.26]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2039" geometry={nodes.Final2039.geometry} material={materials['Material.6100']} position={[-140.77, 13.1, 307.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2040" geometry={nodes.Final2040.geometry} material={nodes.Final2040.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2041" geometry={nodes.Final2041.geometry} material={nodes.Final2041.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2042" geometry={nodes.Final2042.geometry} material={nodes.Final2042.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2043" geometry={nodes.Final2043.geometry} material={materials['Material.6101']} position={[-140.77, 13.1, 307.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2044" geometry={nodes.Final2044.geometry} material={nodes.Final2044.material} position={[-141.18, 13.1, 308.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.65, 2.84, 1.94]} />
        <mesh name="Final2046" geometry={nodes.Final2046.geometry} material={nodes.Final2046.material} position={[-418.7, 13.84, -144.29]} scale={[2.02, 1.91, 2.65]} />
        <group name="Front_Cylinder" position={[-227.5, 17.54, -253.79]} rotation={[0, -Math.PI / 2, 0]} scale={[6.8, 10.6, 6.44]}>
          <mesh name="Cylinder355" geometry={nodes.Cylinder355.geometry} material={materials['Front Cylinder.001']} />
          <mesh name="Cylinder355_1" geometry={nodes.Cylinder355_1.geometry} material={materials['White Highlights.001']} />
        </group>
        <group name="Front_Entrance" position={[-234.38, 15.09, -253.73]} rotation={[0, -Math.PI / 2, 0]} scale={[2.94, 0.28, 0.21]}>
          <mesh name="Cube28648" geometry={nodes.Cube28648.geometry} material={materials['Light Brown Walls.001']} />
          <mesh name="Cube28648_1" geometry={nodes.Cube28648_1.geometry} material={materials['Pyramid.001']} />
        </group>
        <mesh name="Front_Gate" geometry={nodes.Front_Gate.geometry} material={materials['Wall.001']} position={[-327.2, 11.92, -321.34]} rotation={[0, Math.PI / 2, 0]} scale={[0.18, 1.38, 0.18]} />
        <mesh name="Front_Wall" geometry={nodes.Front_Wall.geometry} material={materials['Wall.001']} position={[-327.19, 11.54, -353.49]} rotation={[0, Math.PI / 2, 0]} scale={[26.5, 1, 0.09]} />
        <mesh name="Grand_Stairs" geometry={nodes.Grand_Stairs.geometry} material={materials['Material.552']} position={[-357.62, 12.32, 211.71]} rotation={[-Math.PI, 0, -Math.PI]} scale={[11.77, 0.65, 10.11]} />
        <mesh name="Ground_Floor_Railing" geometry={nodes.Ground_Floor_Railing.geometry} material={materials['Railing Material.001']} position={[-236.48, 28.88, -253.79]} rotation={[0, -Math.PI / 2, 0]} scale={[0.81, 0.56, 0.76]} />
        <mesh name="Layer0_001001" geometry={nodes.Layer0_001001.geometry} material={materials.dbl_Layer0_001} position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001002" geometry={nodes.Layer0_001002.geometry} material={materials.dbl_Layer0_001} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001004" geometry={nodes.Layer0_001004.geometry} material={materials.dbl_Layer0_001} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001005" geometry={nodes.Layer0_001005.geometry} material={materials.dbl_Layer0_001} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001006" geometry={nodes.Layer0_001006.geometry} material={materials.dbl_Layer0_001} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001007" geometry={nodes.Layer0_001007.geometry} material={materials['Material.6102']} position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001008" geometry={nodes.Layer0_001008.geometry} material={materials.dbl_Layer0_008} position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001009" geometry={nodes.Layer0_001009.geometry} material={materials.dbl_Layer0_003} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001010" geometry={nodes.Layer0_001010.geometry} material={materials.dbl_Layer0_008} position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001011" geometry={nodes.Layer0_001011.geometry} material={materials.dbl_Layer0_003} position={[-489.03, 12.86, -103.11]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001012" geometry={nodes.Layer0_001012.geometry} material={materials.dbl_Layer0_008} position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001013" geometry={nodes.Layer0_001013.geometry} material={materials.dbl_Layer0_011} position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001014" geometry={nodes.Layer0_001014.geometry} material={materials.dbl_Layer0_003} position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001015" geometry={nodes.Layer0_001015.geometry} material={materials.dbl_Layer0_003} position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001016" geometry={nodes.Layer0_001016.geometry} material={materials.dbl_Layer0_003} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001017" geometry={nodes.Layer0_001017.geometry} material={materials.dbl_Layer0_003} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001018" geometry={nodes.Layer0_001018.geometry} material={materials.dbl_Layer0_003} position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001019" geometry={nodes.Layer0_001019.geometry} material={materials.dbl_Layer0_003} position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001020" geometry={nodes.Layer0_001020.geometry} material={materials.dbl_Layer0_003} position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001021" geometry={nodes.Layer0_001021.geometry} material={materials.dbl_Layer0_003} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001022" geometry={nodes.Layer0_001022.geometry} material={materials.dbl_Layer0_003} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001025" geometry={nodes.Layer0_001025.geometry} material={materials.dbl_Layer0_004} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001026" geometry={nodes.Layer0_001026.geometry} material={materials.dbl_Layer0_008} position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001027" geometry={nodes.Layer0_001027.geometry} material={materials.dbl_Layer0_011} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001028" geometry={nodes.Layer0_001028.geometry} material={materials.dbl_Layer0_001} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001029" geometry={nodes.Layer0_001029.geometry} material={materials.dbl_Layer0_004} position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001030" geometry={nodes.Layer0_001030.geometry} material={materials.dbl_Layer0_004} position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001031" geometry={nodes.Layer0_001031.geometry} material={materials.dbl_Layer0_004} position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001033" geometry={nodes.Layer0_001033.geometry} material={materials.dbl_Layer0_005} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001035" geometry={nodes.Layer0_001035.geometry} material={materials.dbl_Layer0_005} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001036" geometry={nodes.Layer0_001036.geometry} material={materials.dbl_Layer0_005} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001037" geometry={nodes.Layer0_001037.geometry} material={materials.dbl_Layer0_005} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001038" geometry={nodes.Layer0_001038.geometry} material={materials.dbl_Layer0_005} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001039" geometry={nodes.Layer0_001039.geometry} material={materials.dbl_Layer0_005} position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001040" geometry={nodes.Layer0_001040.geometry} material={materials.dbl_Layer0_005} position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001041" geometry={nodes.Layer0_001041.geometry} material={materials.dbl_Layer0_005} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001042" geometry={nodes.Layer0_001042.geometry} material={materials.dbl_Layer0_005} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001043" geometry={nodes.Layer0_001043.geometry} material={materials.dbl_Layer0_005} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001044" geometry={nodes.Layer0_001044.geometry} material={materials.dbl_Layer0_005} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001045" geometry={nodes.Layer0_001045.geometry} material={materials.dbl_Layer0_005} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001046" geometry={nodes.Layer0_001046.geometry} material={materials.dbl_Layer0_005} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001047" geometry={nodes.Layer0_001047.geometry} material={materials.dbl_Layer0_005} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001048" geometry={nodes.Layer0_001048.geometry} material={materials.dbl_Layer0_005} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001049" geometry={nodes.Layer0_001049.geometry} material={materials.dbl_Layer0_005} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001050" geometry={nodes.Layer0_001050.geometry} material={materials.dbl_Layer0_005} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001051" geometry={nodes.Layer0_001051.geometry} material={materials.dbl_Layer0_006} position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001052" geometry={nodes.Layer0_001052.geometry} material={materials.dbl_Layer0_001} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001053" geometry={nodes.Layer0_001053.geometry} material={materials.dbl_Layer0_006} position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001054" geometry={nodes.Layer0_001054.geometry} material={materials.dbl_Layer0_006} position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001055" geometry={nodes.Layer0_001055.geometry} material={materials.dbl_Layer0_006} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001056" geometry={nodes.Layer0_001056.geometry} material={materials.dbl_Layer0_006} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001057" geometry={nodes.Layer0_001057.geometry} material={materials.dbl_Layer0_007} position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001058" geometry={nodes.Layer0_001058.geometry} material={materials.dbl_Layer0_007} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001059" geometry={nodes.Layer0_001059.geometry} material={materials.dbl_Layer0_008} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001060" geometry={nodes.Layer0_001060.geometry} material={materials.dbl_Layer0_009} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001061" geometry={nodes.Layer0_001061.geometry} material={materials.dbl_Layer0_009} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001062" geometry={nodes.Layer0_001062.geometry} material={materials.dbl_Layer0_009} position={[-494.83, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.01, 0, 0]} />
        <mesh name="Layer0_001063" geometry={nodes.Layer0_001063.geometry} material={materials.dbl_Layer0_010} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="Layer0_001064" geometry={nodes.Layer0_001064.geometry} material={materials.dbl_Layer0_011} position={[-492.51, 13.04, -104.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
        <mesh name="LootersTott002" geometry={nodes.LootersTott002.geometry} material={materials['Material.6104']} position={[-466.27, 12.19, 32.41]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott003" geometry={nodes.LootersTott003.geometry} material={materials['Material.6105']} position={[-466.27, 12.19, 32.41]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott005" geometry={nodes.LootersTott005.geometry} material={materials['Material.6107']} position={[-466.27, 12.19, 37.23]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott007" geometry={nodes.LootersTott007.geometry} material={materials['Material.6109']} position={[-466.3, 12.19, 22.73]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott008" geometry={nodes.LootersTott008.geometry} material={materials['Material.6110']} position={[-466.3, 12.19, 17.85]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott044" geometry={nodes.LootersTott044.geometry} material={nodes.LootersTott044.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott046" geometry={nodes.LootersTott046.geometry} material={nodes.LootersTott046.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott048" geometry={nodes.LootersTott048.geometry} material={nodes.LootersTott048.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott049" geometry={nodes.LootersTott049.geometry} material={nodes.LootersTott049.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott050" geometry={nodes.LootersTott050.geometry} material={nodes.LootersTott050.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott051" geometry={nodes.LootersTott051.geometry} material={nodes.LootersTott051.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott052" geometry={nodes.LootersTott052.geometry} material={nodes.LootersTott052.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott054" geometry={nodes.LootersTott054.geometry} material={nodes.LootersTott054.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott055" geometry={nodes.LootersTott055.geometry} material={materials['Material.6112']} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <group name="LootersTott057" position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]}>
          <mesh name="Cube28674" geometry={nodes.Cube28674.geometry} material={materials['Material.6114']} />
          <mesh name="Cube28674_1" geometry={nodes.Cube28674_1.geometry} material={materials['Material.6115']} />
        </group>
        <mesh name="LootersTott058" geometry={nodes.LootersTott058.geometry} material={materials['Material.6116']} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott062" geometry={nodes.LootersTott062.geometry} material={materials['Material.6117']} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott068" geometry={nodes.LootersTott068.geometry} material={materials['Material.6118']} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <group name="LootersTott072" position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]}>
          <mesh name="Cube28678" geometry={nodes.Cube28678.geometry} material={materials['Material.6119']} />
          <mesh name="Cube28678_1" geometry={nodes.Cube28678_1.geometry} material={materials['Material.6120']} />
        </group>
        <mesh name="LootersTott073" geometry={nodes.LootersTott073.geometry} material={materials['Material.6121']} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott078" geometry={nodes.LootersTott078.geometry} material={nodes.LootersTott078.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott079" geometry={nodes.LootersTott079.geometry} material={nodes.LootersTott079.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott083" geometry={nodes.LootersTott083.geometry} material={nodes.LootersTott083.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott086" geometry={nodes.LootersTott086.geometry} material={nodes.LootersTott086.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott087" geometry={nodes.LootersTott087.geometry} material={materials['Material.6122']} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott090" geometry={nodes.LootersTott090.geometry} material={nodes.LootersTott090.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott091" geometry={nodes.LootersTott091.geometry} material={nodes.LootersTott091.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott095" geometry={nodes.LootersTott095.geometry} material={materials['Material.6123']} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott097" geometry={nodes.LootersTott097.geometry} material={nodes.LootersTott097.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott098" geometry={nodes.LootersTott098.geometry} material={materials['Material.6124']} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <group name="LootersTott101" position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]}>
          <mesh name="Cube28690" geometry={nodes.Cube28690.geometry} material={materials['Material.6125']} />
          <mesh name="Cube28690_1" geometry={nodes.Cube28690_1.geometry} material={materials['Material.6126']} />
        </group>
        <mesh name="LootersTott102" geometry={nodes.LootersTott102.geometry} material={materials['Material.6127']} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott107" geometry={nodes.LootersTott107.geometry} material={nodes.LootersTott107.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott108" geometry={nodes.LootersTott108.geometry} material={nodes.LootersTott108.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott110" geometry={nodes.LootersTott110.geometry} material={materials['Material.6128']} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott111" geometry={nodes.LootersTott111.geometry} material={materials['Material.6129']} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott112" geometry={nodes.LootersTott112.geometry} material={nodes.LootersTott112.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott115" geometry={nodes.LootersTott115.geometry} material={nodes.LootersTott115.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott116" geometry={nodes.LootersTott116.geometry} material={materials['Material.6130']} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott118" geometry={nodes.LootersTott118.geometry} material={materials['Material.6131']} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott119" geometry={nodes.LootersTott119.geometry} material={nodes.LootersTott119.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott120" geometry={nodes.LootersTott120.geometry} material={nodes.LootersTott120.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott123" geometry={nodes.LootersTott123.geometry} material={materials['Material.6132']} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott127" geometry={nodes.LootersTott127.geometry} material={nodes.LootersTott127.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott693" geometry={nodes.LootersTott693.geometry} material={materials['Material.6133']} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott694" geometry={nodes.LootersTott694.geometry} material={nodes.LootersTott694.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott750" geometry={nodes.LootersTott750.geometry} material={materials['Material.6135']} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <group name="LootersTott752" position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]}>
          <mesh name="Cube28720" geometry={nodes.Cube28720.geometry} material={materials['Material.6136']} />
          <mesh name="Cube28720_1" geometry={nodes.Cube28720_1.geometry} material={materials['Material.6137']} />
        </group>
        <group name="LootersTott753" position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]}>
          <mesh name="Cube28721" geometry={nodes.Cube28721.geometry} material={materials['Material.6138']} />
          <mesh name="Cube28721_1" geometry={nodes.Cube28721_1.geometry} material={materials['Material.6139']} />
          <mesh name="Cube28721_2" geometry={nodes.Cube28721_2.geometry} material={materials['Material.6140']} />
          <mesh name="Cube28721_3" geometry={nodes.Cube28721_3.geometry} material={materials['Material.6141']} />
          <mesh name="Cube28721_4" geometry={nodes.Cube28721_4.geometry} material={materials['Material.6142']} />
        </group>
        <mesh name="LootersTott757" geometry={nodes.LootersTott757.geometry} material={materials['Material.6143']} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott760" geometry={nodes.LootersTott760.geometry} material={nodes.LootersTott760.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott778" geometry={nodes.LootersTott778.geometry} material={nodes.LootersTott778.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <group name="LootersTott793" position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]}>
          <mesh name="Cube28729" geometry={nodes.Cube28729.geometry} material={materials['Material.6144']} />
          <mesh name="Cube28729_1" geometry={nodes.Cube28729_1.geometry} material={materials['Material.6145']} />
        </group>
        <mesh name="LootersTott799" geometry={nodes.LootersTott799.geometry} material={nodes.LootersTott799.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott802" geometry={nodes.LootersTott802.geometry} material={nodes.LootersTott802.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <mesh name="LootersTott1389" geometry={nodes.LootersTott1389.geometry} material={nodes.LootersTott1389.material} position={[-468.54, 12.19, 27.59]} rotation={[0, Math.PI / 2, 0]} scale={[3.08, 1.31, 0.22]} />
        <group name="Main_Building" position={[-246.55, 15.28, 255.17]} rotation={[0, Math.PI / 2, 0]} scale={[1.16, 0.64, 0.02]}>
          <mesh name="Cube28738" geometry={nodes.Cube28738.geometry} material={materials['Blue Accents.001']} />
          <mesh name="Cube28738_1" geometry={nodes.Cube28738_1.geometry} material={materials['Grayish Concrete.001']} />
          <mesh name="Cube28738_2" geometry={nodes.Cube28738_2.geometry} material={materials['Shutters.001']} />
        </group>
        <group name="Main_Building001" position={[-236.48, 11.15, -253.79]} rotation={[0, -Math.PI / 2, 0]} scale={[0.81, 1.26, 0.76]}>
          <mesh name="Plane036_1" geometry={nodes.Plane036_1.geometry} material={materials['Dark Brown Walls.001']} />
          <mesh name="Plane036_2" geometry={nodes.Plane036_2.geometry} material={materials['Light Brown Walls.001']} />
          <mesh name="Plane036_3" geometry={nodes.Plane036_3.geometry} material={materials['Windows.005']} />
        </group>
        <group name="Mess" position={[-338.23, 12.6, -348.76]} rotation={[0, Math.PI / 2, 0]} scale={[14.63, 6.61, 7.05]}>
          <mesh name="Cube28739" geometry={nodes.Cube28739.geometry} material={materials['Roof.001']} />
          <mesh name="Cube28739_1" geometry={nodes.Cube28739_1.geometry} material={materials['Wall.001']} />
          <mesh name="Cube28739_2" geometry={nodes.Cube28739_2.geometry} material={materials['Windows.003']} />
        </group>
        <mesh name="Pipes" geometry={nodes.Pipes.geometry} material={materials['Shutters.001']} position={[-241.36, 15.82, 254.68]} rotation={[0, Math.PI / 2, 0]} scale={[0.64, 0.64, 0.02]} />
        <mesh name="Plane001" geometry={nodes.Plane001.geometry} material={materials['Material.6147']} position={[-352.4, 16.17, -178.74]} rotation={[0.24, 0, 0]} scale={[1.33, 0.26, 0.21]} />
        <mesh name="Plane002" geometry={nodes.Plane002.geometry} material={materials['Material.6147']} position={[-352.4, 12.5, -178.74]} rotation={[0.24, 0, 0]} scale={[1.33, 0.26, 0.21]} />
        <mesh name="Plane003" geometry={nodes.Plane003.geometry} material={materials['Material.6148']} position={[-318.95, 16.16, -194.21]} rotation={[Math.PI / 2, -1.33, Math.PI / 2]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane004" geometry={nodes.Plane004.geometry} material={materials['Material.6148']} position={[-319.1, 12.5, -199.25]} rotation={[Math.PI / 2, -1.33, Math.PI / 2]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane006" geometry={nodes.Plane006.geometry} material={materials['Material.6150']} position={[-346.45, 16.17, -151.29]} rotation={[0.24, 0, 0]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane007" geometry={nodes.Plane007.geometry} material={materials['Material.6150']} position={[-346.45, 12.5, -151.29]} rotation={[0.24, 0, 0]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane008" geometry={nodes.Plane008.geometry} material={materials['Material.6151']} position={[-303.61, 16.16, -105.75]} rotation={[Math.PI / 2, 1.33, -Math.PI / 2]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane009" geometry={nodes.Plane009.geometry} material={materials['Material.6151']} position={[-303.61, 12.5, -105.61]} rotation={[Math.PI / 2, 1.33, -Math.PI / 2]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane010" geometry={nodes.Plane010.geometry} material={materials['Material.6152']} position={[-365.6, 15.43, -168.32]} rotation={[0.24, 0, 0]} scale={[2.07, 0.25, 0.25]} />
        <mesh name="Plane011" geometry={nodes.Plane011.geometry} material={materials['Material.6153']} position={[-349.47, 16.17, -149.74]} rotation={[0.24, 0, 0]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane012" geometry={nodes.Plane012.geometry} material={materials['Material.6153']} position={[-349.47, 12.5, -149.74]} rotation={[0.24, 0, 0]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane013" geometry={nodes.Plane013.geometry} material={materials['Material.6152']} position={[-365.6, 14.91, -168.3]} rotation={[0.24, 0, 0]} scale={[2.07, 0.25, 0.25]} />
        <mesh name="Plane014" geometry={nodes.Plane014.geometry} material={materials['Material.6152']} position={[-364.16, 15.43, -168.32]} rotation={[0.24, 0, 0]} scale={[2.07, 0.25, 0.25]} />
        <mesh name="Plane015" geometry={nodes.Plane015.geometry} material={materials['Material.3728']} position={[-354.17, 36.54, -173.92]} scale={[1.06, 1.04, 0.98]} />
        <mesh name="Plane016" geometry={nodes.Plane016.geometry} material={materials['Material.6152']} position={[-358.91, 14.75, -168.32]} rotation={[0.24, 0, 0]} scale={[2.07, 0.25, 0.25]} />
        <mesh name="Plane017" geometry={nodes.Plane017.geometry} material={materials['Material.6154']} position={[-354.66, 16.17, -148.46]} rotation={[0.24, 0, 0]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane018" geometry={nodes.Plane018.geometry} material={materials['Material.3728']} position={[-354.17, 36.54, -173.92]} scale={[1.06, 1.04, 0.98]} />
        <mesh name="Plane019" geometry={nodes.Plane019.geometry} material={materials['Material.6152']} position={[-360.42, 15.49, -168.32]} rotation={[0.24, 0, 0]} scale={[2.07, 0.25, 0.25]} />
        <mesh name="Plane020" geometry={nodes.Plane020.geometry} material={materials['Material.6152']} position={[-364.16, 14.91, -168.32]} rotation={[0.24, 0, 0]} scale={[2.07, 0.25, 0.25]} />
        <mesh name="Plane021" geometry={nodes.Plane021.geometry} material={materials['Material.6152']} position={[-367.37, 18.28, -170.47]} rotation={[Math.PI / 2, -1.33, Math.PI / 2]} scale={[2.07, 0.25, 0.25]} />
        <mesh name="Plane022" geometry={nodes.Plane022.geometry} material={materials['Material.6154']} position={[-354.66, 12.5, -148.46]} rotation={[0.24, 0, 0]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane023" geometry={nodes.Plane023.geometry} material={materials['Material.3728']} position={[-354.17, 36.54, -177.7]} scale={[1.06, 1.04, 0.98]} />
        <mesh name="Plane024" geometry={nodes.Plane024.geometry} material={materials['Material.3728']} position={[-358.69, 36.54, -177.7]} rotation={[0, Math.PI / 2, 0]} scale={[1, 1.04, 1.04]} />
        <mesh name="Plane025" geometry={nodes.Plane025.geometry} material={materials['Material.6152']} position={[-360.51, 14.75, -168.32]} rotation={[0.24, 0, 0]} scale={[2.07, 0.25, 0.25]} />
        <mesh name="Plane026" geometry={nodes.Plane026.geometry} material={materials['Material.6152']} position={[-360.51, 15.49, -168.32]} rotation={[0.24, 0, 0]} scale={[2.07, 0.25, 0.25]} />
        <mesh name="Plane027" geometry={nodes.Plane027.geometry} material={materials['Material.6152']} position={[-358.91, 15.49, -168.32]} rotation={[0.24, 0, 0]} scale={[2.07, 0.25, 0.25]} />
        <mesh name="Plane028" geometry={nodes.Plane028.geometry} material={materials['Material.6152']} position={[-366.21, 14.3, -168.57]} rotation={[0.24, 0, 0]} scale={[1.76, 0.21, 0.21]} />
        <mesh name="Plane029" geometry={nodes.Plane029.geometry} material={materials['Material.6152']} position={[-364.26, 14.3, -168.57]} rotation={[0.24, 0, 0]} scale={[2, 0.21, 0.21]} />
        <mesh name="Plane030" geometry={nodes.Plane030.geometry} material={materials['Material.6152']} position={[-360.55, 13.82, -168.53]} rotation={[0.24, 0, 0]} scale={[2.07, 0.25, 0.25]} />
        <mesh name="Plane031" geometry={nodes.Plane031.geometry} material={materials['Material.6152']} position={[-359.16, 13.82, -168.53]} rotation={[0.24, 0, 0]} scale={[2.07, 0.25, 0.25]} />
        <mesh name="Plane032" geometry={nodes.Plane032.geometry} material={materials['Material.6152']} position={[-352.44, 14.75, -173.3]} rotation={[0.24, 0, 0]} scale={[2.07, 0.25, 0.25]} />
        <mesh name="Plane033" geometry={nodes.Plane033.geometry} material={materials['Material.6152']} position={[-353.75, 14.75, -173.32]} rotation={[0.24, 0, 0]} scale={[2.07, 0.25, 0.25]} />
        <mesh name="Plane034" geometry={nodes.Plane034.geometry} material={materials['Material.6155']} position={[-317.06, 16.21, -151.38]} rotation={[0.24, 0, 0]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane035" geometry={nodes.Plane035.geometry} material={materials['Material.6155']} position={[-317.06, 12.54, -151.38]} rotation={[0.24, 0, 0]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane036" geometry={nodes.Plane036.geometry} material={materials['Material.6156']} position={[-270.84, 16.17, -140.23]} rotation={[Math.PI / 2, 1.33, -Math.PI / 2]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane037" geometry={nodes.Plane037.geometry} material={materials['Material.6156']} position={[-270.84, 12.5, -140.23]} rotation={[Math.PI / 2, 1.33, -Math.PI / 2]} scale={[1.7, 0.26, 0.21]} />
        <group name="Plane038" position={[-238.2, 11.31, 71.17]} rotation={[0, -1.57, 0]} scale={[4.92, 5.85, 11.09]}>
          <mesh name="Plane141" geometry={nodes.Plane141.geometry} material={materials['Material.6157']} />
          <mesh name="Plane141_1" geometry={nodes.Plane141_1.geometry} material={materials['Material.862']} />
        </group>
        <mesh name="Plane039" geometry={nodes.Plane039.geometry} material={materials['Material.6158']} position={[-149.49, 14.62, 24.75]} rotation={[0, -Math.PI / 2, 0]} scale={[10.96, 3.28, 10.96]} />
        <mesh name="Plane041" geometry={nodes.Plane041.geometry} material={materials['Material.6160']} position={[-61.28, 12.76, 34.66]} rotation={[0, Math.PI / 2, 0]} scale={[2.51, 2.51, 1.16]} />
        <mesh name="Plane042" geometry={nodes.Plane042.geometry} material={materials['Material.6161']} position={[-65.1, 12.71, 33.52]} rotation={[0, Math.PI / 2, 0]} scale={2.51} />
        <mesh name="Plane043" geometry={nodes.Plane043.geometry} material={materials['Material.6162']} position={[-57.05, 10.23, 33.56]} rotation={[0, Math.PI / 2, 0]} scale={[1.7, 2.51, 2.51]} />
        <mesh name="Plane044" geometry={nodes.Plane044.geometry} material={materials['Material.6163']} position={[-60.89, 10.57, 15.12]} rotation={[0, Math.PI / 2, 0]} scale={2.51} />
        <mesh name="Plane045" geometry={nodes.Plane045.geometry} material={materials['Material.6164']} position={[-402.04, 16.17, -178.74]} rotation={[0.24, 0, 0]} scale={[1.33, 0.26, 0.21]} />
        <mesh name="Plane046" geometry={nodes.Plane046.geometry} material={materials['Material.6164']} position={[-402.04, 12.5, -178.74]} rotation={[0.24, 0, 0]} scale={[1.33, 0.26, 0.21]} />
        <mesh name="Plane047" geometry={nodes.Plane047.geometry} material={materials['Material.6165']} position={[-390.14, 16.16, -150.03]} rotation={[Math.PI / 2, 1.33, -Math.PI / 2]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane048" geometry={nodes.Plane048.geometry} material={materials['Material.6165']} position={[-390.14, 12.5, -144.99]} rotation={[Math.PI / 2, 1.33, -Math.PI / 2]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane049" geometry={nodes.Plane049.geometry} material={materials['Material.6166']} position={[-454.36, 16.17, -151.45]} rotation={[0.24, 0, 0]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane050" geometry={nodes.Plane050.geometry} material={materials['Material.6166']} position={[-454.36, 12.5, -151.45]} rotation={[0.24, 0, 0]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane051" geometry={nodes.Plane051.geometry} material={materials['Material.6167']} position={[-60.89, 17.57, 15.12]} rotation={[0, Math.PI / 2, 0]} scale={2.51} />
        <mesh name="Plane052" geometry={nodes.Plane052.geometry} material={materials['Material.6168']} position={[-57.31, 10.8, 30.5]} rotation={[-Math.PI / 2, 1.57, 0]} scale={[1.25, 1.25, 1.55]} />
        <mesh name="Plane053" geometry={nodes.Plane053.geometry} material={materials['Material.6169']} position={[-60.91, 15.67, 30.56]} rotation={[-Math.PI / 2, 1.57, 0]} scale={0.87} />
        <mesh name="Plane054" geometry={nodes.Plane054.geometry} material={materials['Material.6170']} position={[-62.23, 15.67, 30.56]} rotation={[-Math.PI / 2, 1.57, 0]} scale={0.87} />
        <mesh name="Plane083" geometry={nodes.Plane083.geometry} material={materials['Material.6182']} position={[-435.92, 16.17, -151.29]} rotation={[0.24, 0, 0]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane084" geometry={nodes.Plane084.geometry} material={materials['Material.6182']} position={[-435.92, 12.5, -151.29]} rotation={[0.24, 0, 0]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane085" geometry={nodes.Plane085.geometry} material={materials['Material.6183']} position={[-393.08, 16.16, -105.75]} rotation={[Math.PI / 2, 1.33, -Math.PI / 2]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane086" geometry={nodes.Plane086.geometry} material={materials['Material.6183']} position={[-393.08, 12.5, -105.61]} rotation={[Math.PI / 2, 1.33, -Math.PI / 2]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane087" geometry={nodes.Plane087.geometry} material={materials['Material.6184']} position={[-438.94, 16.17, -149.74]} rotation={[0.24, 0, 0]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane088" geometry={nodes.Plane088.geometry} material={materials['Material.6184']} position={[-438.94, 12.5, -149.74]} rotation={[0.24, 0, 0]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane089" geometry={nodes.Plane089.geometry} material={materials['Material.6185']} position={[-444.13, 16.17, -148.46]} rotation={[0.24, 0, 0]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Plane090" geometry={nodes.Plane090.geometry} material={materials['Material.6185']} position={[-444.13, 12.5, -148.46]} rotation={[0.24, 0, 0]} scale={[1.7, 0.26, 0.21]} />
        <mesh name="Railing" geometry={nodes.Railing.geometry} material={nodes.Railing.material} position={[-356.93, 15.57, 210.14]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.02, 0.05, 0.05]} />
        <group name="Roof_Access" position={[-236.83, 19.51, 261.12]} rotation={[0, Math.PI / 2, 0]} scale={[1.16, 0.64, 0.02]}>
          <mesh name="Cube28760" geometry={nodes.Cube28760.geometry} material={materials['Windows.004']} />
          <mesh name="Cube28760_1" geometry={nodes.Cube28760_1.geometry} material={materials['Grayish Concrete.001']} />
          <mesh name="Cube28760_2" geometry={nodes.Cube28760_2.geometry} material={materials['Shutters.001']} />
        </group>
        <mesh name="Side_" geometry={nodes.Side_.geometry} material={materials['Front Cylinder.001']} position={[-235.43, 12.88, -253.79]} rotation={[-Math.PI, -0.31, -Math.PI]} scale={[2.75, 0.34, 0.17]} />
        <mesh name="SR_Bhawan" geometry={nodes.SR_Bhawan.geometry} material={materials['Roof.001']} position={[-327.08, 11.66, -328.16]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.66, 0.94, 0.66]} />
        <mesh name="Stairs001" geometry={nodes.Stairs001.geometry} material={materials['Railing Material.001']} position={[-235.33, 11.72, -253.79]} rotation={[0, -Math.PI / 2, 0]} scale={[5.18, 8.07, 4.9]} />
        <mesh name="Text" geometry={nodes.Text.geometry} material={materials['Material.6186']} position={[-49.65, 16.5, 49.61]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.98, 0.98, 1.13]} />
        <mesh name="Text002" geometry={nodes.Text002.geometry} material={materials['Material.6188']} position={[-470.82, 10.99, -108.17]} rotation={[Math.PI / 2, 0.59, -Math.PI / 2]} scale={[1.47, 1.51, 1.59]} />
        <mesh name="Text003" geometry={nodes.Text003.geometry} material={nodes.Text003.material} position={[-230.54, 11.28, -253.79]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[0.58, 0.55, 0.76]} />
        <mesh name="Text004" geometry={nodes.Text004.geometry} material={nodes.Text004.material} position={[-464.93, 14.37, -166.88]} rotation={[Math.PI / 2, 0, 0.04]} scale={[0.76, 0.76, 1.13]} />
        <mesh name="Text005" geometry={nodes.Text005.geometry} material={materials['Material.6189']} position={[-240.23, 18.26, 218.4]} rotation={[Math.PI / 2, 0, Math.PI]} scale={[0.56, 0.63, 0.69]} />
        <mesh name="Text006" geometry={nodes.Text006.geometry} material={materials['Material.6190']} position={[-233.6, 14.23, 260.67]} rotation={[Math.PI / 2, 0, 0]} scale={0.4} />
        <mesh name="Text007" geometry={nodes.Text007.geometry} material={nodes.Text007.material} position={[-226.69, 14.53, -149.93]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={0.59} />
        <mesh name="Text008" geometry={nodes.Text008.geometry} material={materials['Material.778']} position={[-77.76, 13.07, 67.61]} rotation={[Math.PI / 2, 0, Math.PI]} scale={[1.17, 0.84, 0.84]} />
        <mesh name="Top_Floor_Railing" geometry={nodes.Top_Floor_Railing.geometry} material={materials['Railing Material.001']} position={[-236.48, 9.65, -253.79]} rotation={[0, -Math.PI / 2, 0]} scale={[0.81, 0.5, 0.76]} />
        <group name="Walkways_1" position={[-374.5, 12.05, -332.75]} rotation={[0, Math.PI / 2, 0]} scale={[3.59, 1.63, 1.02]}>
          <mesh name="Plane179" geometry={nodes.Plane179.geometry} material={materials['Poles.001']} />
          <mesh name="Plane179_1" geometry={nodes.Plane179_1.geometry} material={materials['Ceiling.001']} />
          <mesh name="Plane179_2" geometry={nodes.Plane179_2.geometry} material={materials['Wall.001']} />
        </group>
        <group name="Walkways_2" position={[-408.52, 12.05, -332.75]} rotation={[0, Math.PI / 2, 0]} scale={[3.59, 1.63, 1.02]}>
          <mesh name="Plane180" geometry={nodes.Plane180.geometry} material={materials['Poles.001']} />
          <mesh name="Plane180_1" geometry={nodes.Plane180_1.geometry} material={materials['Ceiling.001']} />
          <mesh name="Plane180_2" geometry={nodes.Plane180_2.geometry} material={materials['Wall.001']} />
        </group>
        <group name="Walkways_3" position={[-400.56, 12.05, -348.17]} rotation={[0, Math.PI / 2, 0]} scale={[3.59, 1.63, 1.02]}>
          <mesh name="Plane181" geometry={nodes.Plane181.geometry} material={materials['Poles.001']} />
          <mesh name="Plane181_1" geometry={nodes.Plane181_1.geometry} material={materials['Ceiling.001']} />
          <mesh name="Plane181_2" geometry={nodes.Plane181_2.geometry} material={materials['Wall.001']} />
        </group>
        <group name="Walkways_4" position={[-407.86, 12.05, -363.8]} rotation={[0, Math.PI / 2, 0]} scale={[3.59, 1.63, 1.02]}>
          <mesh name="Plane182" geometry={nodes.Plane182.geometry} material={materials['Poles.001']} />
          <mesh name="Plane182_1" geometry={nodes.Plane182_1.geometry} material={materials['Ceiling.001']} />
          <mesh name="Plane182_2" geometry={nodes.Plane182_2.geometry} material={materials['Wall.001']} />
        </group>
        <group name="Walkways_5" position={[-375.1, 12.05, -363.8]} rotation={[0, Math.PI / 2, 0]} scale={[3.59, 1.63, 1.02]}>
          <mesh name="Plane183" geometry={nodes.Plane183.geometry} material={materials['Poles.001']} />
          <mesh name="Plane183_1" geometry={nodes.Plane183_1.geometry} material={materials['Ceiling.001']} />
          <mesh name="Plane183_2" geometry={nodes.Plane183_2.geometry} material={materials['Wall.001']} />
        </group>
        <mesh name="Cube037" geometry={nodes.Cube037.geometry} material={materials['Material.6191']} position={[-162.67, 11.75, 84.37]} rotation={[0, Math.PI / 2, 0]} scale={[23.33, 2.42, 0.24]} />
        <mesh name="Cube044" geometry={nodes.Cube044.geometry} material={materials['Material.6192']} position={[-243.1, 10.36, 20.07]} scale={[15.24, 0.48, 16.98]} />
        <mesh name="Cube046" geometry={nodes.Cube046.geometry} material={materials['Material.6196']} position={[-291.51, 10.36, -62.8]} scale={[15.24, 0.48, 16.98]} />
        <mesh name="Cube047" geometry={nodes.Cube047.geometry} material={materials['Material.6200']} position={[-243.1, 10.36, -62.8]} scale={[15.24, 0.48, 16.98]} />
        <mesh name="Cube18537" geometry={nodes.Cube18537.geometry} material={materials['Material.6204']} position={[-291.51, 10.36, 178.47]} scale={[15.24, 0.48, 16.98]} />
        <mesh name="Cube18538" geometry={nodes.Cube18538.geometry} material={materials['Material.6208']} position={[-291.51, 10.36, 93.04]} scale={[15.24, 0.48, 16.98]} />
        <mesh name="Cube18539" geometry={nodes.Cube18539.geometry} material={materials['Material.771']} position={[-321.78, 9.97, 132.87]} scale={[1, 1, 17.17]} />
        <mesh name="Cube18540" geometry={nodes.Cube18540.geometry} material={materials['Material.6250']} position={[-308.59, 9.97, 132.87]} scale={[1, 1, 17.17]} />
        <mesh name="Cube18541" geometry={nodes.Cube18541.geometry} material={materials['Material.6257']} position={[-343.8, 9.97, 60]} rotation={[0, Math.PI / 2, 0]} scale={[1, 1, 23.93]} />
        <mesh name="Cube18542" geometry={nodes.Cube18542.geometry} material={materials['Material.6255']} position={[-343.8, 9.97, 165.43]} rotation={[0, Math.PI / 2, 0]} scale={[1, 1, 23.93]} />
        <mesh name="Cube18543" geometry={nodes.Cube18543.geometry} material={materials['Material.6254']} position={[-343.8, 9.97, 183.03]} rotation={[0, Math.PI / 2, 0]} scale={[1.14, 1, 23.93]} />
        <mesh name="Cube18544" geometry={nodes.Cube18544.geometry} material={materials['Material.6245']} position={[-282.07, 9.97, 58.99]} rotation={[0, Math.PI / 2, 0]} scale={[1, 1, 12.36]} />
        <mesh name="Cube18545" geometry={nodes.Cube18545.geometry} material={materials['Material.6243']} position={[-215.73, 9.97, 56.91]} rotation={[0, Math.PI / 2, 0]} scale={[1, 1, 16.89]} />
        <mesh name="Cube18546" geometry={nodes.Cube18546.geometry} material={materials['Material.6244']} position={[-282.07, 9.97, 39.5]} rotation={[0, Math.PI / 2, 0]} scale={[1, 1, 12.36]} />
        <mesh name="Cube18547" geometry={nodes.Cube18547.geometry} material={materials['Material.6212']} position={[-194.54, 10.36, 4.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[15.24, 0.48, 16.98]} />
        <mesh name="Cube18548" geometry={nodes.Cube18548.geometry} material={materials['Material.6216']} position={[-194.54, 10.36, -78.43]} rotation={[-Math.PI, 0, -Math.PI]} scale={[15.24, 0.48, 16.98]} />
        <mesh name="Cube18549" geometry={nodes.Cube18549.geometry} material={materials['Material.6251']} position={[-282.07, 9.97, 198.25]} rotation={[0, Math.PI / 2, 0]} scale={[1, 1, 12.36]} />
        <mesh name="Cube18550" geometry={nodes.Cube18550.geometry} material={materials['Material.769']} position={[-282.07, 11.67, 216.67]} rotation={[Math.PI / 2, 1.57, 0]} scale={[1.74, 0.22, 12.36]} />
        <mesh name="Cube18551" geometry={nodes.Cube18551.geometry} material={materials['Material.6242']} position={[-215.73, 9.97, 39.21]} rotation={[0, Math.PI / 2, 0]} scale={[1, 1, 16.89]} />
        <mesh name="Cube18552" geometry={nodes.Cube18552.geometry} material={materials['Material.6252']} position={[-282.07, 9.97, 215.56]} rotation={[0, Math.PI / 2, 0]} scale={[1, 1, 12.36]} />
        <mesh name="Cube18553" geometry={nodes.Cube18553.geometry} material={materials['Material.770']} position={[-269.84, 11.67, 228.96]} rotation={[-Math.PI, 0, -Math.PI / 2]} scale={[1.74, 0.22, 12.36]} />
        <mesh name="Cube18554" geometry={nodes.Cube18554.geometry} material={materials['Material.6220']} position={[-428.4, 10.36, 4.87]} rotation={[-Math.PI, 0, -Math.PI]} scale={[15.24, 0.48, 16.98]} />
        <mesh name="Cube18555" geometry={nodes.Cube18555.geometry} material={materials['Material.6224']} position={[-428.4, 10.36, -74.61]} rotation={[-Math.PI, 0, -Math.PI]} scale={[15.24, 0.48, 16.98]} />
        <mesh name="Cube18556" geometry={nodes.Cube18556.geometry} material={materials['Material.6228']} position={[-393.05, 11.58, 231.15]} rotation={[-Math.PI, 0, 0]} scale={[0.57, 1.12, 1]} />
        <group name="Cube18558" position={[-149.49, 12.49, -3.18]} rotation={[0, -Math.PI / 2, 0]} scale={[9.78, 2.13, 9.78]}>
          <mesh name="Cube28788" geometry={nodes.Cube28788.geometry} material={materials['Material.6230']} />
          <mesh name="Cube28788_1" geometry={nodes.Cube28788_1.geometry} material={materials['Material.6231']} />
        </group>
        <mesh name="Cube18559" geometry={nodes.Cube18559.geometry} material={materials['Material.6232']} position={[-161.47, 12.26, -4.88]} rotation={[0, -Math.PI / 2, 0]} scale={[0.03, 2.02, 0.03]} />
        <mesh name="Cylinder003" geometry={nodes.Cylinder003.geometry} material={materials['Material.6233']} position={[-161, 11.93, -3.18]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[3.36, 1.76, 2.69]} />
        <mesh name="Cube18560" geometry={nodes.Cube18560.geometry} material={materials['Material.6258']} position={[-395.57, 9.97, 132.87]} scale={[1, 1, 17.17]} />
        <mesh name="Cube18561" geometry={nodes.Cube18561.geometry} material={materials['Material.6256']} position={[-320.92, 9.97, 196.69]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1, 1, 12.36]} />
        <mesh name="Cube18562" geometry={nodes.Cube18562.geometry} material={materials['Material.6253']} position={[-394.75, 9.97, 196.69]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1, 1, 12.36]} />
        <mesh name="Cube18563" geometry={nodes.Cube18563.geometry} material={materials['Material.550']} position={[-415.69, 11.67, 70.64]} rotation={[-Math.PI, 0, -Math.PI / 2]} scale={[1.46, 0.22, 12.36]} />
        <mesh name="Cube18564" geometry={nodes.Cube18564.geometry} material={materials['Material.547']} position={[-425.6, 11.67, 223.16]} rotation={[Math.PI / 2, 1.57, 0]} scale={[1.46, 0.22, 12.36]} />
        <mesh name="Cube18565" geometry={nodes.Cube18565.geometry} material={materials['Material.549']} position={[-424.5, 11.67, 62.85]} rotation={[Math.PI / 2, 1.57, 0]} scale={[1.46, 0.22, 12.36]} />
        <mesh name="Cube18566" geometry={nodes.Cube18566.geometry} material={materials['Material.6246']} position={[-308.27, 9.97, -6.92]} scale={[1, 1, 17.17]} />
        <mesh name="Cube030" geometry={nodes.Cube030.geometry} material={materials['Material.6240']} position={[-430.85, 10.36, -122.77]} scale={[14.42, 0.48, 16.06]} />
        <mesh name="Cube031" geometry={nodes.Cube031.geometry} material={materials['Material.6241']} position={[-429.39, 11.68, -106.81]} rotation={[0, Math.PI / 2, 0]} scale={[0.54, 1.12, 0.95]} />
        <mesh name="Cube18567" geometry={nodes.Cube18567.geometry} material={materials['Material.668']} position={[-94.21, 9.97, 57.26]} rotation={[0, Math.PI / 2, 0]} scale={[1, 1, 16.89]} />
        <mesh name="Cube18568" geometry={nodes.Cube18568.geometry} material={materials['Material.667']} position={[-94.21, 9.97, 38.38]} rotation={[0, Math.PI / 2, 0]} scale={[1, 1, 16.89]} />
        <group name="Cylinder" position={[-171.81, 10.59, 47.7]} scale={[3.38, 1, 3.38]}>
          <mesh name="Cylinder_1" geometry={nodes.Cylinder_1.geometry} material={materials.Material} />
          <mesh name="Cylinder_2" geometry={nodes.Cylinder_2.geometry} material={materials['Material.539']} />
        </group>
        <mesh name="Cube18569" geometry={nodes.Cube18569.geometry} material={materials['Material.190']} position={[-480.77, 10.36, -72.61]} rotation={[-Math.PI, 0, -Math.PI]} scale={[11.06, 0.48, 12.32]} />
        <mesh name="Cube18984" geometry={nodes.Cube18984.geometry} material={materials['Material.548']} position={[-405.84, 11.67, 242.69]} rotation={[Math.PI / 2, 1.57, 0]} scale={[1.46, 0.22, 12.36]} />
        <group name="Cylinder004" position={[-263.61, 10.59, 47.7]} scale={[3.38, 1, 3.38]}>
          <mesh name="Cylinder005_1" geometry={nodes.Cylinder005_1.geometry} material={materials['Material.540']} />
          <mesh name="Cylinder005_2" geometry={nodes.Cylinder005_2.geometry} material={materials['Material.541']} />
        </group>
        <group name="Cylinder005" position={[-315.59, 10.59, 47.7]} scale={[3.38, 1, 3.38]}>
          <mesh name="Cylinder006_1" geometry={nodes.Cylinder006_1.geometry} material={materials['Material.542']} />
          <mesh name="Cylinder006_2" geometry={nodes.Cylinder006_2.geometry} material={materials['Material.543']} />
        </group>
        <group name="Cylinder006" position={[-403.73, 10.59, 48.77]} scale={[3.38, 1, 3.38]}>
          <mesh name="Cylinder007_1" geometry={nodes.Cylinder007_1.geometry} material={materials['Material.544']} />
          <mesh name="Cylinder007_2" geometry={nodes.Cylinder007_2.geometry} material={materials['Material.545']} />
        </group>
        <mesh name="Cube18985" geometry={nodes.Cube18985.geometry} material={materials['Material.248']} position={[-403.85, 12.3, 48.81]} scale={1.98} />
        <mesh name="Cube18986" geometry={nodes.Cube18986.geometry} material={materials['Material.725']} position={[-490.92, 11.67, 241.94]} rotation={[0, 0.89, Math.PI / 2]} scale={[1.92, 0.22, 1.19]} />
        <mesh name="Cube18987" geometry={nodes.Cube18987.geometry} material={materials['Material.726']} position={[-490.92, 11.67, 224.47]} rotation={[Math.PI, 0.83, -Math.PI / 2]} scale={[1.92, 0.22, 1.19]} />
        <group name="Cylinder007" position={[-264.5, 10.59, 206.69]} scale={[1.63, 1, 1.63]}>
          <mesh name="Cylinder001_1" geometry={nodes.Cylinder001_1.geometry} material={materials['Material.087']} />
          <mesh name="Cylinder001_2" geometry={nodes.Cylinder001_2.geometry} material={materials['Material.088']} />
        </group>
        <mesh name="Final003" geometry={nodes.Final003.geometry} material={materials['Material.093']} position={[-509.32, 13.84, -44.26]} scale={[3.96, 3.35, 3.96]}>
          <group name="Final007">
            <mesh name="Cube007_1" geometry={nodes.Cube007_1.geometry} material={materials['Material.173']} />
            <mesh name="Cube007_2" geometry={nodes.Cube007_2.geometry} material={materials['Material.094']} />
          </group>
          <group name="Final013">
            <mesh name="Cube004_1" geometry={nodes.Cube004_1.geometry} material={materials['Material.174']} />
            <mesh name="Cube004_2" geometry={nodes.Cube004_2.geometry} material={materials['Material.179']} />
          </group>
          <group name="Final016">
            <mesh name="Cube005_1" geometry={nodes.Cube005_1.geometry} material={materials['Material.180']} />
            <mesh name="Cube005_2" geometry={nodes.Cube005_2.geometry} material={materials['Material.182']} />
          </group>
          <mesh name="Final017" geometry={nodes.Final017.geometry} material={materials['Material.788']} position={[-0.33, 0, 0]} />
          <mesh name="Final021" geometry={nodes.Final021.geometry} material={materials['Material.832']} position={[64.2, -0.82, 26.8]} rotation={[Math.PI, 0, Math.PI]} scale={0.28} />
          <mesh name="Final032" geometry={nodes.Final032.geometry} material={materials['Material.183']} />
          <mesh name="Final033" geometry={nodes.Final033.geometry} material={materials['Material.833']} position={[64.2, -0.82, 26.8]} rotation={[Math.PI, 0, Math.PI]} scale={0.28} />
          <mesh name="Final034" geometry={nodes.Final034.geometry} material={materials['Material.366']} />
          <mesh name="Final035" geometry={nodes.Final035.geometry} material={materials['Material.834']} position={[64.2, -0.82, 26.8]} rotation={[Math.PI, 0, Math.PI]} scale={0.28} />
          <mesh name="Final036" geometry={nodes.Final036.geometry} material={materials['Material.367']} />
          <mesh name="Final037" geometry={nodes.Final037.geometry} material={materials['Material.537']} />
          <mesh name="Final038" geometry={nodes.Final038.geometry} material={materials['Material.835']} position={[64.2, -0.82, 26.8]} rotation={[Math.PI, 0, Math.PI]} scale={0.28} />
          <mesh name="Final039" geometry={nodes.Final039.geometry} material={materials['Material.836']} position={[64.2, -0.82, 27.37]} rotation={[Math.PI, 0, Math.PI]} scale={0.28} />
          <mesh name="Final040" geometry={nodes.Final040.geometry} material={materials['Material.837']} position={[64.2, -0.82, 27.37]} rotation={[Math.PI, 0, Math.PI]} scale={0.28} />
          <mesh name="Final041" geometry={nodes.Final041.geometry} material={materials['Material.538']} />
          <mesh name="Final042" geometry={nodes.Final042.geometry} material={materials['Material.838']} position={[64.2, -0.82, 27.37]} rotation={[Math.PI, 0, Math.PI]} scale={0.28} />
          <mesh name="Final043" geometry={nodes.Final043.geometry} material={materials['Material.839']} position={[64.2, -0.82, 27.37]} rotation={[Math.PI, 0, Math.PI]} scale={0.28} />
          <mesh name="Final044" geometry={nodes.Final044.geometry} material={materials['Material.840']} position={[64.2, -0.82, 28.08]} rotation={[Math.PI, 0, Math.PI]} scale={0.28} />
          <mesh name="Final045" geometry={nodes.Final045.geometry} material={materials['Material.841']} position={[64.2, -0.82, 28.08]} rotation={[Math.PI, 0, Math.PI]} scale={0.28} />
          <mesh name="Final046" geometry={nodes.Final046.geometry} material={materials['Material.842']} position={[64.2, -0.82, 28.08]} rotation={[Math.PI, 0, Math.PI]} scale={0.28} />
          <mesh name="Final047" geometry={nodes.Final047.geometry} material={materials['Material.843']} position={[64.2, -0.82, 28.08]} rotation={[Math.PI, 0, Math.PI]} scale={0.28} />
          <mesh name="Final048" geometry={nodes.Final048.geometry} material={materials['Material.844']} position={[64.2, -0.82, 28.86]} rotation={[Math.PI, 0, Math.PI]} scale={0.28} />
          <mesh name="Final049" geometry={nodes.Final049.geometry} material={materials['Material.845']} position={[64.2, -0.82, 28.86]} rotation={[Math.PI, 0, Math.PI]} scale={0.28} />
          <mesh name="Final050" geometry={nodes.Final050.geometry} material={materials['Material.847']} position={[64.2, -0.82, 28.86]} rotation={[Math.PI, 0, Math.PI]} scale={0.28} />
          <mesh name="Final051" geometry={nodes.Final051.geometry} material={materials['Material.848']} position={[64.2, -0.82, 28.86]} rotation={[Math.PI, 0, Math.PI]} scale={0.28} />
          <mesh name="Final052" geometry={nodes.Final052.geometry} material={materials['Material.763']} position={[54.8, 0, -47.22]} />
          <mesh name="Final053" geometry={nodes.Final053.geometry} material={materials['Material.764']} position={[54.8, 0, -47.22]} />
          <mesh name="Final054" geometry={nodes.Final054.geometry} material={materials['Material.765']} position={[54.8, 0, -47.22]} />
          <mesh name="Final055" geometry={nodes.Final055.geometry} material={materials['Material.766']} position={[54.47, 0, -47.22]} />
          <mesh name="Final057" geometry={nodes.Final057.geometry} material={materials['Material.767']} position={[54.8, 0, -48.88]} />
          <mesh name="Final058" geometry={nodes.Final058.geometry} material={materials['Material.768']} position={[54.8, 0, -48.88]} />
          <mesh name="Final059" geometry={nodes.Final059.geometry} material={materials['Material.772']} position={[54.8, 0, -48.88]} />
          <mesh name="Final060" geometry={nodes.Final060.geometry} material={materials['Material.773']} position={[54.47, 0, -48.88]} />
          <mesh name="Final067" geometry={nodes.Final067.geometry} material={materials['Material.546']} />
          <mesh name="Final082" geometry={nodes.Final082.geometry} material={materials['Material.666']} />
          <mesh name="Final095" geometry={nodes.Final095.geometry} material={materials['Material.787']} />
          <mesh name="Final125" geometry={nodes.Final125.geometry} material={materials['Material.789']} />
          <mesh name="Final128" geometry={nodes.Final128.geometry} material={materials['Material.790']} />
          <mesh name="Final155" geometry={nodes.Final155.geometry} material={materials['Material.791']} />
          <mesh name="Final169" geometry={nodes.Final169.geometry} material={materials['Material.792']} />
        </mesh>
        <mesh name="Text009" geometry={nodes.Text009.geometry} material={materials['Material.775']} position={[-508.23, 15.09, -32.56]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1.12, 0.63, 0.94]} />
        <mesh name="Cube020" geometry={nodes.Cube020.geometry} material={materials['Material.793']} position={[-431, 11.71, -175.96]} rotation={[0, Math.PI / 2, 0]} scale={[0.49, 0.63, 0.58]} />
        <mesh name="Cube021" geometry={nodes.Cube021.geometry} material={materials['Material.794']} position={[-420.49, 11.71, -194.59]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.49, 0.63, 0.58]} />
        <mesh name="Final020" geometry={nodes.Final020.geometry} material={materials['Material.795']} position={[-255.65, 13.44, 77.33]} rotation={[0, Math.PI / 2, 0]} scale={[3.46, 1.71, 2.02]} />
        <mesh name="Cube022" geometry={nodes.Cube022.geometry} material={materials['Material.853']} position={[-250.16, 11.41, 66.64]} scale={[0.08, 1, 8.52]} />
        <mesh name="Cube027" geometry={nodes.Cube027.geometry} material={materials['Material.830']} position={[-253.77, 11.9, 72.47]} scale={[0.07, 1.87, 0.07]} />
        <mesh name="Cube029" geometry={nodes.Cube029.geometry} material={materials['Material.796']} position={[-204.53, 11.59, 217.48]} scale={[4.79, 0.93, 0.14]} />
        <mesh name="Cube036" geometry={nodes.Cube036.geometry} material={materials['Material.797']} position={[-205.39, 10.59, 253.47]} scale={[20.51, 1.11, 30.57]} />
        <mesh name="Cube129" geometry={nodes.Cube129.geometry} material={materials['Material.800']} position={[-256.53, 18.07, 474.33]} scale={[310.95, 24.06, 5.26]} />
        <mesh name="Cube140" geometry={nodes.Cube140.geometry} material={materials['Material.798']} position={[-559.33, 18.07, 33.55]} rotation={[0, Math.PI / 2, 0]} scale={[321.26, 24.06, 5.26]} />
        <mesh name="Cube141" geometry={nodes.Cube141.geometry} material={materials['Material.801']} position={[119.75, 18.07, 38.6]} rotation={[0, Math.PI / 2, 0]} scale={[310.95, 24.06, 5.26]} />
        <mesh name="Cube152" geometry={nodes.Cube152.geometry} material={materials['Material.799']} position={[-256.53, 18.07, -392.73]} scale={[310.95, 24.06, 5.26]} />
        <group name="Cylinder008" position={[-361.2, 2.45, 4.66]} scale={0.13}>
          <mesh name="Cylinder002_1" geometry={nodes.Cylinder002_1.geometry} material={materials['Material.805']} />
          <mesh name="Cylinder002_2" geometry={nodes.Cylinder002_2.geometry} material={materials['Material.806']} />
        </group>
        <group name="Cylinder009" position={[-361.15, 1.33, 0.32]} scale={0.13}>
          <mesh name="Cylinder003_1" geometry={nodes.Cylinder003_1.geometry} material={materials['Material.807']} />
          <mesh name="Cylinder003_2" geometry={nodes.Cylinder003_2.geometry} material={materials['Material.808']} />
        </group>
        <group name="Cylinder010" position={[-347.98, 2.5, 4.66]} scale={0.13}>
          <mesh name="Cylinder004_1" geometry={nodes.Cylinder004_1.geometry} material={materials['Material.809']} />
          <mesh name="Cylinder004_2" geometry={nodes.Cylinder004_2.geometry} material={materials['Material.810']} />
        </group>
        <group name="Cylinder011" position={[-347.92, 1.3, 0.32]} scale={0.13}>
          <mesh name="Cylinder008_1" geometry={nodes.Cylinder008_1.geometry} material={materials['Material.811']} />
          <mesh name="Cylinder008_2" geometry={nodes.Cylinder008_2.geometry} material={materials['Material.812']} />
        </group>
        <group name="Cylinder012" position={[-361.2, 4.44, 13.03]} scale={0.13}>
          <mesh name="Cylinder009_1" geometry={nodes.Cylinder009_1.geometry} material={materials['Material.813']} />
          <mesh name="Cylinder009_2" geometry={nodes.Cylinder009_2.geometry} material={materials['Material.814']} />
        </group>
        <group name="Cylinder013" position={[-361.15, 3.39, 8.69]} scale={0.13}>
          <mesh name="Cylinder010_1" geometry={nodes.Cylinder010_1.geometry} material={materials['Material.815']} />
          <mesh name="Cylinder010_2" geometry={nodes.Cylinder010_2.geometry} material={materials['Material.816']} />
        </group>
        <group name="Cylinder014" position={[-347.98, 4.46, 13.03]} scale={0.13}>
          <mesh name="Cylinder011_1" geometry={nodes.Cylinder011_1.geometry} material={materials['Material.817']} />
          <mesh name="Cylinder011_2" geometry={nodes.Cylinder011_2.geometry} material={materials['Material.818']} />
        </group>
        <group name="Cylinder015" position={[-347.92, 3.58, 8.69]} scale={0.13}>
          <mesh name="Cylinder012_1" geometry={nodes.Cylinder012_1.geometry} material={materials['Material.819']} />
          <mesh name="Cylinder012_2" geometry={nodes.Cylinder012_2.geometry} material={materials['Material.820']} />
        </group>
        <group name="Cylinder016" position={[-361.2, 7.18, 22.08]} scale={0.13}>
          <mesh name="Cylinder013_1" geometry={nodes.Cylinder013_1.geometry} material={materials['Material.821']} />
          <mesh name="Cylinder013_2" geometry={nodes.Cylinder013_2.geometry} material={materials['Material.822']} />
        </group>
        <group name="Cylinder017" position={[-361.15, 6.07, 17.74]} scale={0.13}>
          <mesh name="Cylinder014_1" geometry={nodes.Cylinder014_1.geometry} material={materials['Material.823']} />
          <mesh name="Cylinder014_2" geometry={nodes.Cylinder014_2.geometry} material={materials['Material.824']} />
        </group>
        <group name="Cylinder018" position={[-347.98, 7.23, 22.08]} scale={0.13}>
          <mesh name="Cylinder015_1" geometry={nodes.Cylinder015_1.geometry} material={materials['Material.825']} />
          <mesh name="Cylinder015_2" geometry={nodes.Cylinder015_2.geometry} material={materials['Material.826']} />
        </group>
        <group name="Cylinder019" position={[-347.92, 5.62, 17.74]} scale={0.13}>
          <mesh name="Cylinder016_1" geometry={nodes.Cylinder016_1.geometry} material={materials['Material.827']} />
          <mesh name="Cylinder016_2" geometry={nodes.Cylinder016_2.geometry} material={materials['Material.828']} />
        </group>
        <mesh name="Cube155" geometry={nodes.Cube155.geometry} material={materials['Material.829']} position={[-107.09, 11.75, 61.28]} scale={[23.33, 2.42, 0.24]} />
        <mesh name="Cube160" geometry={nodes.Cube160.geometry} material={materials['Material.859']} position={[-251.76, 11.41, 79.57]} scale={[1.12, 1.12, 1.31]} />
        <mesh name="Cube025" geometry={nodes.Cube025.geometry} material={materials['Material.831']} position={[-177.39, 11.67, 306.96]} rotation={[-Math.PI, 0, -Math.PI / 2]} scale={[1.46, 0.22, 9.29]} />
        <mesh name="Cube026" geometry={nodes.Cube026.geometry} material={materials['Material.860']} position={[-256.56, 13.44, 74.87]} scale={[1, 0.33, 0.05]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1004" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1004'].geometry} material={materials['Material.849']} position={[-88.61, 12.95, 85.13]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.06]} />
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1005" position={[-88.61, 12.95, 85.13]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.06]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1002_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1002_1'].geometry} material={materials['Material.850']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1002_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1002_2'].geometry} material={materials['Material.851']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1006" position={[-88.61, 12.95, 85.13]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.06]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1003_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1003_1'].geometry} material={materials['Material.850']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1003_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1003_2'].geometry} material={materials['Material.851']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1007" position={[-88.61, 12.95, 85.13]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.06]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1004_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1004_1'].geometry} material={materials['Material.850']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1004_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1004_2'].geometry} material={materials['Material.851']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1008" position={[-88.61, 12.95, 85.13]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.06]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1005_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1005_1'].geometry} material={materials['Material.850']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1005_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1005_2'].geometry} material={materials['Material.851']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1009" position={[-88.61, 12.95, 85.13]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.06]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1006_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1006_1'].geometry} material={materials['Material.850']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1006_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1006_2'].geometry} material={materials['Material.851']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1010" position={[-88.61, 12.95, 85.13]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.06]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1007_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1007_1'].geometry} material={materials['Material.850']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1007_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1007_2'].geometry} material={materials['Material.851']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1011" position={[-88.61, 12.95, 85.13]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.06]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1008_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1008_1'].geometry} material={materials['Material.850']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1008_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1008_2'].geometry} material={materials['Material.851']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1012" position={[-88.61, 12.95, 85.13]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.06]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1009_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1009_1'].geometry} material={materials['Material.850']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1009_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1009_2'].geometry} material={materials['Material.851']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1013" position={[-88.61, 12.95, 85.13]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.06]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1010_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1010_1'].geometry} material={materials['Material.850']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1010_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1010_2'].geometry} material={materials['Material.851']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1014" position={[-88.61, 12.95, 85.13]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.06]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1011_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1011_1'].geometry} material={materials['Material.850']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1011_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1011_2'].geometry} material={materials['Material.851']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1015" position={[-88.54, 12.95, 85.13]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.06]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1012_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1012_1'].geometry} material={materials['Material.850']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1012_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1012_2'].geometry} material={materials['Material.851']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1016" position={[-88.54, 12.95, 85.13]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.06]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1013_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1013_1'].geometry} material={materials['Material.850']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1013_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1013_2'].geometry} material={materials['Material.851']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1017" position={[-88.61, 12.95, 85.13]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.06]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1014_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1014_1'].geometry} material={materials['Material.850']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1014_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1014_2'].geometry} material={materials['Material.851']} />
        </group>
        <mesh name="Cube028" geometry={nodes.Cube028.geometry} material={materials['Material.852']} position={[-117.06, 11.93, 80.7]} rotation={[0, -Math.PI / 2, 0]} scale={[1, 1, 0.72]} />
        <mesh name="Text010" geometry={nodes.Text010.geometry} material={materials['Material.863']} position={[-257.05, 13.27, 74.92]} rotation={[Math.PI / 2, 0, 0]} scale={0.41} />
        <group name="Cube162" position={[-413.08, 11.71, 252.18]} rotation={[-Math.PI, 0, -Math.PI]} scale={[8.43, 1.25, 1]}>
          <mesh name="Cube360" geometry={nodes.Cube360.geometry} material={materials['Material.001']} />
          <mesh name="Cube360_1" geometry={nodes.Cube360_1.geometry} material={materials['Material.002']} />
        </group>
        <group name="Cube163" position={[-408.32, 11.47, 256.72]} rotation={[-Math.PI, 0, -Math.PI]} scale={[4.73, 1, 1]}>
          <mesh name="Cube515_1" geometry={nodes.Cube515_1.geometry} material={materials['Material.003']} />
          <mesh name="Cube515_2" geometry={nodes.Cube515_2.geometry} material={materials['Material.004']} />
        </group>
        <group name="Cube174" position={[-405.68, 11.47, 260.01]} rotation={[-Math.PI, 0, -Math.PI]} scale={[4.73, 1, 1]}>
          <mesh name="Cube516_1" geometry={nodes.Cube516_1.geometry} material={materials['Material.005']} />
          <mesh name="Cube516_2" geometry={nodes.Cube516_2.geometry} material={materials['Material.006']} />
        </group>
        <group name="Cube175" position={[-403.02, 11.47, 262.74]} rotation={[-Math.PI, 0, -Math.PI]} scale={[4.73, 1, 1]}>
          <mesh name="Cube658" geometry={nodes.Cube658.geometry} material={materials['Material.007']} />
          <mesh name="Cube658_1" geometry={nodes.Cube658_1.geometry} material={materials['Material.008']} />
        </group>
        <mesh name="Cube176" geometry={nodes.Cube176.geometry} material={materials['Material.009']} position={[-404.18, 11.47, 265.46]} scale={[4.73, 1, 1]} />
        <mesh name="Cube177" geometry={nodes.Cube177.geometry} material={materials['Material.010']} position={[-406.7, 11.47, 267.46]} scale={[4.73, 1, 1]} />
        <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1018" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1018'].geometry} material={materials['Material.011']} position={[-414.6, 12.95, 266.36]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.11]} />
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1019" position={[-414.6, 12.95, 266.36]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.11]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1016_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1016_1'].geometry} material={materials['Material.012']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1016_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1016_2'].geometry} material={materials['Material.013']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1020" position={[-414.6, 12.95, 266.36]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.11]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1017_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1017_1'].geometry} material={materials['Material.012']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1017_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1017_2'].geometry} material={materials['Material.013']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1021" position={[-414.6, 12.95, 266.36]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.11]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1018_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1018_1'].geometry} material={materials['Material.012']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1018_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1018_2'].geometry} material={materials['Material.013']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1022" position={[-414.6, 12.95, 266.36]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.11]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1019_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1019_1'].geometry} material={materials['Material.012']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1019_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1019_2'].geometry} material={materials['Material.013']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1023" position={[-414.6, 12.95, 266.36]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.11]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1020_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1020_1'].geometry} material={materials['Material.012']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1020_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1020_2'].geometry} material={materials['Material.013']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1024" position={[-414.6, 12.95, 266.36]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.11]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1021_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1021_1'].geometry} material={materials['Material.012']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1021_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1021_2'].geometry} material={materials['Material.013']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1025" position={[-414.6, 12.95, 266.36]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.11]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1022_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1022_1'].geometry} material={materials['Material.012']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1022_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1022_2'].geometry} material={materials['Material.013']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1026" position={[-414.6, 12.95, 266.36]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.11]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1023_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1023_1'].geometry} material={materials['Material.012']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1023_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1023_2'].geometry} material={materials['Material.013']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1027" position={[-414.6, 12.95, 266.36]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.11]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1024_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1024_1'].geometry} material={materials['Material.012']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1024_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1024_2'].geometry} material={materials['Material.013']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1028" position={[-414.6, 12.95, 266.36]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.11]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1025_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1025_1'].geometry} material={materials['Material.012']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1025_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1025_2'].geometry} material={materials['Material.013']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1029" position={[-414.48, 12.95, 266.36]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.11]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1026_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1026_1'].geometry} material={materials['Material.012']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1026_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1026_2'].geometry} material={materials['Material.013']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1030" position={[-414.48, 12.95, 266.36]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.11]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1027_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1027_1'].geometry} material={materials['Material.012']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1027_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1027_2'].geometry} material={materials['Material.013']} />
        </group>
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1037" position={[-414.6, 12.95, 266.36]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.11]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1028_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1028_1'].geometry} material={materials['Material.012']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1028_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1028_2'].geometry} material={materials['Material.013']} />
        </group>
        <mesh name="Cube178" geometry={nodes.Cube178.geometry} material={materials['Material.014']} position={[-463.39, 11.93, 261.94]} rotation={[0, -Math.PI / 2, 0]} scale={[1, 1, 1.24]} />
        <mesh name="Cube179" geometry={nodes.Cube179.geometry} material={materials['Material.015']} position={[-434.4, 11.67, 242.69]} rotation={[Math.PI / 2, 1.57, 0]} scale={[1.46, 0.22, 12.36]} />
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1038" position={[-414.6, 9.45, 266.36]} rotation={[0, -Math.PI / 2, 0]} scale={[0.09, 0.08, 0.11]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1029_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1029_1'].geometry} material={materials['Material.016']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1029_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1029_2'].geometry} material={materials['Material.017']} />
        </group>
        <mesh name="Cube180" geometry={nodes.Cube180.geometry} material={materials['Material.018']} position={[-411.59, 9.97, 2.41]} scale={[1, 1, 20.26]} />
        <mesh name="Cube447" geometry={nodes.Cube447.geometry} material={materials['Material.744']} position={[-410.7, 12.02, 242.69]} rotation={[Math.PI / 2, 1.57, 0]} scale={[1.46, 0.28, 2.44]} />
        <mesh name="Cube446" geometry={nodes.Cube446.geometry} material={materials['Material.745']} position={[-414.44, 12.02, 242.69]} rotation={[Math.PI / 2, 1.57, 0]} scale={[1.46, 0.28, 2.65]} />
        <mesh name="Cube450" geometry={nodes.Cube450.geometry} material={materials['Material.750']} position={[-415.69, 11.67, -25.75]} rotation={[-Math.PI, 0, -Math.PI / 2]} scale={[1.46, 0.22, 12.36]} />
        <mesh name="Cube451" geometry={nodes.Cube451.geometry} material={materials['Material.751']} position={[-415.6, 12.02, 128.38]} rotation={[-Math.PI, 0, -Math.PI / 2]} scale={[1.75, 0.28, 3.52]} />
        <mesh name="Cube452" geometry={nodes.Cube452.geometry} material={materials['Material.752']} position={[-415.6, 12.02, 133.78]} rotation={[-Math.PI, 0, -Math.PI / 2]} scale={[1.75, 0.28, 3.83]} />
        <mesh name="Cube454" geometry={nodes.Cube454.geometry} material={materials['Material.249']} position={[-315.62, 12.3, 47.67]} scale={1.98} />
        <group name="Cube457" position={[-296.82, 14.43, -214.53]} scale={[4.63, 3.9, 6.98]}>
          <mesh name="Cube947" geometry={nodes.Cube947.geometry} material={materials['Material.758']} />
          <mesh name="Cube947_1" geometry={nodes.Cube947_1.geometry} material={materials['Material.759']} />
          <mesh name="Cube947_2" geometry={nodes.Cube947_2.geometry} material={materials['Material.760']} />
          <mesh name="Cube947_3" geometry={nodes.Cube947_3.geometry} material={materials['Material.761']} />
        </group>
        <mesh name="Text013" geometry={nodes.Text013.geometry} material={materials['Material.762']} position={[-292.19, 16.05, -210.24]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1.72, 2.21, 1.15]} />
        <mesh name="Cube458" geometry={nodes.Cube458.geometry} material={materials['Material.777']} position={[-162.67, 11.81, -262.26]} rotation={[0, Math.PI / 2, 0]} scale={[23.33, 1.81, 0.24]} />
        <mesh name="Cube459" geometry={nodes.Cube459.geometry} material={materials['Material.779']} position={[-221.04, 10.78, 337.08]} rotation={[0, Math.PI / 2, 0]} scale={[0.57, 1.12, 1]} />
        <mesh name="Cube460" geometry={nodes.Cube460.geometry} material={materials['Material.780']} position={[-216.21, 10.78, 300.86]} rotation={[0, Math.PI / 2, 0]} scale={[0.57, 1.12, 1]} />
        <group name="Cube461" position={[-248.31, 11.19, 317.82]} scale={[10.34, 1, 3.38]}>
          <mesh name="Cube016_1" geometry={nodes.Cube016_1.geometry} material={materials['Material.781']} />
          <mesh name="Cube016_2" geometry={nodes.Cube016_2.geometry} material={materials['Material.782']} />
        </group>
        <mesh name="Cube462" geometry={nodes.Cube462.geometry} material={materials['Material.197']} position={[-269.84, 11.67, 332.21]} rotation={[-Math.PI, 0, -Math.PI / 2]} scale={[1.74, 0.22, 12.36]} />
        <mesh name="Cube463" geometry={nodes.Cube463.geometry} material={materials['Material.198']} position={[-282.07, 11.67, 404.14]} rotation={[Math.PI / 2, 1.57, 0]} scale={[1.74, 0.22, 12.36]} />
        <group name="Cylinder020" position={[-264.58, 10.59, 409.61]} scale={[1.83, 1, 1.83]}>
          <mesh name="Cylinder017_1" geometry={nodes.Cylinder017_1.geometry} material={materials['Material.199']} />
          <mesh name="Cylinder017_2" geometry={nodes.Cylinder017_2.geometry} material={materials['Material.200']} />
        </group>
        <mesh name="Cube466" geometry={nodes.Cube466.geometry} material={materials['Material.205']} position={[-282.07, 11.67, 414.19]} rotation={[Math.PI / 2, 1.57, 0]} scale={[1.74, 0.22, 12.36]} />
        <mesh name="Cube467" geometry={nodes.Cube467.geometry} material={materials['Material.206']} position={[-258.5, 11.67, 332.21]} rotation={[-Math.PI, 0, -Math.PI / 2]} scale={[1.74, 0.22, 12.36]} />
        <mesh name="Cube468" geometry={nodes.Cube468.geometry} material={materials['Material.207']} position={[-218.42, 11.67, 404.14]} rotation={[Math.PI / 2, 1.57, 0]} scale={[1.74, 0.22, 12.36]} />
        <mesh name="Cube469" geometry={nodes.Cube469.geometry} material={materials['Material.208']} position={[-269.84, 11.67, 426.38]} rotation={[-Math.PI, 0, -Math.PI / 2]} scale={[1.74, 0.22, 12.36]} />
        <mesh name="Cube470" geometry={nodes.Cube470.geometry} material={materials['Material.210']} position={[-259.38, 11.67, 426.38]} rotation={[-Math.PI, 0, -Math.PI / 2]} scale={[1.74, 0.22, 12.36]} />
        <mesh name="Cube471" geometry={nodes.Cube471.geometry} material={materials['Material.211']} position={[-219.13, 11.67, 414.42]} rotation={[Math.PI / 2, 1.57, 0]} scale={[1.74, 0.22, 12.36]} />
        <group name="Cube501" position={[-269.71, 13.97, 311.69]} rotation={[-Math.PI, 0, -Math.PI / 2]} scale={[2.28, 0.06, 0.05]}>
          <mesh name="Cube056_1" geometry={nodes.Cube056_1.geometry} material={materials['Material.241']} />
          <mesh name="Cube056_2" geometry={nodes.Cube056_2.geometry} material={materials['Material.228']} />
          <mesh name="Cube056_3" geometry={nodes.Cube056_3.geometry} material={materials['Material.229']} />
          <mesh name="Cube056_4" geometry={nodes.Cube056_4.geometry} material={materials['Material.230']} />
          <mesh name="Cube056_5" geometry={nodes.Cube056_5.geometry} material={materials['Material.231']} />
          <mesh name="Cube056_6" geometry={nodes.Cube056_6.geometry} material={materials['Material.232']} />
          <mesh name="Cube056_7" geometry={nodes.Cube056_7.geometry} material={materials['Material.233']} />
          <mesh name="Cube056_8" geometry={nodes.Cube056_8.geometry} material={materials['Material.234']} />
          <mesh name="Cube056_9" geometry={nodes.Cube056_9.geometry} material={materials['Material.235']} />
          <mesh name="Cube056_10" geometry={nodes.Cube056_10.geometry} material={materials['Material.236']} />
          <mesh name="Cube056_11" geometry={nodes.Cube056_11.geometry} material={materials['Material.237']} />
          <mesh name="Cube056_12" geometry={nodes.Cube056_12.geometry} material={materials['Material.238']} />
          <mesh name="Cube056_13" geometry={nodes.Cube056_13.geometry} material={materials['Material.239']} />
          <mesh name="Cube056_14" geometry={nodes.Cube056_14.geometry} material={materials['Material.240']} />
          <mesh name="Cube056_15" geometry={nodes.Cube056_15.geometry} material={materials['Material.242']} />
          <mesh name="Cube056_16" geometry={nodes.Cube056_16.geometry} material={materials['Material.213']} />
          <mesh name="Cube056_17" geometry={nodes.Cube056_17.geometry} material={materials['Material.214']} />
          <mesh name="Cube056_18" geometry={nodes.Cube056_18.geometry} material={materials['Material.215']} />
          <mesh name="Cube056_19" geometry={nodes.Cube056_19.geometry} material={materials['Material.216']} />
          <mesh name="Cube056_20" geometry={nodes.Cube056_20.geometry} material={materials['Material.217']} />
          <mesh name="Cube056_21" geometry={nodes.Cube056_21.geometry} material={materials['Material.218']} />
          <mesh name="Cube056_22" geometry={nodes.Cube056_22.geometry} material={materials['Material.219']} />
          <mesh name="Cube056_23" geometry={nodes.Cube056_23.geometry} material={materials['Material.220']} />
          <mesh name="Cube056_24" geometry={nodes.Cube056_24.geometry} material={materials['Material.221']} />
          <mesh name="Cube056_25" geometry={nodes.Cube056_25.geometry} material={materials['Material.222']} />
          <mesh name="Cube056_26" geometry={nodes.Cube056_26.geometry} material={materials['Material.223']} />
          <mesh name="Cube056_27" geometry={nodes.Cube056_27.geometry} material={materials['Material.224']} />
          <mesh name="Cube056_28" geometry={nodes.Cube056_28.geometry} material={materials['Material.225']} />
          <mesh name="Cube056_29" geometry={nodes.Cube056_29.geometry} material={materials['Material.226']} />
          <mesh name="Cube056_30" geometry={nodes.Cube056_30.geometry} material={materials['Material.227']} />
        </group>
        <mesh name="Cube472" geometry={nodes.Cube472.geometry} material={materials['Material.243']} position={[-291.85, 9.97, -323.58]} rotation={[0, Math.PI / 2, 0]} scale={[1.48, 1, 13.97]} />
        <mesh name="Cube474" geometry={nodes.Cube474.geometry} material={materials['Material.245']} position={[-305.44, 10.36, -347.34]} scale={[21.9, 0.48, 16.81]} />
        <mesh name="Cube475" geometry={nodes.Cube475.geometry} material={materials['Material.246']} position={[-303.22, 11.68, -331.07]} rotation={[0, Math.PI / 2, 0]} scale={[0.54, 1.12, 1.44]} />
        <group name="Cylinder021" position={[-401.35, 10.59, -101.7]} scale={[2.76, 1, 2.76]}>
          <mesh name="Cylinder018_1" geometry={nodes.Cylinder018_1.geometry} material={materials['Material.250']} />
          <mesh name="Cylinder018_2" geometry={nodes.Cylinder018_2.geometry} material={materials['Material.251']} />
        </group>
        <group name="Cylinder022" position={[-315, 10.59, -101.7]} scale={[2.76, 1, 2.76]}>
          <mesh name="Cylinder019_1" geometry={nodes.Cylinder019_1.geometry} material={materials['Material.253']} />
          <mesh name="Cylinder019_2" geometry={nodes.Cylinder019_2.geometry} material={materials['Material.254']} />
        </group>
        <mesh name="Plane" geometry={nodes.Plane.geometry} material={materials['Material.746']} position={[-353.58, 10.98, 36.69]} scale={[1.61, 1, 1]} />
        <group name="16020_Cargo_Plane-Medium_Size-USA_v1050" position={[-44.82, 17.86, 74.69]} rotation={[0, -Math.PI / 2, 0]} scale={[0.04, 0.11, 0.04]}>
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1030_1" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1030_1'].geometry} material={materials['Material.949']} />
          <mesh name="16020_Cargo_Plane-Medium_Size-USA_v1030_2" geometry={nodes['16020_Cargo_Plane-Medium_Size-USA_v1030_2'].geometry} material={materials['Material.950']} />
        </group>
        <group name="BB_+_Tennis_Court" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane230" geometry={nodes.Plane230.geometry} material={materials['Material.212']} />
          <mesh name="Plane230_1" geometry={nodes.Plane230_1.geometry} material={materials['Material.255']} />
          <mesh name="Plane230_2" geometry={nodes.Plane230_2.geometry} material={materials['Material.256']} />
        </group>
        <mesh name="Volley_Ball_Court" geometry={nodes.Volley_Ball_Court.geometry} material={materials['Material.890']} position={[-189, 11.8, 131.53]} rotation={[0, -1.57, 0]} scale={5.99} />
        <mesh name="Soccer_Field" geometry={nodes.Soccer_Field.geometry} material={materials['Material.899']} position={[-233.21, 12.03, 129.7]} rotation={[0, -1.57, 0]} scale={21.34} />
        <group name="BB_+_Tennis_Court001" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane006_1" geometry={nodes.Plane006_1.geometry} material={materials['Material.257']} />
          <mesh name="Plane006_2" geometry={nodes.Plane006_2.geometry} material={materials['Material.258']} />
        </group>
        <group name="BB_+_Tennis_Court002" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane007_1" geometry={nodes.Plane007_1.geometry} material={materials['Material.257']} />
          <mesh name="Plane007_2" geometry={nodes.Plane007_2.geometry} material={materials['Material.258']} />
        </group>
        <group name="BB_+_Tennis_Court003" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane008_1" geometry={nodes.Plane008_1.geometry} material={materials['Material.257']} />
          <mesh name="Plane008_2" geometry={nodes.Plane008_2.geometry} material={materials['Material.258']} />
        </group>
        <group name="BB_+_Tennis_Court004" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane009_1" geometry={nodes.Plane009_1.geometry} material={materials['Material.257']} />
          <mesh name="Plane009_2" geometry={nodes.Plane009_2.geometry} material={materials['Material.258']} />
        </group>
        <group name="BB_+_Tennis_Court005" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane010_1" geometry={nodes.Plane010_1.geometry} material={materials['Material.257']} />
          <mesh name="Plane010_2" geometry={nodes.Plane010_2.geometry} material={materials['Material.258']} />
        </group>
        <group name="BB_+_Tennis_Court006" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane011_1" geometry={nodes.Plane011_1.geometry} material={materials['Material.257']} />
          <mesh name="Plane011_2" geometry={nodes.Plane011_2.geometry} material={materials['Material.258']} />
        </group>
        <mesh name="BB_+_Tennis_Court007" geometry={nodes['BB_+_Tennis_Court007'].geometry} material={materials['Material.257']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <group name="BB_+_Tennis_Court008" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane013_1" geometry={nodes.Plane013_1.geometry} material={materials['Material.257']} />
          <mesh name="Plane013_2" geometry={nodes.Plane013_2.geometry} material={materials['Material.258']} />
        </group>
        <group name="BB_+_Tennis_Court009" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane014_1" geometry={nodes.Plane014_1.geometry} material={materials['Material.257']} />
          <mesh name="Plane014_2" geometry={nodes.Plane014_2.geometry} material={materials['Material.258']} />
        </group>
        <mesh name="BB_+_Tennis_Court010" geometry={nodes['BB_+_Tennis_Court010'].geometry} material={materials['Material.257']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court011" geometry={nodes['BB_+_Tennis_Court011'].geometry} material={materials['Material.257']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <group name="BB_+_Tennis_Court012" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane017_1" geometry={nodes.Plane017_1.geometry} material={materials['Material.257']} />
          <mesh name="Plane017_2" geometry={nodes.Plane017_2.geometry} material={materials['Material.258']} />
        </group>
        <group name="BB_+_Tennis_Court013" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane019_1" geometry={nodes.Plane019_1.geometry} material={materials['Material.257']} />
          <mesh name="Plane019_2" geometry={nodes.Plane019_2.geometry} material={materials['Material.258']} />
        </group>
        <mesh name="BB_+_Tennis_Court014" geometry={nodes['BB_+_Tennis_Court014'].geometry} material={materials['Material.257']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court015" geometry={nodes['BB_+_Tennis_Court015'].geometry} material={materials['Material.257']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court016" geometry={nodes['BB_+_Tennis_Court016'].geometry} material={materials['Material.257']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <group name="BB_+_Tennis_Court017" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane024_1" geometry={nodes.Plane024_1.geometry} material={materials['Material.257']} />
          <mesh name="Plane024_2" geometry={nodes.Plane024_2.geometry} material={materials['Material.258']} />
        </group>
        <mesh name="BB_+_Tennis_Court018" geometry={nodes['BB_+_Tennis_Court018'].geometry} material={materials['Material.257']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <group name="BB_+_Tennis_Court037" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane045_1" geometry={nodes.Plane045_1.geometry} material={materials['Material.260']} />
          <mesh name="Plane045_2" geometry={nodes.Plane045_2.geometry} material={materials['Material.261']} />
        </group>
        <group name="BB_+_Tennis_Court038" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane046_1" geometry={nodes.Plane046_1.geometry} material={materials['Material.260']} />
          <mesh name="Plane046_2" geometry={nodes.Plane046_2.geometry} material={materials['Material.261']} />
        </group>
        <mesh name="BB_+_Tennis_Court050" geometry={nodes['BB_+_Tennis_Court050'].geometry} material={materials['Material.260']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court054" geometry={nodes['BB_+_Tennis_Court054'].geometry} material={materials['Material.260']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court055" geometry={nodes['BB_+_Tennis_Court055'].geometry} material={materials['Material.262']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court056" geometry={nodes['BB_+_Tennis_Court056'].geometry} material={materials['Material.263']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court057" geometry={nodes['BB_+_Tennis_Court057'].geometry} material={materials['Material.264']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court058" geometry={nodes['BB_+_Tennis_Court058'].geometry} material={materials['Material.265']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court059" geometry={nodes['BB_+_Tennis_Court059'].geometry} material={materials['Material.266']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court060" geometry={nodes['BB_+_Tennis_Court060'].geometry} material={materials['Material.267']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court061" geometry={nodes['BB_+_Tennis_Court061'].geometry} material={materials['Material.268']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court062" geometry={nodes['BB_+_Tennis_Court062'].geometry} material={materials['Material.269']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court063" geometry={nodes['BB_+_Tennis_Court063'].geometry} material={materials['Material.270']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court064" geometry={nodes['BB_+_Tennis_Court064'].geometry} material={materials['Material.271']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court065" geometry={nodes['BB_+_Tennis_Court065'].geometry} material={materials['Material.272']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court066" geometry={nodes['BB_+_Tennis_Court066'].geometry} material={materials['Material.273']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court071" geometry={nodes['BB_+_Tennis_Court071'].geometry} material={materials['Material.277']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court072" geometry={nodes['BB_+_Tennis_Court072'].geometry} material={materials['Material.277']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court073" geometry={nodes['BB_+_Tennis_Court073'].geometry} material={materials['Material.278']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court074" geometry={nodes['BB_+_Tennis_Court074'].geometry} material={materials['Material.278']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court075" geometry={nodes['BB_+_Tennis_Court075'].geometry} material={materials['Material.278']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court076" geometry={nodes['BB_+_Tennis_Court076'].geometry} material={materials['Material.278']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court077" geometry={nodes['BB_+_Tennis_Court077'].geometry} material={materials['Material.278']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court078" geometry={nodes['BB_+_Tennis_Court078'].geometry} material={materials['Material.278']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court079" geometry={nodes['BB_+_Tennis_Court079'].geometry} material={materials['Material.311']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court080" geometry={nodes['BB_+_Tennis_Court080'].geometry} material={materials['Material.311']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court081" geometry={nodes['BB_+_Tennis_Court081'].geometry} material={materials['Material.311']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court082" geometry={nodes['BB_+_Tennis_Court082'].geometry} material={materials['Material.311']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court083" geometry={nodes['BB_+_Tennis_Court083'].geometry} material={materials['Material.279']} position={[-193.94, 11.6, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={[6.03, 6.6, 6.03]} />
        <mesh name="BB_+_Tennis_Court086" geometry={nodes['BB_+_Tennis_Court086'].geometry} material={materials['Material.280']} position={[-193.94, 11.6, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={[6.03, 6.6, 6.03]} />
        <mesh name="BB_+_Tennis_Court088" geometry={nodes['BB_+_Tennis_Court088'].geometry} material={materials['Material.282']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court089" geometry={nodes['BB_+_Tennis_Court089'].geometry} material={materials['Material.282']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court090" geometry={nodes['BB_+_Tennis_Court090'].geometry} material={materials['Material.282']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court091" geometry={nodes['BB_+_Tennis_Court091'].geometry} material={materials['Material.282']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court093" geometry={nodes['BB_+_Tennis_Court093'].geometry} material={materials['Material.284']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court094" geometry={nodes['BB_+_Tennis_Court094'].geometry} material={materials['Material.284']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court095" geometry={nodes['BB_+_Tennis_Court095'].geometry} material={materials['Material.284']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court096" geometry={nodes['BB_+_Tennis_Court096'].geometry} material={materials['Material.284']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court101" geometry={nodes['BB_+_Tennis_Court101'].geometry} material={materials['Material.310']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court102" geometry={nodes['BB_+_Tennis_Court102'].geometry} material={materials['Material.310']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court103" geometry={nodes['BB_+_Tennis_Court103'].geometry} material={materials['Material.310']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court104" geometry={nodes['BB_+_Tennis_Court104'].geometry} material={materials['Material.310']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court139" geometry={nodes['BB_+_Tennis_Court139'].geometry} material={materials['Material.288']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court148" geometry={nodes['BB_+_Tennis_Court148'].geometry} material={materials['Material.294']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court149" geometry={nodes['BB_+_Tennis_Court149'].geometry} material={materials['Material.294']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court150" geometry={nodes['BB_+_Tennis_Court150'].geometry} material={materials['Material.294']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court152" geometry={nodes['BB_+_Tennis_Court152'].geometry} material={materials['Material.288']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court153" geometry={nodes['BB_+_Tennis_Court153'].geometry} material={materials['Material.288']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court154" geometry={nodes['BB_+_Tennis_Court154'].geometry} material={materials['Material.288']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <group name="BB_+_Tennis_Court158" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane242" geometry={nodes.Plane242.geometry} material={materials['Material.292']} />
          <mesh name="Plane242_1" geometry={nodes.Plane242_1.geometry} material={materials['Material.297']} />
          <mesh name="Plane242_2" geometry={nodes.Plane242_2.geometry} material={materials['Material.299']} />
        </group>
        <group name="BB_+_Tennis_Court159" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane243" geometry={nodes.Plane243.geometry} material={materials['Material.300']} />
          <mesh name="Plane243_1" geometry={nodes.Plane243_1.geometry} material={materials['Material.302']} />
        </group>
        <group name="BB_+_Tennis_Court171" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane255" geometry={nodes.Plane255.geometry} material={materials['Material.306']} />
          <mesh name="Plane255_1" geometry={nodes.Plane255_1.geometry} material={materials['Material.309']} />
          <mesh name="Plane255_2" geometry={nodes.Plane255_2.geometry} material={materials['Material.312']} />
        </group>
        <group name="BB_+_Tennis_Court172" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane256" geometry={nodes.Plane256.geometry} material={materials['Material.313']} />
          <mesh name="Plane256_1" geometry={nodes.Plane256_1.geometry} material={materials['Material.314']} />
        </group>
        <mesh name="BB_+_Tennis_Court173" geometry={nodes['BB_+_Tennis_Court173'].geometry} material={materials['Material.315']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court175" geometry={nodes['BB_+_Tennis_Court175'].geometry} material={materials['Material.315']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court176" geometry={nodes['BB_+_Tennis_Court176'].geometry} material={materials['Material.315']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court177" geometry={nodes['BB_+_Tennis_Court177'].geometry} material={materials['Material.315']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court178" geometry={nodes['BB_+_Tennis_Court178'].geometry} material={materials['Material.315']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court179" geometry={nodes['BB_+_Tennis_Court179'].geometry} material={materials['Material.315']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court180" geometry={nodes['BB_+_Tennis_Court180'].geometry} material={materials['Material.315']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <group name="BB_+_Tennis_Court184" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane268" geometry={nodes.Plane268.geometry} material={materials['Material.317']} />
          <mesh name="Plane268_1" geometry={nodes.Plane268_1.geometry} material={materials['Material.320']} />
          <mesh name="Plane268_2" geometry={nodes.Plane268_2.geometry} material={materials['Material.321']} />
        </group>
        <group name="BB_+_Tennis_Court185" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane269" geometry={nodes.Plane269.geometry} material={materials['Material.322']} />
          <mesh name="Plane269_1" geometry={nodes.Plane269_1.geometry} material={materials['Material.323']} />
        </group>
        <mesh name="BB_+_Tennis_Court186" geometry={nodes['BB_+_Tennis_Court186'].geometry} material={materials['Material.324']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court188" geometry={nodes['BB_+_Tennis_Court188'].geometry} material={materials['Material.324']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court189" geometry={nodes['BB_+_Tennis_Court189'].geometry} material={materials['Material.324']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court190" geometry={nodes['BB_+_Tennis_Court190'].geometry} material={materials['Material.324']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court191" geometry={nodes['BB_+_Tennis_Court191'].geometry} material={materials['Material.324']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court192" geometry={nodes['BB_+_Tennis_Court192'].geometry} material={materials['Material.324']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court193" geometry={nodes['BB_+_Tennis_Court193'].geometry} material={materials['Material.324']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <group name="BB_+_Tennis_Court200" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane284" geometry={nodes.Plane284.geometry} material={materials['Material.330']} />
          <mesh name="Plane284_1" geometry={nodes.Plane284_1.geometry} material={materials['Material.881']} />
        </group>
        <group name="BB_+_Tennis_Court201" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane285" geometry={nodes.Plane285.geometry} material={materials['Material.326']} />
          <mesh name="Plane285_1" geometry={nodes.Plane285_1.geometry} material={materials['Material.331']} />
          <mesh name="Plane285_2" geometry={nodes.Plane285_2.geometry} material={materials['Material.332']} />
        </group>
        <group name="BB_+_Tennis_Court202" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane286" geometry={nodes.Plane286.geometry} material={materials['Material.333']} />
          <mesh name="Plane286_1" geometry={nodes.Plane286_1.geometry} material={materials['Material.334']} />
        </group>
        <mesh name="BB_+_Tennis_Court211" geometry={nodes['BB_+_Tennis_Court211'].geometry} material={materials['Material.336']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <group name="BB_+_Tennis_Court212" position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane296" geometry={nodes.Plane296.geometry} material={materials['Material.337']} />
          <mesh name="Plane296_1" geometry={nodes.Plane296_1.geometry} material={materials['Material.338']} />
          <mesh name="Plane296_2" geometry={nodes.Plane296_2.geometry} material={materials['Material.339']} />
        </group>
        <mesh name="BB_+_Tennis_Court237" geometry={nodes['BB_+_Tennis_Court237'].geometry} material={materials['Material.340']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court254" geometry={nodes['BB_+_Tennis_Court254'].geometry} material={materials['Material.340']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court256" geometry={nodes['BB_+_Tennis_Court256'].geometry} material={materials['Material.340']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court297" geometry={nodes['BB_+_Tennis_Court297'].geometry} material={materials['Material.349']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court321" geometry={nodes['BB_+_Tennis_Court321'].geometry} material={materials['Material.359']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court327" geometry={nodes['BB_+_Tennis_Court327'].geometry} material={materials['Material.365']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court328" geometry={nodes['BB_+_Tennis_Court328'].geometry} material={materials['Material.288']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court447" geometry={nodes['BB_+_Tennis_Court447'].geometry} material={materials['Material.784']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court450" geometry={nodes['BB_+_Tennis_Court450'].geometry} material={materials['Material.784']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court501" geometry={nodes['BB_+_Tennis_Court501'].geometry} material={materials['Material.785']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court502" geometry={nodes['BB_+_Tennis_Court502'].geometry} material={materials['Material.786']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court503" geometry={nodes['BB_+_Tennis_Court503'].geometry} material={materials['Material.861']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court504" geometry={nodes['BB_+_Tennis_Court504'].geometry} material={materials['Material.864']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court505" geometry={nodes['BB_+_Tennis_Court505'].geometry} material={materials['Material.865']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court506" geometry={nodes['BB_+_Tennis_Court506'].geometry} material={materials['Material.866']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court507" geometry={nodes['BB_+_Tennis_Court507'].geometry} material={materials['Material.867']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court508" geometry={nodes['BB_+_Tennis_Court508'].geometry} material={materials['Material.868']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court509" geometry={nodes['BB_+_Tennis_Court509'].geometry} material={materials['Material.869']} position={[-193.94, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <group name="Cube476" position={[-145.99, 11.71, 66.61]} rotation={[-Math.PI, 0, -Math.PI]} scale={[8.43, 1.25, 1]}>
          <mesh name="Cube024_1" geometry={nodes.Cube024_1.geometry} material={materials['Material.940']} />
          <mesh name="Cube024_2" geometry={nodes.Cube024_2.geometry} material={materials['Material.941']} />
        </group>
        <group name="Cube477" position={[-141.22, 11.47, 71.15]} rotation={[-Math.PI, 0, -Math.PI]} scale={[4.73, 1, 1]}>
          <mesh name="Cube027_1" geometry={nodes.Cube027_1.geometry} material={materials['Material.942']} />
          <mesh name="Cube027_2" geometry={nodes.Cube027_2.geometry} material={materials['Material.943']} />
        </group>
        <group name="Cube478" position={[-138.59, 11.47, 74.43]} rotation={[-Math.PI, 0, -Math.PI]} scale={[4.73, 1, 1]}>
          <mesh name="Cube028_1" geometry={nodes.Cube028_1.geometry} material={materials['Material.944']} />
          <mesh name="Cube028_2" geometry={nodes.Cube028_2.geometry} material={materials['Material.947']} />
        </group>
        <group name="Cube479" position={[-135.93, 11.47, 77.17]} rotation={[-Math.PI, 0, -Math.PI]} scale={[4.73, 1, 1]}>
          <mesh name="Cube029_1" geometry={nodes.Cube029_1.geometry} material={materials['Material.948']} />
          <mesh name="Cube029_2" geometry={nodes.Cube029_2.geometry} material={materials['Material.951']} />
        </group>
        <mesh name="Cube480" geometry={nodes.Cube480.geometry} material={materials['Material.952']} position={[-137.08, 11.47, 79.88]} scale={[4.73, 1, 1]} />
        <mesh name="Cube481" geometry={nodes.Cube481.geometry} material={materials['Material.959']} position={[-139.61, 11.47, 81.89]} scale={[4.73, 1, 1]} />
        <group name="Cube448" position={[-260.4, 12.39, 89.64]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.8, 1.86, 3.32]}>
          <mesh name="Cube032_1" geometry={nodes.Cube032_1.geometry} material={materials['Material.964']} />
          <mesh name="Cube032_2" geometry={nodes.Cube032_2.geometry} material={materials['Material.968']} />
        </group>
        <mesh name="Text011" geometry={nodes.Text011.geometry} material={materials['Material.6259']} position={[-262.21, 13.95, 89.02]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={0.85} />
        <mesh name="Volley_Ball_Court002" geometry={nodes.Volley_Ball_Court002.geometry} material={materials['Material.912']} position={[-290.8, 10.5, -27.41]} rotation={[0, -1.57, 0]} scale={[12.99, 5.99, 15.36]} />
        <mesh name="Volley_Ball_Court094" geometry={nodes.Volley_Ball_Court094.geometry} material={materials['Material.917']} position={[-295.05, 11.89, -27.41]} rotation={[0, -1.57, 0]} scale={[12.99, 5.99, 9.91]} />
        <mesh name="Volley_Ball_Court003" geometry={nodes.Volley_Ball_Court003.geometry} material={materials['Material.921']} position={[-242.8, 10.5, -27.41]} rotation={[0, -1.57, 0]} scale={[12.99, 5.99, 15.36]} />
        <mesh name="Volley_Ball_Court004" geometry={nodes.Volley_Ball_Court004.geometry} material={materials['Material.930']} position={[-199.09, 10.5, -27.41]} rotation={[0, -1.57, 0]} scale={[12.99, 5.99, 15.36]} />
        <mesh name="Volley_Ball_Court005" geometry={nodes.Volley_Ball_Court005.geometry} material={materials['Material.6266']} position={[-434.28, 10.5, -25.98]} rotation={[0, -1.57, 0]} scale={[10.91, 5.99, 15.36]} />
        <mesh name="Volley_Ball_Court006" geometry={nodes.Volley_Ball_Court006.geometry} material={materials['Material.6275']} position={[-290.8, 10.5, 129.46]} rotation={[0, -1.57, 0]} scale={[12.99, 5.99, 15.36]} />
        <mesh name="Plane040" geometry={nodes.Plane040.geometry} material={materials['Material.6285']} position={[-116.71, 14.62, 24.75]} rotation={[0, -Math.PI / 2, 0]} scale={[10.96, 3.28, 10.96]} />
        <mesh name="Plane056" geometry={nodes.Plane056.geometry} material={materials['Material.6288']} position={[-149.49, 14.62, -2.99]} rotation={[0, -Math.PI / 2, 0]} scale={[10.96, 3.28, 10.96]} />
        <mesh name="Cube449" geometry={nodes.Cube449.geometry} material={materials['Material.6291']} position={[-194.54, 10.36, 4.27]} rotation={[-Math.PI, 0, -Math.PI]} scale={[15.24, 0.48, 16.98]} />
        <mesh name="Volley_Ball_Court007" geometry={nodes.Volley_Ball_Court007.geometry} material={materials['Material.747']} position={[-42.38, 10.5, 300.52]} rotation={[0, -1.57, 0]} scale={[142.37, 5.99, 115.57]} />
        <mesh name="Volley_Ball_Court008" geometry={nodes.Volley_Ball_Court008.geometry} material={materials['Material.6294']} position={[-47.08, 10.5, -195.78]} rotation={[0, -1.57, 0]} scale={[142.37, 5.99, 115.57]} />
        <mesh name="Cube050" geometry={nodes.Cube050.geometry} material={materials['Material.6304']} position={[-49.07, 13.01, 51.16]} rotation={[0, 0.88, 0]} scale={[0.05, 2.32, 2.11]} />
        <mesh name="Cube051" geometry={nodes.Cube051.geometry} material={materials['Material.6305']} position={[-49.94, 13.01, 43.19]} rotation={[-Math.PI, 1.34, -Math.PI]} scale={[0.05, 2.32, 2.11]} />
        <mesh name="Cube052" geometry={nodes.Cube052.geometry} material={materials['Material.6306']} position={[-49.07, 13.01, 55.62]} scale={[0.03, 1.74, 1.74]} />
        <mesh name="Cube062" geometry={nodes.Cube062.geometry} material={materials['Material.6307']} position={[-49.07, 13.01, 41.93]} scale={[0.03, 1.74, 1.74]} />
        <mesh name="Cube455" geometry={nodes.Cube455.geometry} material={materials['Material.6308']} position={[-485.71, 10.36, -108.33]} rotation={[-Math.PI, 0, -Math.PI]} scale={[15.24, 0.48, 11.02]} />
        <group name="Cube456" position={[-293.66, 11.19, 317.82]} scale={[19.19, 1, 3.38]}>
          <mesh name="Cube039_1" geometry={nodes.Cube039_1.geometry} material={materials['Material.191']} />
          <mesh name="Cube039_2" geometry={nodes.Cube039_2.geometry} material={materials['Material.193']} />
        </group>
        <mesh name="Cube465" geometry={nodes.Cube465.geometry} material={materials['Material.6325']} position={[-332.69, 12.26, 315.85]} scale={[4.16, 0.6, 4.16]} />
        <mesh name="Cylinder023" geometry={nodes.Cylinder023.geometry} material={materials['Material.6311']} position={[-355.47, 8.99, 316.39]} scale={[36.32, 2.11, 36.32]} />
        <group name="Cylinder024" position={[-355.23, 9.25, 316.39]} scale={[29.71, 2.11, 29.71]}>
          <mesh name="Cylinder022_1" geometry={nodes.Cylinder022_1.geometry} material={materials['Material.6309']} />
          <mesh name="Cylinder022_2" geometry={nodes.Cylinder022_2.geometry} material={materials['Material.6310']} />
        </group>
        <mesh name="Cylinder025" geometry={nodes.Cylinder025.geometry} material={materials['Material.6312']} position={[-355.23, 10.8, 316.39]} scale={[15.64, 0.81, 15.64]} />
        <mesh name="Cylinder026" geometry={nodes.Cylinder026.geometry} material={materials['Material.6314']} position={[-355.23, 10.8, 316.39]} scale={[22.86, 0.81, 22.86]} />
        <mesh name="Cylinder027" geometry={nodes.Cylinder027.geometry} material={materials['Material.6315']} position={[-355.23, 11.04, 316.39]} scale={[29.45, 0.81, 29.45]} />
        <mesh name="Cube483" geometry={nodes.Cube483.geometry} material={nodes.Cube483.material} position={[-354.57, 10.95, 316.6]} scale={[0.37, 0.37, 28.89]} />
        <mesh name="Cube484" geometry={nodes.Cube484.geometry} material={nodes.Cube484.material} position={[-354.57, 10.95, 316.6]} rotation={[0, Math.PI / 4, 0]} scale={[0.37, 0.37, 28.89]} />
        <mesh name="Cube485" geometry={nodes.Cube485.geometry} material={nodes.Cube485.material} position={[-354.57, 10.95, 316.6]} rotation={[0, Math.PI / 2, 0]} scale={[0.37, 0.37, 28.89]} />
        <mesh name="Cube486" geometry={nodes.Cube486.geometry} material={nodes.Cube486.material} position={[-354.57, 10.95, 316.6]} rotation={[-Math.PI, Math.PI / 4, -Math.PI]} scale={[0.37, 0.37, 28.89]} />
        <mesh name="Volley_Ball_Court009" geometry={nodes.Volley_Ball_Court009.geometry} material={materials['Material.6316']} position={[-349.94, 10.66, 345.53]} rotation={[0, -1.57, 0]} scale={[50.4, 3.13, 60.43]} />
        <mesh name="Cube464" geometry={nodes.Cube464.geometry} material={materials['Material.6326']} position={[-287.49, 12.85, 231.12]} rotation={[-Math.PI, 0, -Math.PI]} scale={[8.99, 2.28, 2.1]} />
        <mesh name="Cube487" geometry={nodes.Cube487.geometry} material={materials['Material.6328']} position={[-296.77, 11.98, 229.7]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.64, 0.78, 0.72]} />
        <mesh name="Cube488" geometry={nodes.Cube488.geometry} material={materials['Material.6329']} position={[-278.77, 11.98, 229.7]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.64, 0.78, 0.72]} />
        <mesh name="Cube490" geometry={nodes.Cube490.geometry} material={materials['Material.6331']} position={[-277.55, 11.98, 229.7]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.64, 0.78, 0.72]} />
        <mesh name="Cube491" geometry={nodes.Cube491.geometry} material={materials['Material.6332']} position={[-277.55, 15.45, 229.7]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.64, 0.99, 0.72]} />
        <mesh name="Cube492" geometry={nodes.Cube492.geometry} material={materials['Material.6333']} position={[-300.18, 13.8, 243.35]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[11.81, 0.2, 0.22]} />
        <mesh name="Cube494" geometry={nodes.Cube494.geometry} material={materials['Material.6333']} position={[-300.18, 13.8, 243.35]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[11.81, 0.2, 0.22]} />
        <mesh name="Cube495" geometry={nodes.Cube495.geometry} material={materials['Material.6333']} position={[-300.18, 13.8, 242.93]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[11.81, 0.2, 0.22]} />
        <mesh name="Cube496" geometry={nodes.Cube496.geometry} material={materials['Material.6335']} position={[-282.66, 11.98, 229.7]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.64, 0.78, 0.72]} />
        <mesh name="Cube497" geometry={nodes.Cube497.geometry} material={materials['Material.6336']} position={[-280.98, 11.98, 229.7]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.64, 0.78, 0.72]} />
        <mesh name="Cube499" geometry={nodes.Cube499.geometry} material={materials['Material.6337']} position={[-289.24, 15.52, 229.71]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.64, 0.83, 0.72]} />
        <mesh name="Cube500" geometry={nodes.Cube500.geometry} material={materials['Material.6338']} position={[-284.24, 11.98, 229.7]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.64, 0.78, 0.72]} />
        <mesh name="Cube502" geometry={nodes.Cube502.geometry} material={materials['Material.6339']} position={[-290.6, 12, 229.7]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.64, 0.67, 0.72]} />
        <mesh name="Cube503" geometry={nodes.Cube503.geometry} material={materials['Material.6340']} position={[-294.29, 11.99, 229.7]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.64, 0.67, 0.72]} />
        <mesh name="Cube504" geometry={nodes.Cube504.geometry} material={materials['Material.6341']} position={[-296.4, 15.55, 229.7]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.64, 0.93, 0.72]} />
        <mesh name="Cube505" geometry={nodes.Cube505.geometry} material={materials['Material.6342']} position={[-299.41, 15.55, 229.7]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.71, 0.98, 0.81]} />
        <mesh name="Cube506" geometry={nodes.Cube506.geometry} material={materials['Material.6343']} position={[-297.94, 14.36, 241.25]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.99, 2.45, 3.16]} />
        <mesh name="Cube507" geometry={nodes.Cube507.geometry} material={materials['Material.6344']} position={[-299, 10.96, 240.69]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.81, 0.12, 3.92]} />
        <mesh name="Cube508" geometry={nodes.Cube508.geometry} material={materials['Material.6345']} position={[-300.06, 13.8, 231.09]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[11.81, 0.2, 0.22]} />
        <mesh name="Cube509" geometry={nodes.Cube509.geometry} material={materials['Material.6346']} position={[-300.06, 13.8, 233.43]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[11.81, 0.2, 0.22]} />
        <mesh name="Cube510" geometry={nodes.Cube510.geometry} material={materials['Material.6347']} position={[-300.06, 13.8, 232.31]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[11.81, 0.2, 0.22]} />
        <mesh name="Cube511" geometry={nodes.Cube511.geometry} material={materials['Material.6348']} position={[-300.02, 17.52, 228.84]} rotation={[0, -Math.PI / 2, 0]} scale={[10.66, 0.31, 0.26]} />
        <mesh name="Cube512" geometry={nodes.Cube512.geometry} material={materials['Material.6349']} position={[-300.34, 15.12, 230.86]} rotation={[0, -Math.PI / 2, 0]} scale={[10.66, 0.38, 0.26]} />
        <mesh name="Cube513" geometry={nodes.Cube513.geometry} material={materials['Material.6350']} position={[-288.27, 12.94, 229.02]} rotation={[Math.PI / 2, -1.57, 0]} scale={[2.38, 0.27, 0.24]} />
        <mesh name="Cube514" geometry={nodes.Cube514.geometry} material={materials['Material.6333']} position={[-297.51, 13.42, 243.78]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[10.43, 0.13, 0.22]} />
        <mesh name="Cube515" geometry={nodes.Cube515.geometry} material={materials['Material.6351']} position={[-300.19, 13.8, 240.64]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[11.81, 0.2, 0.6]} />
        <mesh name="Cube516" geometry={nodes.Cube516.geometry} material={materials['Material.6352']} position={[-300.19, 13.8, 242.38]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[11.81, 0.2, 0.64]} />
        <mesh name="Cube525" geometry={nodes.Cube525.geometry} material={materials['Material.6353']} position={[-287.23, 10.96, 229.26]} rotation={[0, Math.PI / 2, 0]} scale={[2.05, 0.12, 3.47]} />
        <mesh name="Cube526" geometry={nodes.Cube526.geometry} material={materials['Material.6354']} position={[-286.98, 10.94, 226.91]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.64, 0.35, 0.72]} />
        <mesh name="Cube100" geometry={nodes.Cube100.geometry} material={materials['Material.6356']} position={[-230.93, 10.36, -300.09]} scale={[15.24, 0.48, 16.98]} />
        <mesh name="Volley_Ball_Court010" geometry={nodes.Volley_Ball_Court010.geometry} material={materials['Material.6357']} position={[-204.67, 11.18, 257.32]} rotation={[0, -1.57, 0]} scale={[8.63, 0.2, 3.81]} />
        <group name="Cube101" position={[-339.37, 2.9, -50.65]} rotation={[-Math.PI, 0, -Math.PI]} scale={[3.59, 7.94, 7.94]}>
          <mesh name="Cube040_1" geometry={nodes.Cube040_1.geometry} material={materials['Material.201']} />
          <mesh name="Cube040_2" geometry={nodes.Cube040_2.geometry} material={materials['Material.202']} />
          <mesh name="Cube040_3" geometry={nodes.Cube040_3.geometry} material={materials['Material.203']} />
          <mesh name="Cube040_4" geometry={nodes.Cube040_4.geometry} material={materials['Material.204']} />
          <mesh name="Cube040_5" geometry={nodes.Cube040_5.geometry} material={materials['Material.960']} />
        </group>
        <group name="Cube102" position={[-339.37, 2.9, -70.86]} rotation={[-Math.PI, 0, -Math.PI]} scale={[3.59, 7.94, 7.94]}>
          <mesh name="Cube042_1" geometry={nodes.Cube042_1.geometry} material={materials['Material.6366']} />
          <mesh name="Cube042_2" geometry={nodes.Cube042_2.geometry} material={materials['Material.6367']} />
          <mesh name="Cube042_3" geometry={nodes.Cube042_3.geometry} material={materials['Material.6368']} />
          <mesh name="Cube042_4" geometry={nodes.Cube042_4.geometry} material={materials['Material.6369']} />
          <mesh name="Cube042_5" geometry={nodes.Cube042_5.geometry} material={materials['Material.6370']} />
        </group>
        <group name="Cube103" position={[-339.37, 2.9, -90.38]} rotation={[-Math.PI, 0, -Math.PI]} scale={[3.59, 7.94, 7.94]}>
          <mesh name="Cube046_1" geometry={nodes.Cube046_1.geometry} material={materials['Material.6371']} />
          <mesh name="Cube046_2" geometry={nodes.Cube046_2.geometry} material={materials['Material.6372']} />
          <mesh name="Cube046_3" geometry={nodes.Cube046_3.geometry} material={materials['Material.6373']} />
          <mesh name="Cube046_4" geometry={nodes.Cube046_4.geometry} material={materials['Material.6374']} />
          <mesh name="Cube046_5" geometry={nodes.Cube046_5.geometry} material={materials['Material.6375']} />
        </group>
        <mesh name="Cube013" geometry={nodes.Cube013.geometry} material={materials['Material.6376']} position={[-285.96, 13.57, -154.65]} scale={[15.08, 2.85, 2.85]} />
        <mesh name="Cube181" geometry={nodes.Cube181.geometry} material={materials['Material.6377']} position={[-299.45, 17.55, -151.76]} scale={[0.18, 1.75, 0.13]} />
        <mesh name="Cube018" geometry={nodes.Cube018.geometry} material={materials['Material.6378']} position={[-273.99, 13.57, -172.52]} rotation={[0, Math.PI / 2, 0]} scale={[15.08, 2.85, 2.85]} />
        <mesh name="Cube182" geometry={nodes.Cube182.geometry} material={materials['Material.6379']} position={[-271.1, 17.55, -159.04]} rotation={[0, Math.PI / 2, 0]} scale={[0.18, 1.75, 0.13]} />
        <mesh name="Cube005" geometry={nodes.Cube005.geometry} material={materials['Material.6380']} position={[-316.17, 13.57, -173.14]} rotation={[0, -1.57, 0]} scale={[15.08, 2.85, 2.85]} />
        <mesh name="Cube009" geometry={nodes.Cube009.geometry} material={materials['Material.6381']} position={[-319.06, 17.55, -186.62]} rotation={[0, -1.57, 0]} scale={[0.18, 1.75, 0.13]} />
        <mesh name="Cube153" geometry={nodes.Cube153.geometry} material={materials['Material.6382']} position={[-327.6, 17.32, -181.69]} rotation={[-Math.PI, 0, -Math.PI]} />
        <mesh name="Cube184" geometry={nodes.Cube184.geometry} material={materials['Material.6383']} position={[-327.6, 11.68, -181.69]} rotation={[-Math.PI, 0, -Math.PI]} />
        <mesh name="Cube185" geometry={nodes.Cube185.geometry} material={materials['Material.6384']} position={[-332.56, 13.57, -181.82]} scale={[15.08, 2.85, 2.85]} />
        <mesh name="Cube186" geometry={nodes.Cube186.geometry} material={materials['Material.6385']} position={[-346.04, 17.55, -178.92]} scale={[0.18, 1.75, 0.13]} />
        <mesh name="Cube007" geometry={nodes.Cube007.geometry} material={materials['Material.6386']} position={[-375.42, 17.32, -180.2]} rotation={[-Math.PI, 0, -Math.PI]} />
        <mesh name="Cube008" geometry={nodes.Cube008.geometry} material={materials['Material.6387']} position={[-380.39, 13.57, -181.82]} scale={[15.08, 2.85, 2.85]} />
        <mesh name="Cube192" geometry={nodes.Cube192.geometry} material={materials['Material.6388']} position={[-389.33, 17.55, -178.92]} scale={[0.18, 1.75, 0.13]} />
        <mesh name="Cube194" geometry={nodes.Cube194.geometry} material={materials['Material.6389']} position={[-393.29, 13.57, -172.52]} rotation={[0, Math.PI / 2, 0]} scale={[15.08, 2.85, 2.85]} />
        <mesh name="Cube195" geometry={nodes.Cube195.geometry} material={materials['Material.6390']} position={[-390.4, 17.55, -159.04]} rotation={[0, Math.PI / 2, 0]} scale={[0.18, 1.75, 0.13]} />
        <mesh name="Cube196" geometry={nodes.Cube196.geometry} material={materials['Material.6391']} position={[-391.38, 13.27, -167.78]} rotation={[0, Math.PI / 2, 0]} scale={[1, 1.43, 1]} />
        <mesh name="Cube197" geometry={nodes.Cube197.geometry} material={materials['Material.6392']} position={[-423.26, 13.57, -154.65]} scale={[15.08, 2.85, 2.85]} />
        <mesh name="Cube198" geometry={nodes.Cube198.geometry} material={materials['Material.6393']} position={[-436.75, 17.55, -151.76]} scale={[0.18, 1.75, 0.13]} />
        <mesh name="Cube131" geometry={nodes.Cube131.geometry} material={materials['Material.6394']} position={[-351.1, 18.48, -171.78]} scale={[1.06, 3.31, 1]} />
        <mesh name="Cube134" geometry={nodes.Cube134.geometry} material={materials['Material.6395']} position={[-361.22, 18.48, -171.78]} scale={[1.06, 3.31, 1]} />
        <mesh name="BB_+_Tennis_Court067" geometry={nodes['BB_+_Tennis_Court067'].geometry} material={materials['Material.6482']} position={[-193.94, 11.82, 84.48]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court068" geometry={nodes['BB_+_Tennis_Court068'].geometry} material={materials['Material.6625']} position={[-193.94, 11.82, 106.87]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court069" geometry={nodes['BB_+_Tennis_Court069'].geometry} material={materials['Material.6768']} position={[-193.12, 12.41, 104.22]} scale={[5.4, 6.03, 6.03]} />
        <mesh name="BB_+_Tennis_Court070" geometry={nodes['BB_+_Tennis_Court070'].geometry} material={materials['Material.6911']} position={[-193.12, 12.41, 87.09]} scale={[5.4, 6.03, 6.03]} />
        <mesh name="BB_+_Tennis_Court087" geometry={nodes['BB_+_Tennis_Court087'].geometry} material={materials['Material.7054']} position={[-197.29, 12.15, 86.6]} scale={[2.17, 3.13, 3.13]} />
        <mesh name="BB_+_Tennis_Court092" geometry={nodes['BB_+_Tennis_Court092'].geometry} material={materials['Material.7197']} position={[-189.58, 12.15, 86.6]} scale={[2.17, 3.13, 3.13]} />
        <mesh name="BB_+_Tennis_Court097" geometry={nodes['BB_+_Tennis_Court097'].geometry} material={materials['Material.7340']} position={[-212.29, 11.82, 106.87]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court084" geometry={nodes['BB_+_Tennis_Court084'].geometry} material={materials['Material.7483']} position={[-212.29, 11.82, 84.42]} scale={6.03} />
        <mesh name="BB_+_Tennis_Court039" geometry={nodes['BB_+_Tennis_Court039'].geometry} material={materials['Material.7626']} position={[-212.55, 11.82, 93.19]} rotation={[0, 1.57, 0]} scale={[3.33, 6.03, 6.03]} />
        <mesh name="BB_+_Tennis_Court019" geometry={nodes['BB_+_Tennis_Court019'].geometry} material={materials['Material.7769']} position={[-212.55, 11.82, 80.25]} rotation={[0, 1.57, 0]} scale={[3.33, 6.03, 6.03]} />
        <mesh name="BB_+_Tennis_Court020" geometry={nodes['BB_+_Tennis_Court020'].geometry} material={materials['Material.7912']} position={[-212.34, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <group name="BB_+_Tennis_Court024" position={[-211.88, 12.15, 78.78]} rotation={[Math.PI, 0, Math.PI]} scale={[4.98, 3.13, 3.13]}>
          <mesh name="Plane202" geometry={nodes.Plane202.geometry} material={materials['Material.8484']} />
          <mesh name="Plane202_1" geometry={nodes.Plane202_1.geometry} material={materials['Material.8055']} />
          <mesh name="Plane202_2" geometry={nodes.Plane202_2.geometry} material={materials['Material.8198']} />
          <mesh name="Plane202_3" geometry={nodes.Plane202_3.geometry} material={materials['Material.8341']} />
        </group>
        <group name="BB_+_Tennis_Court025" position={[-186.98, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03}>
          <mesh name="Plane203" geometry={nodes.Plane203.geometry} material={materials['Material.8617']} />
          <mesh name="Plane203_1" geometry={nodes.Plane203_1.geometry} material={materials['Material.8675']} />
        </group>
        <mesh name="BB_+_Tennis_Court026" geometry={nodes['BB_+_Tennis_Court026'].geometry} material={materials['Material.8623']} position={[-186.98, 11.82, 84.86]} rotation={[0, -Math.PI / 2, 0]} scale={6.03} />
        <group name="BB_+_Tennis_Court027" position={[-229.09, 11.82, 88.23]} rotation={[0, 1.57, 0]} scale={6.03}>
          <mesh name="Plane205" geometry={nodes.Plane205.geometry} material={materials['Material.8760']} />
          <mesh name="Plane205_1" geometry={nodes.Plane205_1.geometry} material={materials['Material.8818']} />
        </group>
        <mesh name="BB_+_Tennis_Court028" geometry={nodes['BB_+_Tennis_Court028'].geometry} material={materials['Material.8766']} position={[-229.09, 11.82, 88.23]} rotation={[0, 1.57, 0]} scale={6.03} />
        <group name="BB_+_Tennis_Court029" position={[-236.05, 11.82, 88.23]} rotation={[0, 1.57, 0]} scale={6.03}>
          <mesh name="Plane207" geometry={nodes.Plane207.geometry} material={materials['Material.8903']} />
          <mesh name="Plane207_1" geometry={nodes.Plane207_1.geometry} material={materials['Material.8961']} />
        </group>
        <mesh name="BB_+_Tennis_Court030" geometry={nodes['BB_+_Tennis_Court030'].geometry} material={materials['Material.8909']} position={[-236.05, 11.82, 88.23]} rotation={[0, 1.57, 0]} scale={6.03} />
        <group name="BB_+_Tennis_Court021" position={[-188.23, 12.31, 122.5]} rotation={[Math.PI, 0, Math.PI]} scale={[4.51, 4.23, 3.37]}>
          <mesh name="Plane209" geometry={nodes.Plane209.geometry} material={materials['Material.9056']} />
          <mesh name="Plane209_1" geometry={nodes.Plane209_1.geometry} material={materials['Material.9199']} />
          <mesh name="Plane209_2" geometry={nodes.Plane209_2.geometry} material={materials['Material.9342']} />
          <mesh name="Plane209_3" geometry={nodes.Plane209_3.geometry} material={materials['Material.9485']} />
        </group>
        <group name="Cube142" position={[-192.43, 12.12, 130.73]} scale={[0.02, 0.4, 0.02]}>
          <mesh name="Cube101_1" geometry={nodes.Cube101_1.geometry} material={materials['Material.9828']} />
          <mesh name="Cube101_2" geometry={nodes.Cube101_2.geometry} material={materials['Material.9561']} />
          <mesh name="Cube101_3" geometry={nodes.Cube101_3.geometry} material={materials['Material.9829']} />
        </group>
        <group name="Cube199" position={[-186.88, 12.12, 130.73]} scale={[0.02, 0.4, 0.02]}>
          <mesh name="Cube104_1" geometry={nodes.Cube104_1.geometry} material={materials['Material.9830']} />
          <mesh name="Cube104_2" geometry={nodes.Cube104_2.geometry} material={materials['Material.9850']} />
          <mesh name="Cube104_3" geometry={nodes.Cube104_3.geometry} material={materials['Material.9974']} />
        </group>
        <group name="BB_+_Tennis_Court022" position={[-192.28, 12.31, 144.41]} rotation={[Math.PI, 0, Math.PI]} scale={[6.93, 4.42, 5.48]}>
          <mesh name="Plane214" geometry={nodes.Plane214.geometry} material={materials['Material.10061']} />
          <mesh name="Plane214_1" geometry={nodes.Plane214_1.geometry} material={materials['Material.10204']} />
          <mesh name="Plane214_2" geometry={nodes.Plane214_2.geometry} material={materials['Material.10347']} />
          <mesh name="Plane214_3" geometry={nodes.Plane214_3.geometry} material={materials['Material.10490']} />
        </group>
        <group name="Cube200" position={[-232.84, 12.53, 122.81]} scale={[2.28, 1, 1]}>
          <mesh name="Cube105_1" geometry={nodes.Cube105_1.geometry} material={materials['Material.10547']} />
          <mesh name="Cube105_2" geometry={nodes.Cube105_2.geometry} material={materials['Material.10548']} />
        </group>
        <group name="Cube201" position={[-232.84, 12.53, 165.8]} rotation={[-Math.PI, 0, -Math.PI]} scale={[2.28, 1, 1]}>
          <mesh name="Cube106_1" geometry={nodes.Cube106_1.geometry} material={materials['Material.10549']} />
          <mesh name="Cube106_2" geometry={nodes.Cube106_2.geometry} material={materials['Material.10550']} />
        </group>
        <group name="Cube204" position={[-191.16, 12.53, 169.67]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.86, 0.82, 0.82]}>
          <mesh name="Cube107_1" geometry={nodes.Cube107_1.geometry} material={materials['Material.10551']} />
          <mesh name="Cube107_2" geometry={nodes.Cube107_2.geometry} material={materials['Material.10552']} />
        </group>
        <group name="Cube205" position={[-191.16, 12.53, 145.93]} scale={[1.86, 0.82, 0.82]}>
          <mesh name="Cube108_1" geometry={nodes.Cube108_1.geometry} material={materials['Material.10553']} />
          <mesh name="Cube108_2" geometry={nodes.Cube108_2.geometry} material={materials['Material.10554']} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload(file)
